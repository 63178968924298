<template>
    <div class="container">

        <div class="row h2 m-4">
            <span>Change Password</span>
        </div>
        <div class="row text-primary m-4 justify-content-center" >
            <div class="col-6 col-sm-2 text-left">
                <span> Old Password</span>
            </div>
            <div class="col-6 col-sm-2">
                <input type="password" class="col-12" v-model="oldPassword" placeholder="Enter Old Password"/>
            </div>
        </div>

        <div class="row text-primary m-4 justify-content-center" >
            <div class="col-6 col-sm-2 text-left">
                <span> New Password</span>
            </div>
            <div class="col-6 col-sm-2">
                <input type="password" class="col-12" v-model="newPassword" placeholder="Enter New Password"/>
            </div>
        </div>

        <div class="row text-primary m-4 justify-content-center" >
            <div class="col-6 col-sm-2 text-left">
                <span> Confirm Password</span>
            </div>
            <div class="col-6 col-sm-2">
                <input type="password" class="col-12" v-model="confirmPassword" placeholder="Confirm New Password"/>
            </div>
        </div>
        <div>
            <button @click="changePassword">Change Password</button>
        </div>
        <div class="row h3 text-success" v-show="isPasswordChangeSuccessful == 'true'">
            <span>Password Changed Successfully</span>
        </div>
        <div class="row h3 text-danger" v-show="isPasswordChangeSuccessful == 'false'">
            <span>Password Could not be Changed</span>
        </div>

</div>
</template>
<script>
export default({
    name:"ChangePassword",
    props:{
        smsapi_endpoint_base_url:String,
        oldPwd:String,
        userId:String,
    },
    data(){
        return {
            oldPassword:'',
            newPassword:'',
            confirmPassword:'',
            isPasswordChangeSuccessful:'',
            //userId:'',

        }
    },
    methods:{
        changePassword(){
            // console.log("this.userId",this.userId);
            // console.log("this.oldPwd",this.oldPwd);
            // console.log("this.oldPassword",this.oldPassword);
            // console.log("this.newPassword",this.newPassword);
            // console.log("this.confirmPassword",this.confirmPassword);
            this.isPasswordChangeSuccessful = '';

            if(this.newPassword != this.confirmPassword)
            {
                alert("Enter new-password does not match the confirm-password. Please enter same password in both places.");
                return;
            }
            let token = localStorage.getItem("token");
            let requestUrl = this.smsapi_endpoint_base_url + "login/ChangeControlUserPassword";
            let requestContents={
                method:"POST",
                headers:{
                    "Content-Type":"application/json",
                    "Authorization":`Bearer ${token}`
                },
                body: JSON.stringify({
                    NewPassword:this.newPassword,
                    UserPb : {
                        UserId: this.userId,
                        Password : this.oldPassword,
                        UserName:"ABAD"
                    },
                }),
            };
            fetch(requestUrl, requestContents)
            .then(response => {
                let res = response.json();
                //console.log("res ", res);
                return res;
            })
            .then( res => {
                //console.log("res " , res);
                if(res.userViewModel == null)
                    this.isPasswordChangeSuccessful = 'false';
                else
                    this.isPasswordChangeSuccessful = 'true';
            });
        },
    },
    mounted(){
        this.isPasswordChangeSuccessful = '';
    }
   
});
</script>