import { IIdDocument } from "./IIdDocument";

export class IdDocument implements IIdDocument{
    id: number;
    issueDate: Date;
    expiryDate: Date;
    number: string;
    countryOfIssueId: number;
    typeId: string;

    /**
     *
     */
    constructor(
        id: number,
        issueDate: Date,
        expiryDate: Date,
        number: string,
        countryOfIssueId: number,
        typeId: string
    ) {
        
        this.id = id;
        this.issueDate = issueDate;
        this.expiryDate = expiryDate;
        this.number = number;
        this.countryOfIssueId = countryOfIssueId;
        this.typeId = typeId;
        //console.log("idDocument :", this);
    }
    
}