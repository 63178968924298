<template>

    <!-- <div class="row bg-info"> -->
    <div class="row">
            
        <nav class="navbar navbar-expand-md navbar-dark bg-primary mb-0">
          <img id="iconImage" alt="Logo">
          <label id="companyName" class="navbar-brand fw-bold mx-4"></label>
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup">
              <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
              <div class="navbar-nav fw-bold">
                  <!-- <a href="#" class="nav-item nav-link" v-on:click="showHome">Home</a>
                  <a href="#" class="nav-item nav-link" v-on:click="showSites">Sites</a>
                  <a href="#" class="nav-item nav-link" @click="showOfficers">Security Officers</a>
                  <a href="#" class="nav-item nav-link" @click="showSchedule">Schedule</a>
                  <a href="#" class="nav-item nav-link" >Events</a>
                  <a href="#" class="nav-item nav-link">Education</a> -->

                  <div class="align-left navbar-nav fw-bold">

                  <!-- <a href="#" :class="setActive(isHomePageVisible)" class="nav-item nav-link" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" v-on:click="showHome">Home</a> -->
                  <a href="#"  class="nav-item nav-link" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" v-on:click="showHome">Home</a>
                  <a href="#" :class="setActive(isSitesVisible)" v-show="isLogginSuccessfull" class="nav-item nav-link" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" v-on:click="showSites">Sites</a>
                  <a href="#" :class="setActive(isOfficersVisible)" v-show="isLogginSuccessfull" class="nav-item nav-link" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" @click="showOfficers">Security Officers</a>
                  <a href="#" :class="setActive(isScheduleVisible)" v-show="isLogginSuccessfull" class="nav-item nav-link" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" @click="showSchedule">Schedule</a>
                  <a href="#" :class="setActive(isSiteScheduleVisible)" v-show="isLogginSuccessfull" class="nav-item nav-link" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" @click="showSiteSchedule">SiteSchedule</a>
                  <a href="#" :class="setActive(isCompaniesVisible)" v-show="isLogginSuccessfull" class="nav-item nav-link" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" @click="showCompanies">Companies</a>
                  <a href="#" :class="setActive(isChangePasswordVisible)" v-show="isLogginSuccessfull" class="nav-item nav-link" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" @click="showChangePassword">ChangePassword</a>
                  <!-- <a href="#" class="nav-item nav-link" >Events</a>
                  <a href="#" class="nav-item nav-link">Settings</a>
                  <a href="#" class="nav-item nav-link" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" @click="showGeoAttendance">GeoAttendance</a>
                  <a href="#" class="nav-item nav-link" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" @click="showMap"> Map </a>
                   -->
                  
                  <!-- <a href="#" :class="setActive(isLoginPageVisible)" v-show="!isLogginSuccessfull" class="nav-item nav-link" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" v-on:click="showLogin">Login</a> -->
                  <a href="#" :class="setActive(isLoginPageVisible)" v-show="!isLogginSuccessfull" class="nav-item nav-link" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" v-on:click="logout">Login</a>
                  <a href="#" :class="setActive(isLoginPageVisible)" v-show="isLogginSuccessfull" class="nav-item nav-link" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" v-on:click="logout">Logout</a>
                </div>
              </div>
          </div>            
        </nav>
    
      <Sites class="container" :smsapi_endpoint_base_url="smsapi_endpoint_base_url" v-show="isSitesVisible"/>
      <SecurityOfficers class="container bg-light " :smsapi_endpoint_base_url="smsapi_endpoint_base_url" v-show="isOfficersVisible"/>
      <Schedule class="container " :smsapi_endpoint_base_url="smsapi_endpoint_base_url" v-show="isScheduleVisible"/>
      <SiteSchedule class="container " :smsapi_endpoint_base_url="smsapi_endpoint_base_url" v-show="isSiteScheduleVisible"/>
      <Login @login-status="setLoginStatus" @login-info="setLoginInfo" class="container" :smsapi_endpoint_base_url="smsapi_endpoint_base_url" v-show="isLoginPageVisible"/>
      <Companies class="container " :smsapi_endpoint_base_url="smsapi_endpoint_base_url" v-show="isCompaniesVisible"/>
      <ChangePassword class="container " :userId="userId" :oldPwd="oldPwd" :smsapi_endpoint_base_url="smsapi_endpoint_base_url" v-show="isChangePasswordVisible"/>

      
      <div id="content" class="container text-center" v-if="isHomePageVisible"> 
        <div class="container row">
          <div class="row col-12 ">
            <div class="container text-center text-dark fs-1" ><h2>Welcome to Vibrants Services</h2></div>
            <!-- <div class="container text-center text-dark fs-1" ><h2>Welcome to Eagle Security Ltd</h2></div> -->
          </div>

        </div>
        
        <div class="row col-12 text-center d-flex justify-content-center">
          <!-- <img class="row col-8" src="Images/CompanyLogo.png">  -->
          <img class="row col-2" id="iconImage2"> 

          </div>

        <div class="container row  text-dark">
            <div class="row col-4"></div>
            <div class="row col-8 text-left">
                <div class="container p-2" >
                  <div class="d-flex">
                    <div class="fs-1 col-5">Director:</div>
                    <div class="fs-2 col-7  pt-2 ">Ayaz Shabbir</div>
                    <!-- <div class="fs-2 col-7  pt-2 ">M. Umer Nawazish</div> -->
                  </div>
                </div>

                <div class="container p-2" >
                  <div class="row">
                    <div class="fs-1 col-5">Address: </div>
                    <div class="fs-2 col-7  pt-2"> 5 Union Street,</div>
                    <!-- <div class="fs-2 col-7  pt-2"> Second Floor, 184 Cheetham Hill Road</div> -->
                  </div>
                </div>

                <!-- <div class="container p-2" >
                  <div class="row">
                    <div class="fs-1 col-5"> </div>
                    <div class="fs-2 col-7  pt-2">Manchester</div>
                  </div>
                </div> -->


                <div class="container p-2" >
                  <div class="row">
                    <div class="fs-1 col-5"> </div>
                    <div class="fs-2 col-7  pt-2">Manchester</div>
                  </div>
                </div>

                <div class="container p-2" >
                  <div class="row">
                    <div class="fs-1 col-5"> </div>
                    <div class="fs-2 col-7  pt-2">United Kingdom</div>
                  </div>
                </div>

                <div class="container p-2" >
                  <div class="row">
                    <div class="fs-1 col-5"> </div>
                    <div class="fs-2 col-7  pt-2">M12 4JD</div>
                  </div>
                </div>

              </div>


        </div>

          

          </div>			
    <!-- /#content -->

        
  </div>

</template>

<script language="ts">
//import {ref, defineComponent} from 'vue'
import { defineComponent} from 'vue'
import Sites from './components/smsweb/Sites.vue'
import SecurityOfficers from './components/smsweb/SecurityOfficers.vue'
import Schedule from './components/smsweb/Schedule.vue'
import SiteSchedule from './components/smsweb/SiteSchedule.vue'
import Login from './components/smsweb/Login.vue'
import Companies from './components/smsweb/Companies.vue'
import ChangePassword from './components/smsweb/ChangePassword.vue'

export default defineComponent( {

// setup(){
// const test = ref('AAA');
// console.log(test.value)
// },




  name: 'App',
  components: {
    Sites,
    SecurityOfficers,
    Schedule,
    SiteSchedule,
    Login,
    Companies,
    ChangePassword,
},
  devServer: {
    https: true,
  },
  methods:{
    setActive(isVisible){
      var acttiveClass=null;
      if(isVisible)
      {
          acttiveClass = {
            'text-white':true,
            //'bg-white':true
        }
      }
      else
      {
          acttiveClass = {
          'text-white':false,
          //'bg-white':false
        }
      }
      return acttiveClass;
    },
    logout(){
      this.isLogginSuccessfull=false;
      localStorage.setItem("isLogginSuccessfull", false);
      localStorage.setItem("currentTab", "login");

      localStorage.setItem('token', "");
      this.hideAll();
      this.isLoginPageVisible = true;
    },
    showSites(){
      this.hideAll();
      if(this.isLogginSuccessfull == "true" || this.isLogginSuccessfull == true)
      {
        this.isSitesVisible = true;
        this.isLoginPageVisible = false;
        localStorage.setItem("currentTab", "sites");
      }
      else
        this.isLoginPageVisible = true;

    },
    showOfficers(){
     this.hideAll();
     if(this.isLogginSuccessfull == "true" || this.isLogginSuccessfull == true)
      {
        this.isOfficersVisible = true;
        localStorage.setItem("currentTab", "officers");

      }
      else
        this.isLoginPageVisible = true;
    },
    showSchedule(){
     this.hideAll();
     if(this.isLogginSuccessfull == "true" || this.isLogginSuccessfull == true)
      {
        this.isScheduleVisible = true;
        localStorage.setItem("currentTab", "schedule");
      }
      else
        this.isLoginPageVisible = true;
    },
    showSiteSchedule(){
     this.hideAll();
     if(this.isLogginSuccessfull == "true" || this.isLogginSuccessfull == true)
      {
        this.isSiteScheduleVisible = true;
        localStorage.setItem("currentTab", "siteSchedule");
      }
      else
        this.isLoginPageVisible = true;
    },
    showChangePassword(){
      this.hideAll();
      if(this.isLogginSuccessfull == "true" || this.isLogginSuccessfull == true)
      {
        this.isChangePasswordVisible = true;
        this.isLoginPageVisible = false;
        localStorage.setItem("currentTab", "ChangePassword");
      }
      else
        this.isLoginPageVisible = true;
    },
    showCompanies(){
     this.hideAll();
      if(this.isLogginSuccessfull == "true" || this.isLogginSuccessfull == true)
      {
        this.isCompaniesVisible = true;
        this.isLoginPageVisible = false;
        localStorage.setItem("currentTab", "companies");
      }
      else
        this.isLoginPageVisible = true;
    },
    showHome(){
     this.hideAll();
     //if(true)//this.isLogginSuccessfull == "true" || this.isLogginSuccessfull == true)
      {
        this.isHomePageVisible = true;
        localStorage.setItem("currentTab", "home");
      }
      // else
      //   this.isLoginPageVisible = true;
    },
    showLogin(){
      this.hideAll();
      if(this.isLogginSuccessfull == "true" || this.isLogginSuccessfull == true)
      {
        this.isLoginPageVisible = true;
        localStorage.setItem("currentTab", "login");
      }
    },
    hideAll(){
      this.isScheduleVisible=false;
      this.isSiteScheduleVisible=false;
      this.isOfficersVisible=false;
      this.isSitesVisible=false;
      this.isHomePageVisible = false;
      this.isLoginPageVisible = false;
      this.isCompaniesVisible=false;
      this.isChangePasswordVisible=false;

    },
    setLoginInfo(obj){
      //console.log("obj", obj);
      this.oldPwd = obj.password;
      this.userId=obj.userId;
    },
    setLoginStatus(message){
      //alert("message : " + message);

      this.isLogginSuccessfull = message;
      // var isLogginSuccessfulls = localStorakge.getItem("isLogginSuccessfull");
      if(this.isLogginSuccessfull == "true" || this.isLogginSuccessfull == true)
      {
        this.isLoginPageVisible = false;
        this.isScheduleVisible = true;
        localStorage.setItem("isLogginSuccessfull", true);
      }
      else
      {
        this.isLoginPageVisible = true;
      }
      //alert("Received Message : " + this.isLogginSuccessfull);
    }

  },
  computed:{
    getIsSitesVisible(){
      //console.log("getIsSitesVisible called.")
      return this.isSitesVisible;
    },
    getIsOfficersVisible(){
      return this.isSitesVisible;
    },
    getIsShcedulteVisible(){
      return this.isScheduleVisible;
    },
    getIsSiteShcedulteVisible(){
      return this.isSiteScheduleVisible;
    },
    getIsLoginPageVisible(){
      return this.isLoginPageVisible;
    },
  },
  data(){
    return {
      isScheduleVisible:Boolean,
      isSiteScheduleVisible:Boolean,
      isOfficersVisible:Boolean,
      isSitesVisible:Boolean,
      //isHomePageVisible:Boolean,
      isHomePageVisible : localStorage.getItem("isHomePageVisible"),//false 
      isLoginPageVisible:Boolean,
      isCompaniesVisible:Boolean,
      isChangePasswordVisible:Boolean,
      smsapi_endpoint_base_url:window.VUE_APP_API_KEY,
      isLogginSuccessfull : localStorage.getItem("isLogginSuccessfull"),//false 
      oldPwd:String,
      userId:String,

    }
  },
  mounted(){
     this.hideAll();
     
     //setting configurable properties from config.js file fields
     document.title = window.SITE_TITLE;
     document.getElementById("companyName").innerHTML = window.COMPANY_NAME;
     document.getElementById("iconImage").setAttribute('src', window.ICON_FILE);
     document.getElementById("iconImage2").setAttribute('src', window.ICON_FILE);

     //this.showLogin();
     //let isLogginSuccessfullfromLocalStorage = localStorage.getItem("isLogginSuccessfull");
     //let token = localStorage.getItem('token');
    //  console.log("token in mounted", token);
    //  console.log("isLogginSuccessfullfromLocalStorage in mounted", isLogginSuccessfullfromLocalStorage);
    //  console.log("this.isLogginSuccessfull in mounted 1", this.isLogginSuccessfull);

     if(localStorage.getItem("isLogginSuccessfull") == false || localStorage.getItem("isLogginSuccessfull") == "false"|| localStorage.getItem("isLogginSuccessfull") == null)
        this.showHome();
      else
      {
        this.isLogginSuccessfull = true;
        let currentTab = localStorage.getItem("currentTab");

        switch(currentTab)
        {
          case "sites":
            this.showSites();
            break;
          case "officers":
            this.showOfficers();
            break;
          case "schedule":
            this.showSchedule();
            break;

          case "siteSchedule":
            this.showSiteSchedule();
            break;
            
          case "companies":
            this.showCompanies();
            break;

          case "home":
            this.showHome();
            break;

            //case "logout":
          case "login":
            this.showLogin();
            break;

          default:
            this.showLogin();
            break;
        }
        
        localStorage.setItem("isLogginSuccessfull", true)
      }
     //this.isLogginSuccessfull = localStorage.getItem("isLogginSuccessfull");
    //  console.log("this.isLogginSuccessfull in mounted 2", this.isLogginSuccessfull);
  },
 
})
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
</style>
