<template>
  <div class="container">
    <h3>{{ message }}
      <span class="col-4">
        <i class='bi bi-search color-primary' style="font-size: 20px;" @click="getScheduleForSelectedSite(0)"></i>
        <i class='bi bi-plus-square-fill' style="font-size: 22px;"  @click="addScheduleJob"></i>
        <i class='bi bi-dash-square-fill' style="font-size: 22px;"  @click="deleteScheduleJob"></i>
      </span> 
    </h3>

      <input type="date" v-if="isSearchMode" :value="dateToYYYYMMDD(startDate)" class="col-3" data-date-format="dd/mm/yyyy" 
                 @input="startDate = $event.target.valueAsDate" placeholder="Start Date">

      <input type="date" v-if="isSearchMode" :value="dateToYYYYMMDD(endDate)"
                 @input="endDate = $event.target.valueAsDate" class=" col-3" placeholder="End Date">
      <!-- <select @change="OnSelectedSiteChanged($event)">
        <option v-for="site in Sites" :value="site.Id" v-bind:key="site.Id" >{{ site.siteName }}</option>
      </select> -->
      <input list="siteslist" placeholder="Select Site" class="col-5" v-model="selectedSiteName"  @change="OnSelectedSiteChanged($event)">
      <datalist id="siteslist">
        <option v-for="site in Sites" v-bind:key="site.id">
          {{ site.id +"-"+ site.name }}
        </option>
      </datalist>

      <input list="selectedSiteClientsList" class="col-4" placeholder="Select Client" v-model="selectedClientName" @change="OnSelectedClientChanged($event)"> 
      <datalist id="selectedSiteClientsList">
        <option v-for="company in selectedSiteClients" v-bind:key="company.id">
          {{ company.id +"-"+ company.name }}
        </option>
      </datalist>
      
     

      <div class="row m-2" v-if="isSearchMode">
        <table class="table text-left table-hover">
          <thead>
            <tr>
              <!-- <th>Site</th> -->
              <th>Officer</th>
              <th>Date</th>
              <!-- <th>Client</th> -->
              <!-- <th>Status</th> -->
            </tr>
          </thead>
         <tbody>
          <tr v-for="job in schedule" v-bind:key="job.JobId">
            <!-- <td>{{ job.scheduleViewModel.jobId }}</td> -->
            <!-- <td>{{ job.scheduleViewModel.siteInfo }}</td> -->
            <td>{{ job.scheduleViewModel.securityOfficerInfo }}</td>
            <td>{{ this.getJobTimings(job) }}</td>
            <!-- <td>{{ job.scheduleViewModel.clientInfo }}</td> -->
          </tr>
         </tbody>
        </table>
      </div>
        <div v-for="job in siteSchedule" v-bind:key="job.id">
          <div class="card m-4 align-right" v-if="!isSearchMode">

        <!-- console.log(j.JobId, j.SiteId, j.JobDate, j.SecurityOfficer, j.StartTime, j.EndTime, j.TravelExpense, j.PayRate, j.Remarks); -->

          <div>
            <input type="date" v-model="job.JobDate" class="col-3 col-sm-2 p-2">
            <!-- <input type="number" pattern="[0-9]" placeholder="StartTime" v-model="job.STime" class="col-3 col-sm-1" title="Start Time"> -->
            <input type="time"  placeholder="StartTime" v-model="job.StartTime" class="col-3 col-sm-1 p-2" title="Start Time">
            <input type="time" placeholder="End Time" v-model="job.EndTime" class="col-3 col-sm-1 p-2 ">
            <!-- <input type="time" class="form-control" value="10:05 AM" /> -->

            <input type="number" placeholder="Travel.Exp." v-model="job.TravelExpense" class="col-3 col-sm-1 p-2">

            <input list="officerslist"  class="col-6 col-sm-4 p-2" v-model="job.SecurityOfficer" placeholder="Select Officer" @change="officerChanged(job)">
              <datalist id="officerslist">
                <option v-for="officer in officers" v-bind:key="officer.securityOfficerId">
                  {{officer.securityOfficerId + "-"+officer.name + "," + officer.postCode + "," + officer.cityName}}
                </option>
              </datalist>

              <input type="number" placeholder="P.Rate" v-model="job.PayRate" class="col-3 col-sm-1 p-2">

              <input type="input" placeholder="Remarks" v-model="job.Remarks" class="col-3 col-sm-2 p-2">
          </div>
        </div>

      </div>
      <div class="row justify-content-center" >
        <button class="btn-primary col-4 " v-if="!isSearchMode" @click="saveSchedule">Save Schedule</button>
      </div>
      <div class="container mt-2" v-if="isSearchMode">
        <button class="Btn btn-primary btn-sm m-1"  v-show="isRangeLess_Than_TotalResultPages"  @click="getScheduleForSelectedSite(startPageNumber - LabelsPerPage )"> &lt;&lt;</button>
        <span v-if="endPageNumber > 1">
            <span v-for="pageNo in (endPageNumber)" v-bind:key="pageNo">
              <button class="btn btn-success btn-sm m-1" type="button" v-if="isShownPageButton(pageNo)" @click="getScheduleForSelectedSite(pageNo -1 )">{{ pageNo }}</button>
            </span>
            <button type="button" class="Btn btn-primary btn-sm m-1" v-show="isRangeGreater_Than_LabelsPerPage" @click="getScheduleForSelectedSite(endPageNumber)"> >> </button>
        </span>
      </div>
  </div>
  <!-- </div> -->
</template>

<script language="ts">
import Site from "../Sites/Site"
import Job from "../Sites/Job"
export default {
    name:'SiteSchedule',
    props:{smsapi_endpoint_base_url:String},
  data() {
    return {
           // message: 'Hello World!',
           isSearchMode:false,
           schedule:[],//this.getScheduleForToday(),
           isModalShown:Boolean,
           modalContent:{},
           duty:{},
           balanceAdjustmentValue:40,
            searchString:'',
            //startDate:Date,
            currentPageNo:0,
            startPageNumber : 1,
            endPageNumber:0,
            LabelsPerPage : 10,
            totalResultPages:0,
            totalRecordCount:0,
            recordsPerPage:10,
            isRangeGreater_Than_LabelsPerPage:false,
            isRangeLess_Than_TotalResultPages:false,
            message: 'Site Schedule Interface',
            startDate: new Date(),// Date('2011-04-15T00:01:01Z'),
            endDate: new Date(),//('2011-04-17T00:01:01Z'),
            isAuthorized:false,
            isVisible:false,
            job:Job,
            site:Site,
            officers:[],
            companies:[],
            selectedOfficerId:0,
            selectedSiteId:0,
            selectedSiteName:'',
            selectedClientName:'',
            selectedSiteInfo:"",
            selectedJobDate: this.dateToYYYYMMDD(new Date()),// "2022-02-02",
            //myJobDate: "02-02-2022"//.reverse()
            selectedJobStartTime:"00:00",
            selectedJobEndTime:"00:00",
            selectedJobRemarks:"",
            selectedJobAdjustment:1,
            selectedJobTravelExpense:10,
            selectedJobClientId:2,
            selectedJobClientInfo:2,
            selectedJobBonusHours:2,
            selectedSecurityOfficerInfo:"",
            selectedSecurityOfficerInfo_ForInputBox:"",
            selectedSiteInfo_ForInputBox:"",
            selectedSiteClientsInfo_ForInputBox:"",
            selectedJobStatusInfo_ForInputBox:"",
            jobStatuses:[],
            selectedJobStatusId:"",
            selectedJobStatusInfo:"",
            selectedJobPayRate:0,
            selectedOfficerPayRate:0,
            selectedClientSiteInvoiceRate:0,
            selectedSiteClients:[],
            isNewJobToAdd:false,
            selectedCompanyId:"",
            JobCalls:[],
            Sites:[],
            siteSchedule:[],
            toDayDate:new Date()
    }
  },
  methods:{
    officerChanged(job){
      if ( job.SecurityOfficer.indexOf("-") > 0 )
      {
        let officerId = job.SecurityOfficer.substring(0, job.SecurityOfficer.indexOf("-"))
        let id  = parseInt(officerId)
        if(typeof id != "number")
        {
          alert("please select officer name from the list");
          return;
        }
        else
        {
          let requestUrl = this.smsapi_endpoint_base_url + "Pays/OfficerPay?Id=" + officerId;
          let requestOptions = {
            method:"GET",//"POST",//
            headers:{
              "Authorization": "Bearer " + localStorage.token,
              "Content-Type":"application/json",
            }
          }
          fetch(requestUrl, requestOptions)
          .then(response =>  response.json() )
          .then(res2 =>{
            job.PayRate=res2;
            // console.log("job.PayRate : ", job.PayRate);
          })
        }
      }
      else
      {
        alert("please select officer name from the list");
        return;
      }
    },
    getOfficersList(){
      let officersRequestUrl =  this.smsapi_endpoint_base_url + "securityofficers/securityofficers";
              let officersRequestOptions = {
                method:'POST',
                headers:{
                  'Authorization': `Bearer ${localStorage.token}`,
                  'Content-Type':'application/json'
                },
                body:JSON.stringify({
                  'PageNumber': 0,
                  'RecordsPerPage':1000,
                  'SearchString': ''
                })
              };

              fetch(officersRequestUrl, officersRequestOptions)
              .then(response => {
                  var result = response.json();
                  if(response.status == false)
                  {
                    //console.log("result false",  result);
                  }
                  else
                  {
                    //console.log("result true",  result);
                    return result;
                  }
              })
              .then(res=>{
                //console.log("res", res);
                this.officers=res.officersListPage;

              });
    },
    addScheduleJob(){
      if(this.selectedSiteId == 0)
      {
        alert("No site selected. Please select a valid site form the list.");
        return;
      }

      if(this.siteSchedule.length > 0)
      {
        // Add 1 Day to the last record date
        this.toDayDate.setDate(this.toDayDate.getDate() + 1)
        this.siteSchedule.push(
        {
          JobId:0,
          SiteId:this.siteSchedule[this.siteSchedule.length-1].SiteId,
          SecurityOfficer:this.siteSchedule[this.siteSchedule.length-1].SecurityOfficer,
          JobDate : this.dateToYYYYMMDD( this.toDayDate),
          StartTime:this.siteSchedule[this.siteSchedule.length-1].StartTime,
          EndTime:this.siteSchedule[this.siteSchedule.length-1].EndTime,
          PayRate :this.siteSchedule[this.siteSchedule.length-1].PayRate,
          InvoiceRate :this.selectedClientSiteInvoiceRate,
          TravelExpense : this.siteSchedule[this.siteSchedule.length-1].TravelExpense,
          Remarks:this.siteSchedule[this.siteSchedule.length-1].Remarks
        })
          return;
      }
      else
      {
        this.siteSchedule.push(
        {
          JobId:0,
          SiteId:'',
          SecurityOfficer:'',
          JobDate : this.dateToYYYYMMDD( new Date() ),
          StartTime:'',
          EndTime:'',
          PayRate :'',
          InvoiceRate :this.selectedClientSiteInvoiceRate,
          TravelExpense : '',
          Remarks:''
        })
      }
      this.isSearchMode = false;

    },
    deleteScheduleJob(){
      if(this.selectedSiteId == 0)
      {
        alert("No site selected. Please select a valid site form the list.");
        return;
      }
      
      if(this.siteSchedule.length < 1)
      {
        alert("There are no schedule records to delete.");
        return;
      }
      this.siteSchedule.pop();
      this.isSearchMode = false;
    },
    saveSchedule(){
      let jobsToAdd = [];
      if(this.siteSchedule.length<1)
      {
        alert("No records found to save. Please add records and then click save.");
        return;
      }

      this.siteSchedule.every(j => {
        let soId = -1;
        let isOfficerSelected = false;
        let indexOfDashCharacter = j.SecurityOfficer.indexOf("-");
        if(indexOfDashCharacter < 0)
        {
          alert("please select officer name from the list")
          return false;
        }

        let officerId = j.SecurityOfficer.substring(0, indexOfDashCharacter);
        if(officerId != '' || officerId != null)
        {
          soId = parseInt(officerId);
          if(typeof soId == "number")
            isOfficerSelected = true;
        }
        
        if(isOfficerSelected == false)
        {
          alert("please select officer name from the list")
          return false;
        }
        
        //console.log(j.JobId, this.selectedSiteId, this.selectedJobClientId, j.JobDate, soId, j.StartTime, j.EndTime, j.TravelExpense, j.PayRate, j.Remarks, j.InvoiceRate);
          jobsToAdd.push({
              'jobId': 0,
              'siteId': parseInt(this.selectedSiteId),
              'jobDate': j.JobDate,
              'securityOfficerId': soId,
              'operator': parseInt(localStorage.getItem('userId')),
              'bonusHours': 0,
              'travelExpense': parseInt(j.TravelExpense),
              'Remarks': j.Remarks,
              'Adjustment': 0,
              'statusId': 1,//scheduled
              'clientId': parseInt(this.selectedJobClientId),
              'OperatorId':4,
              'PayRate':parseFloat( j.PayRate),
              'Rate':j.InvoiceRate,//1.2,
              'startTime': j.StartTime,
              'endTime':j.EndTime,
          });
        return true;
      });

      let requestUrl = this.smsapi_endpoint_base_url+ "schedule/AddJobs";
        let requestOptions = {
          method:"POST",
          headers:{
            'Authorization':"Bearer " + localStorage.token,
            'Content-Type':"application/json"
          },
          body: JSON.stringify(
            jobsToAdd//jobDetailToUpdate
          )
        }

        fetch(requestUrl, requestOptions)
        .then(response => response.json())
        .then( res => {
          if(res != null && res.length > 0)
          {
            alert("Jobs added successfully to the schedule");
            this.siteSchedule.splice(0, this.siteSchedule.length);
          }
        });
    },
    getSiteClients(selectedSiteId){
      //console.log("selectedSiteId...", selectedSiteId);
      // console.log("localStorage.token", localStorage.token);
        let requestUrl = this.smsapi_endpoint_base_url + "Companies/SiteClientsNameList";//+selectedSiteId;
        let requestParameters = "?Id="+ selectedSiteId;
        let requestOptions = {
        method:"POST",
        headers:{
          "Content-Type":"application/json",
          "Authorization":"Bearer " + localStorage.token
        },
        body:JSON.stringify({
          'Id':parseInt(selectedSiteId)
        })
      }

        fetch(requestUrl + requestParameters, requestOptions)
        .then(response => response.json())
        .then(result=>{
              //console.log("result = ", result);
              this.selectedSiteClients=result.siteClients;

              if(this.selectedSiteClients != null)
              {
                this.selectedCompanyId = this.selectedSiteClients[0].id;
                this.selectedClientName=this.selectedSiteClients[0].name;
                this.selectedClientSiteInvoiceRate = this.selectedSiteClients[0].rate;
                this.selectedJobClientId = this.selectedCompanyId;
              }
          })
    },
    dateToYYYYMMDD(d) {
      // alternative implementations in https://stackoverflow.com/q/23593052/1850609
      return d && new Date(d.getTime()-(d.getTimezoneOffset()*60*1000)).toISOString().split('T')[0]
    },
    getTime4Digits(time){
        let timehours = time.value.hours;
        let timeMins = time.value.minutes;
        let time4Digits = timehours;

        if(timehours >= 0 && timehours < 10)
          time4Digits = "0" + timehours;
        
        if(timeMins >= 0 && timeMins < 10)
        {
          time4Digits += ":0" + timeMins;
        }
        else
          time4Digits += ":" + timeMins;

          return time4Digits;
    },
    getJobTimings(duty){
        //let dutyDate = duty.scheduleViewModel.jobDate.substring(0, 10);
        let dutyDateFromSchedule = new Date(duty.scheduleViewModel.jobDate.substring(0, 10));
        let dutyDate = dutyDateFromSchedule.toLocaleString("en-GB").substring(0,dutyDateFromSchedule.toLocaleString("en-GB").length-10)  ;
        let dutyStartTime = this.getTime4Digits(duty.scheduleViewModel.startTime);
        let dutyEndTime = this.getTime4Digits(duty.scheduleViewModel.endTime);
        return dutyDate + " ("+ dutyStartTime + "-" + dutyEndTime + ")";
    },
    searchSiteSchedule(){
        if(this.selectedSiteId == 0)
        {
          alert("No site selected. Please select a valid site form the list.");
          return;
        }
    },
    isShownPageButton(pageNo){
      return (pageNo > this.startPageNumber) && (pageNo <= (this.startPageNumber + this.LabelsPerPage));
    },
    getScheduleForSelectedSite(pageNo){
      if(this.selectedSiteId == 0)
      {
        alert("No site selected. Please select a valid site form the list.");
        return;
      }
      this.isSearchMode = true;

      //console.log("pageNo :" + pageNo);
      //console.log("getScheduleForSelectedSite :");
      this.currentPageNo = pageNo;//0;//pageNo
      var StartDate = this.startDate;//new Date();
      var EndDate = this.endDate//new Date();

      let requestUrl = this.smsapi_endpoint_base_url + "schedule/SiteScheduleForDateRangePageWise";
      let requestOptions = {
        method:"POST",
        headers:{
          "Content-Type":"application/json",
          "Authorization" : "Bearer " + localStorage.token
        },
        body:JSON.stringify({
          'StartDate':StartDate,
          'EndDate':EndDate,
          'PageNumber': this.currentPageNo,
          'RecordsPerPage':this.recordsPerPage,
          'SearchString': this.searchString,
          'SiteId': parseInt(this.selectedSiteId)
        })
      }

      fetch(requestUrl, requestOptions)
      .then(response =>  {
        let rs = response.json();
        return rs;
      })
      .then(res =>{
        this.totalRecordCount = res.totalRecordCount;
        this.totalResultPages = Math.ceil( this.totalRecordCount/this.recordsPerPage);
        this.schedule = res.scheduleSinglePageResult;
        this.diplayResultPageList();
        return this.schedule;
      })
    },
    diplayResultPageList(){
        let sectionNo = Math.floor(this.currentPageNo/this.LabelsPerPage) ;
        this.startPageNumber = sectionNo * this.LabelsPerPage;
            this.endPageNumber = (sectionNo + 1) * this.LabelsPerPage;
            if (this.endPageNumber >= this.totalResultPages)
            {
                //we don't need a previous-result-pages-arrow
                this.isRangeGreater_Than_LabelsPerPage = false;
                this.endPageNumber = this.totalResultPages;
            }
            else
            {
                //we need a previous-result-pages-arrow
                this.isRangeGreater_Than_LabelsPerPage = true;
            }

            if(this.startPageNumber >= this.LabelsPerPage)
            {
              this.isRangeLess_Than_TotalResultPages = true;
            }
            else
            {
              this.isRangeLess_Than_TotalResultPages = false;
            }
    },
    getSitesList(){
      let requestUrl = this.smsapi_endpoint_base_url + "Sites/SitesNameList";
      // let requestParameters = "?PageNumber=0&RecordsPerPage=10&SearchString="+ this.searchString +"&CompanyId=4";
      let requestParameters = "?PageNumber=0&RecordsPerPage=0&SearchString="+ this.searchString;
      let requestOptions = {
        method:"GET",
        headers:{
          "Content-Type":"application/json",
          "Authorization":"Bearer " + localStorage.token
        },
      }

      fetch(requestUrl + requestParameters, requestOptions)
      .then(response => {
        console.log("response.status = " + response.status);
        if(response.status == 200)//success
        {
          return response.json();
        }
      })
      .then( res => {
        this.Sites = res.sitesNameList;
        //console.log("this.Sites", this.Sites)
      });

    },
    OnSelectedSiteChanged(event){
      let siteId = -1;
      let isSelectedSiteValid = false;
      let selectedSite = event.target.value;
      let indexOfDashSeperator = selectedSite.indexOf("-");
      if ( selectedSite != "undefined" && selectedSite != null && selectedSite != "" &&  indexOfDashSeperator > 0 )
      {
        siteId = selectedSite.substring(0, indexOfDashSeperator)
        let id  = parseInt(siteId)
        if(typeof id == "number")
        {
          isSelectedSiteValid = true;
        }
      }
      
      if( isSelectedSiteValid == false)
      {
        this.selectedSiteId = 0;
        this.selectedSiteName = '';
        this.selectedSiteClients=null;
        alert("please select site name from the list");
        return;
      }
      else
      {
        this.selectedSiteId = siteId;
        this.selectedCompanyId = 0;
        this.selectedClientName='';
        this.getSiteClients(this.selectedSiteId);
      }
    },
    OnSelectedClientChanged(event){
      alert("site client changed111.")

      console.log("event.target.value",event.target.value);
      let selectedClient = event.target.value;
      if(selectedClient == "undefined" || selectedClient == null || selectedClient == "")
      {
        let indexOfDashSeperator = selectedClient.indexOf("-");

      if(indexOfDashSeperator > 0)
      {
        let selectedClientId = selectedClient.substring(0, indexOfDashSeperator);
        console.log("selectedClient",selectedClientId);

        if(selectedClientId == "undefined" || selectedClientId == null || selectedClientId == "")
        {
          this.selectedCompanyId = 0;
          this.selectedClientName='';
          this.selectedClient=null;
        }
        else
        {
          this.selectedCompanyId = selectedClient.id;
          this.selectedClientName=selectedClient.name;
          this.selectedClient=selectedClient;
          alert("site client changed222.")
        }
    }
      // console.log("selectedClient",selectedClient);
      // alert("selectedClient : "+ selectedClient.id + ", " + selectedClient.name);
      }

      
    },
  },
  mounted(){
    this.getSitesList();
    this.getOfficersList();
    
  }
}
</script>
<style>
.card {
  background: lightpink;
  border:1px solid;
}
</style>
