<template language="ts" >

     

<!-- <div class="container bg-info h-100" style="width:40%;height:100%;"  > -->
<div class="container h-100 w-100" style="height:100%;"  >

<div class="text-danger h2 w-100" v-show="!isLogginSuccessfull || !isLoginAuthorized"> Please enter userName and password to login.</div>
       
<input type="text" class="m-2" v-model="userName" id="userName" placeholder="Enter User Name">
<br>
<input type="password" class="m-2" v-model="password" id="password" placeholder="Enter password">
<br>
<button type="button" class="btn btn-primary btn-sm m-1" @click="tryLogin" >Login</button>

<div v-show="!isLoginAuthorized" class="text-danger h2 w-100"> Either username or password is incorrect.<br> Please Try again.</div>
<!-- <div v-show="isLogginSuccessfull" class="text-danger h2 w-100"> You have logged in ssuccessfully.</div> -->
</div>

       
      
</template>

<script>
export default({
    name:"Login",
    components:{

    },
    props:{
        msg:String,
        isLoginVisible:Boolean,
        smsapi_endpoint_base_url:String,
    },
    computed:{

    },
    data(){
        return {
            userName:'',//'shouket',//
            password:'',//'ali121',/'',//
            token:'',
            isLoginAuthorized:true,
            isLogginSuccessfull:false,
        }
    },
    methods:{
        tryLogin(){
            var options = {
                method:'POST',
                headers:{
                'Content-Type':'application/json'
                },
                body:JSON.stringify({
                    'UserId':0,
                    'UserName': this.userName,//'shouket',//
                    'Password': this.password,//'ali121',//
                }),
            }
        var url = this.smsapi_endpoint_base_url + "Login/AdminLoginForTokenNew";
        //var url = this.smsapi_endpoint_base_url + "Login/AdminLoginForToken";
        //fetch("https://localhost:44325/Login/AdminLoginForToken", options)
        //fetch("http://emergingsols.somee.com/Login/AdminLoginForToken", options)
        //fetch("http://emergingsols.somee.com/Login/UserLoginForToken", options)
        fetch(url, options)
            .then(response => //response.json()
            {
                var res = response.json();
                //console.log("res..." , res);
                if(response.status == 400 || response.status == 401)
                {
                    localStorage.setItem('token', "");
                    this.isLoginAuthorized=false;
                    this.isLogginSuccessfull = false;
                    this.$emit('login-status',this.isLogginSuccessfull);
                    this.LonginState = false;
                    return null;
                }
                else if(response.status == 404)
                {
                    localStorage.setItem('token', "");
                    this.isLoginAuthorized=false;
                    this.isLogginSuccessfull = false;
                    this.$emit('login-status',this.isLogginSuccessfull);
                    this.LonginState = false;
                    return null;
                }                
                return res;
            })
            .then(res => {                
                if(res.token != null || res.token != "")
                {            
                    localStorage.setItem('token', res.userViewModel.token);            
                    localStorage.setItem('userId', res.userViewModel.id);
                    this.isLoginAuthorized=true;
                    this.isLogginSuccessfull = true;
                    this.$emit('login-status',this.isLogginSuccessfull);
                    this.$emit('login-info',{password:this.password, userId:res.userViewModel.id});
                    this.LonginState = true;
                }
                else
                {
                    localStorage.setItem('token', "");
                    this.isLoginAuthorized=false;
                    this.isLogginSuccessfull = false;
                    this.$emit('login-status',this.isLogginSuccessfull);
                    this.LonginState = false;
                }
           
                localStorage.setItem('isLogginSuccessfull', this.isLogginSuccessfull);
            })
        },
    },
});
</script>

<style>
</style>