import { IClientSite } from '../Companies/IClientSite';
import { ISite } from './ISite';

// export interface ISite{
//     Id:number;
//     Name:String;
//     StreetAddress:String;
//     PostCode:String;
//     City:String;
//     Client:String;
// }

class Site implements ISite{
    siteId: number;
    siteName: String;
    streetAddress: String;
    postCode: String;
    cityName: String;
    County: string;
    Town: string;
    siteCode: string;
    Active: boolean;
    cityId:number;
    Client: String[];
    SiteClients: IClientSite[];

    /**
     * 
     * @param id 
     * @param name 
     * @param streetAddress 
     * @param postCode 
     * @param cityName 
     * @param siteCode 
     * @param County
     *  @param Town
     * @param Active
     * @param cityId 
     * @param client 
     * @param SiteClients
     * 
     */
    constructor(id:number, name:String, streetAddress:String, postCode:String, cityName:String, county:string, town:string, siteCode:string, active: boolean, 
        cityId:number,client:String[], siteClients:IClientSite[]) {
        
        this.siteId=id;
        this.siteName=name;
        this.streetAddress=streetAddress;
        this.postCode=postCode;
        this.siteCode=siteCode;
        this.cityName=cityName;
        this.County=county;
        this.Town=town;
        this.Active=active;
        this.cityId=cityId;
        this.Client=client;
        this.SiteClients= siteClients;
    }
    
}
export {Site} 
// export default Site;