import { RecordOperations } from "./RecordOperations";
import { IClientSite } from "./IClientSite";

class ClientSite implements IClientSite{
    ClientSiteId: number;
    SiteId: number;
    CompanyId: number;
    Rate: number;
    Remarks: string;
    RecordOperation: RecordOperations;
    ClientInfo:string;

    /**
     *
     */
    constructor(companyId: number,  siteId: number,  rate: number, clientSiteId: number, remarks: string, recordOperation: RecordOperations, clientInfo:string ) 
    {
        this.ClientSiteId = clientSiteId
        this.SiteId = siteId;
        this.CompanyId = companyId;
        this.Rate = rate;
        this.Remarks = remarks;
        this.RecordOperation=recordOperation;
        this.ClientInfo=clientInfo;
    }
    
}
export{ClientSite};