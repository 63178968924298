<template>
  <div class="container">

       <!-- Button trigger modal -->
<button type="button" class="btn btn-primary collapse" id="officerDetailModalButton" data-bs-toggle="modal" data-bs-target="#officerDetailModal">
<!-- <button type="button" class="btn btn-primary" @click="ShowSiteDetail(133333)"> -->
Launch demo modal
</button>

<button type="button" class="btn btn-primary collapse" id="UpdateOfficerModalButton" data-bs-toggle="modal" data-bs-target="#UpdateOfficerModal">
<!-- <button type="button" class="btn btn-primary" @click="ShowSiteDetail(133333)"> -->
Launch demo modal
</button>

<div class="modal" id="UpdateOfficerModal">
<div class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header pt-0 pb-0">
      <div class="row col-12">

        <span class="text-center col-10 h2" v-if="isEntryMode"> Update Officer </span>
        <span class="text-center col-10 h2" v-if="!isEntryMode"> Officer Details</span>
        <button type="button" class="btn-close col-2 text-right mt-2 mr-2" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
    </div>
    <div class="modal-body row col-12 m-0 pt-0 pb-0">
      <div class="container text-left ">


        <!--Personal Information-->
        <div class="row col-12 border border-1 m-2 pt-2 pb-2">
          <div class="container text-left">

            <div class="row">
              <span class="col-12 fw-bold h4 mb-2">Personal Information</span>
            </div>

            <div class="row col-12 text-left">
                <span  class="col-4"> First Name </span>
                <input class="col-8" v-if="isEntryMode" type="input" v-model="securityOfficerObject.FirstName">
                <span class="col-8" v-if="!isEntryMode">{{securityOfficerObject.FirstName}}</span>
            </div>

            <div class="row col-12 text-left">
                <span  class="col-4"> Last Name </span>
                <input class="col-8" v-if="isEntryMode" type="input" v-model="securityOfficerObject.LastName">
                <span class="col-8" v-if="!isEntryMode">{{securityOfficerObject.LastName}}</span>
            </div>

            <div class="row col-12 text-left">
                <span  class="col-4"> Previously Name</span>
                <input class="col-8" type="input" v-model="securityOfficerObject.PreviouslyKnownAs">
            </div>
          </div>
        </div>



        <!--Work Information-->
        <div class="row col-12 border border-1 m-2 pt-2 pb-2">
          <div class="container">
            <div class="row">
              <span class="h4 mb-2 fw-bold">Work Information</span>
            </div>

            <div class="row col-12 text-left">
              <span  class="col-4"> Company </span>
              <input class="col-8" list="companiesList" v-if="isEntryMode" type="input" v-model="securityOfficerObject.SecurityCompanyId">
              <span class="col-8" list="companiesList" v-if="!isEntryMode">{{securityOfficerObject.SecurityCompanyId}}</span>
              <datalist id="companiesList" >
                <option v-for="company in companies" v-bind:key="company.companyId">
                  <span>{{company.companyId + "-" + company.companyName}}</span>
                </option>
              </datalist>

            </div>

            <div class="row col-12 text-left">
                <span  class="col-4"> SIA # </span>
                <input class="col-8" v-if="isEntryMode" type="input" v-model="securityOfficerObject.SiabadageNumber">
              <span class="col-8" v-if="!isEntryMode">{{securityOfficerObject.SiabadageNumber}}</span>
            </div>


            <div class="row col-12 text-left">
                <span  class="col-4"> NIN </span>
                <input class="col-8" v-if="isEntryMode" type="input" v-model="securityOfficerObject.Ninumber">
              <span class="col-8" v-if="!isEntryMode">{{securityOfficerObject.Ninumber}}</span>
            </div>

            <!-- <div class="row col-12 text-left">
                <span  class="col-4"> DesignationId </span>
                <input class="col-8" type="input" v-model="securityOfficerObject.DesignationId">
            </div>

            <div class="row col-12 text-left">
                <span  class="col-4"> DeptId </span>
                <input class="col-8" type="input" v-model="securityOfficerObject.DeptId">
            </div> -->

            <!-- <div class="row col-12 text-left">
                <span  class="col-4"> PassportId </span>
                <input class="col-8" type="input" v-model="securityOfficerObject.PassportId">
            </div> -->

            <!-- <div class="row col-12 text-left">
                <span  class="col-4"> ConveyanceId </span>
                <input class="col-8" type="input" v-model="securityOfficerObject.ConveyanceId">
            </div> -->

            <div class="row col-12 text-left">
                <span  class="col-4"> SiaExpiryDate </span>
                <span  class="col-4">({{dateToYYYYMMDD(securityOfficerObject.SiaExpiryDate)}}) </span>
                <input class="col-4 datepicker" v-if="isEntryMode" type="date" v-model="securityOfficerObject.SiaExpiryDate">
                <span class="col-8" v-if="!isEntryMode">{{securityOfficerObject.SiaExpiryDate}}</span>
                <!-- <input type = "date" v-model="selectedJobDate" class = "col-12 datepicker">   -->

            </div>

            <div class="row col-12 text-left">
                <span  class="col-4"> PayRate </span>
                <input class="col-8" v-if="isEntryMode" type="number" v-model="securityOfficerObject.PayRate">
              <span class="col-8" v-if="!isEntryMode">{{securityOfficerObject.PayRate}}</span>
            </div>

          </div>
        </div>

        <!--Contact Information-->
        <div class="row col-12 border border-1 m-2">
          <div class="container text-left">

          <div class="row">
            <span class="h4 mb-2 fw-bold">Contant Information</span>
          </div>

          <div class="row col-12 text-left">
            <span  class="col-4"> Mobile </span>
            <input class="col-8" v-if="isEntryMode" type="input" v-model="securityOfficerObject.MobileNumber">
            <span class="col-8" v-if="!isEntryMode">{{securityOfficerObject.MobileNumber}}</span>
          </div>

          <div class="row col-12 text-left">
            <span  class="col-4"> Email </span>
            <input class="col-8" v-if="isEntryMode" type="input" v-model="securityOfficerObject.Email">
            <span class="col-8" v-if="!isEntryMode">{{securityOfficerObject.Email}}</span>
          </div>

          <div class="row col-12 text-left">
            <span  class="col-4"> Phone2 </span>
            <input class="col-8" v-if="isEntryMode" type="input" v-model="securityOfficerObject.Phone2">
            <span class="col-8" v-if="!isEntryMode">{{securityOfficerObject.Phone2}}</span>
        </div>


        </div>
        </div>

        <div class="row col-12 border border-1 m-2 pt-2 pb-2">
          <div class="container">
            <div class="row">
              <span class="mb-2 fw-bold h4">Address Information</span>
            </div>

            <div class="row col-12 text-left">
                <span  class="col-4"> House #/Name</span>
                <input class="col-8" v-if="isEntryMode" type="input" v-model="securityOfficerObject.HouseNameNumber">
                <input class="col-8" v-if="!isEntryMode" type="input" v-model="securityOfficerObject.HouseNameNumber">
            </div>

            <div class="row col-12 text-left">
                <span  class="col-4"> Street </span>
                <input class="col-8" v-if="isEntryMode" type="input" v-model="securityOfficerObject.StreetAdress">
                <span class="col-8" v-if="!isEntryMode">{{securityOfficerObject.StreetAdress}}</span>
            </div>
            <div class="row col-12 text-left">
                <span  class="col-4"> City </span>
                <input class="col-8" v-if="isEntryMode" list="citiesList" type="input" v-model="selectedCityName">
                <span class="col-8" v-if="!isEntryMode" list="citiesList" >{{selectedCityName}}</span>

                <datalist id="citiesList">
                  <option v-for="city in cityList" v-bind:key="city.cityId"> {{ city.name}}</option>
                </datalist>
            </div>

            <div class="row col-12 text-left">
                <span  class="col-4"> County </span>
                <input class="col-8" v-if="isEntryMode" type="input" v-model="securityOfficerObject.County">
                <span class="col-8" v-if="!isEntryMode">{{securityOfficerObject.County}}</span>
            </div>

            <div class="row col-12 text-left">
                <span  class="col-4"> PostCode </span>
                <input class="col-8" v-if="isEntryMode" type="input" v-model="securityOfficerObject.PostCode">
                <span class="col-8" v-if="!isEntryMode">{{securityOfficerObject.PostCode}}</span>
            </div>



            <div class="row col-12 text-left" v-show="false">
                <span  class="col-4"> Town </span>
                <input class="col-8" v-if="isEntryMode" type="input" v-model="securityOfficerObject.Town">
                <span class="col-8" v-if="!isEntryMode">{{securityOfficerObject.Town}}</span>
            </div>

            <div class="row col-12 text-left">
                <span  class="col-4"> NextOfKin </span>
                <input class="col-8" v-if="isEntryMode" type="input" v-model="securityOfficerObject.NextOfKin">
                <span class="col-8"  v-if="!isEntryMode">{{securityOfficerObject.NextOfKin}}</span>
            </div>

            <div class="row col-12 text-left">
              <span  class="col-4"> Country </span>
              <span class="col-8"  v-if="!isEntryMode">{{selectedAddressCountry}}</span>
              <input class="col-8" v-if="isEntryMode" list="countriesList"  type="input" v-model="selectedAddressCountry">
              <datalist id="countriesList">
                <option v-for="n in nationalityList" v-bind:key="n.id">{{ n.name }}</option>
              </datalist>
          </div>

          </div>
        </div>


        
            
        <div class="row col-12 border border-1 m-2 pt-2 pb-2">
            <div class="container">
              <div class="row">
                <span class="mb-2 fw-bold h4">Origin Information</span>
              </div>

              <div class="row col-12 text-left">
                  <span  class="col-4"> DateOfBirth </span>
                  <span class=" border border-1 col-8">
                    <span  class="col-6">({{dateToYYYYMMDD(securityOfficerObject.DateOfBirth)}}) </span>
                    <input class="col-6"  type="date" v-model="securityOfficerObject.DateOfBirth">
                  </span>
              </div>


              <div class="row col-12 text-left">
                  <span  class="col-4"> Country Of Birth </span>
                  <input class="col-8" list="countriesList"  type="input" v-model="selectedCountryOfBirth">
                  <datalist id="countriesList">
                    <option v-for="n in nationalityList" v-bind:key="n.id">{{ n.name }}</option>
                  </datalist>
              </div>

              <div class="row col-12 text-left">
                  <span  class="col-4"> Nationality</span>
                  <input class="col-8" list="nationalitiesList" type="input" v-model="selectedNationality">
                  <datalist id="nationalitiesList">
                    <option v-for="n in nationalityList" v-bind:key="n.id">{{ n.name }}</option>
                  </datalist>
              </div>

              <div class="row col-12 text-left">
                  <span  class="col-4"> Gender</span>
                  <input class="col-8" list="gendersListView" type="input" v-model="selectedGenderType">
                  <datalist id="gendersListView">
                    <option v-for="g in genderList" v-bind:key="g.id">{{ g.name }}</option>
                  </datalist>
              </div>

              <div class="row col-12 text-left">
                  <span  class="col-4"> Place Of Birth </span>
                  <input class="col-8" type="input" v-model="securityOfficerObject.PlaceOfBirth">
              </div>

            </div>
          </div>


                            
        <div class="row col-12 border border-1 m-2 pt-2 pb-2">
          <div class="container">
            <div class="row">
              <span class="mb-2 fw-bold h4">Identity Document Information</span>
            </div>

            <div class="row col-12 text-left">
                <span  class="col-4"> ID Document</span>
                <input class="col-8" type="input" list="documentTypes" v-model="selectedIdDocumentTypeName">
                <datalist id="documentTypes">
                  <option v-for="dt in documentTypesList" v-bind:key="dt.id" > {{ dt.name }}</option>
                </datalist>
            </div>
        
            <div class="row col-12 text-left">
                <span  class="col-4"> Expiry Date </span>
                <span class=" border border-1 col-8">
                  <span  class="col-6">({{dateToYYYYMMDD(IdDocumentInfo.expiryDate)}}) </span>
                  <input class="col-6"  type="date" v-model="IdDocumentInfo.expiryDate">
                </span>
            </div> 

            <div class="row col-12 text-left">
                <span  class="col-4"> Number </span>
                <input class="col-8" type="input" v-model="IdDocumentInfo.number">
            </div>

            <div class="row col-12 text-left">
                <span  class="col-4"> Issue Date </span>
                <span class=" border border-1 col-8">
                  <span  class="col-6">({{dateToYYYYMMDD(IdDocumentInfo.issueDate)}}) </span>
                  <input class="col-6"  type="date" v-model="IdDocumentInfo.issueDate">
                </span>
            </div>

           <div class="row col-12 text-left">
                <span  class="col-4"> Issued By </span>
                <input class="col-8" list="countriesList"  type="input" v-model="selectedIdDocumentCountryOfIssue">
                <datalist id="countriesList">
                  <option v-for="n in nationalityList" v-bind:key="n.id">{{ n.name }}</option>
                </datalist>
            </div>   

          </div>
        </div>

      </div>

    </div>
    <div class="modal-footer pt-0 p-4">
      <button type="button" v-if="isEntryMode" @click="UpdateOfficer"> Update Officer</button>
    </div>

  </div>

</div>
</div>


<div class="modal" id="AddOfficerModal">
<div class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header pt-0 pb-0">
      <div class="row col-12">

        <span class="text-center col-10 h2"> Add New Officer </span>
        <button type="button" class="btn-close col-2 text-right mt-2 mr-2" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
    </div>
    <div class="modal-body row col-12 m-0 pt-0 pb-0">
      <div class="container text-left ">


        <!--Personal Information-->
        <div class="row col-12 border border-1 m-2 pt-2 pb-2">
          <div class="container text-left">

            <div class="row">
              <span class="col-12 fw-bold h4 mb-2">Personal Information</span>
            </div>

            <div class="row col-12 text-left">
                <span  class="col-4"> First Name </span>
                <input class="col-8" type="input" v-model="securityOfficerObject.FirstName">
            </div>

            <div class="row col-12 text-left">
                <span  class="col-4"> Last Name </span>
                <input class="col-8" type="input" v-model="securityOfficerObject.LastName">
            </div>

            <div class="row col-12 text-left">
                <span  class="col-4"> Previously Name</span>
                <input class="col-8" type="input" v-model="securityOfficerObject.PreviouslyKnownAs">
            </div>
          </div>
        </div>



        <!--Work Information-->
        <div class="row col-12 border border-1 m-2 pt-2 pb-2">
          <div class="container">
            <div class="row">
              <span class="h4 mb-2 fw-bold">Work Information</span>
            </div>

            <div class="row col-12 text-left">
              <span  class="col-4"> Company </span>
              <input class="col-8" list="companiesList" type="input" v-model="securityOfficerObject.SecurityCompanyId">
              <datalist id="companiesList" >
                <option v-for="company in companies" v-bind:key="company.companyId">
                  <span>{{company.companyId + "-" + company.companyName}}</span>
                </option>
              </datalist>

            </div>

            <div class="row col-12 text-left">
                <span  class="col-4"> SIA # </span>
                <input class="col-8" type="input" v-model="securityOfficerObject.SiabadageNumber">
            </div>


            <div class="row col-12 text-left">
                <span  class="col-4"> NIN </span>
                <input class="col-8" type="input" v-model="securityOfficerObject.Ninumber">
            </div>

            <!-- <div class="row col-12 text-left">
                <span  class="col-4"> DesignationId </span>
                <input class="col-8" type="input" v-model="securityOfficerObject.DesignationId">
            </div>

            <div class="row col-12 text-left">
                <span  class="col-4"> DeptId </span>
                <input class="col-8" type="input" v-model="securityOfficerObject.DeptId">
            </div> -->

            <!-- <div class="row col-12 text-left">
                <span  class="col-4"> PassportId </span>
                <input class="col-8" type="input" v-model="securityOfficerObject.PassportId">
            </div> -->

            <!-- <div class="row col-12 text-left">
                <span  class="col-4"> ConveyanceId </span>
                <input class="col-8" type="input" v-model="securityOfficerObject.ConveyanceId">
            </div> -->

            <div class="row col-12 text-left">
                <span  class="col-4"> SiaExpiryDate </span>
                <span  class="col-4">({{dateToYYYYMMDD(securityOfficerObject.SiaExpiryDate)}}) </span>
                <input class="col-4" type="date" v-model="securityOfficerObject.SiaExpiryDate">
            </div>

            <div class="row col-12 text-left">
                <span  class="col-4"> PayRate </span>
                <input class="col-8" type="number" v-model="securityOfficerObject.PayRate">
            </div>

          </div>
        </div>

        <!--Contact Information-->
        <div class="row col-12 border border-1 m-2">
          <div class="container text-left">

          <div class="row">
            <span class="h4 mb-2 fw-bold">Contant Information</span>
          </div>

          <div class="row col-12 text-left">
            <span  class="col-4"> Mobile </span>
            <input class="col-8" type="input" v-model="securityOfficerObject.MobileNumber">
          </div>

          <div class="row col-12 text-left">
            <span  class="col-4"> Email </span>
            <input class="col-8" type="input" v-model="securityOfficerObject.Email">
          </div>

          <div class="row col-12 text-left">
            <span  class="col-4"> Phone2 </span>
            <input class="col-8" type="input" v-model="securityOfficerObject.Phone2">
        </div>


        </div>
        </div>

        <!-- Address Information -->
        <div class="row col-12 border border-1 m-2 pt-2 pb-2">
          <div class="container">
            <div class="row">
              <span class="mb-2 fw-bold h4">Address Information</span>
            </div>

            <div class="row col-12 text-left">
                <span  class="col-4"> House #/Name</span>
                <input class="col-8" type="input" v-model="securityOfficerObject.HouseNameNumber">
            </div>

            <div class="row col-12 text-left">
                <span  class="col-4"> Street </span>
                <input class="col-8" type="input" v-model="securityOfficerObject.StreetAdress">
            </div>

            <div class="row col-12 text-left">
                <span  class="col-4"> City </span>
                <input class="col-8" list="citiesList" type="input" v-model="selectedCityName">
                <datalist id="citiesList">
                  <option v-for="city in cityList" v-bind:key="city.cityId"> {{ city.name}}</option>
                </datalist>
            </div>

            <div class="row col-12 text-left">
                <span  class="col-4"> County </span>
                <input class="col-8" type="input" v-model="securityOfficerObject.County">
            </div>

            <div class="row col-12 text-left">
                <span  class="col-4"> PostCode </span>
                <input class="col-8" type="input" v-model="securityOfficerObject.PostCode">
            </div>

            <div class="row col-12 text-left">
              <span  class="col-4"> Country </span>
              <input class="col-8" list="countriesList"  type="input" v-model="selectedAddressCountry">
              <datalist id="countriesList">
                <option v-for="n in nationalityList" v-bind:key="n.id">{{ n.name }}</option>
              </datalist>
          </div>

            <div class="row col-12 text-left" v-show="false">
                <span  class="col-4"> Town </span>
                <input class="col-8" type="input" value="">
            </div>

            <div class="row col-12 text-left">
                <span  class="col-4"> NextOfKin </span>
                <input class="col-8" type="input" v-model="securityOfficerObject.NextOfKin">
            </div>


          </div>
        </div>


        <!-- Origin Information -->
        <div class="row col-12 border border-1 m-2 pt-2 pb-2">
            <div class="container">
              <div class="row">
                <span class="mb-2 fw-bold h4">Origin Information</span>
              </div>

              <div class="row col-12 text-left">
                  <span  class="col-4"> DateOfBirth </span>
                  <span class=" border border-1 col-8">
                    <span  class="col-6">({{dateToYYYYMMDD(securityOfficerObject.DateOfBirth)}}) </span>
                    <input class="col-6"  type="date" v-model="securityOfficerObject.DateOfBirth">
                  </span>
              </div>


              <div class="row col-12 text-left">
                  <span  class="col-4"> Country Of Birth </span>
                  <input class="col-8" list="countriesList"  type="input" v-model="selectedCountryOfBirth">
                  <datalist id="countriesList">
                    <option v-for="n in nationalityList" v-bind:key="n.id">{{ n.name }}</option>
                  </datalist>
              </div>

              <div class="row col-12 text-left">
                  <span  class="col-4"> Nationality</span>
                  <input class="col-8" list="nationalitiesList" type="input" v-model="selectedNationality">
                  <datalist id="nationalitiesList">
                    <option v-for="n in nationalityList" v-bind:key="n.id">{{ n.name }}</option>
                  </datalist>
              </div>

              <div class="row col-12 text-left">
                  <span  class="col-4"> Gender</span>
                  <input class="col-8" list="gendersListView" type="input" v-model="selectedGenderType">
                  <datalist id="gendersListView">
                    <option v-for="g in genderList" v-bind:key="g.id">{{ g.name }}</option>
                  </datalist>
              </div>

              <div class="row col-12 text-left">
                  <span  class="col-4"> Place Of Birth </span>
                  <input class="col-8" type="input" v-model="securityOfficerObject.PlaceOfBirth">
              </div>

            </div>
          </div>


          
<div class="row col-12 border border-1 m-2 pt-2 pb-2">
  <div class="container">
    <div class="row">
      <span class="mb-2 fw-bold h4">Identity Document Information</span>
    </div>


            <div class="row col-12 text-left">
                <span  class="col-4"> ID Document</span>
                <input class="col-8" type="input" list="documentTypes" v-model="selectedIdDocumentTypeName">
                <datalist id="documentTypes">
                  <option v-for="dt in documentTypesList" v-bind:key="dt.id" > {{ dt.name }}</option>
                </datalist>
            </div>
        
            <div class="row col-12 text-left">
                <span  class="col-4"> Expiry Date </span>
                <span class=" border border-1 col-8">
                  <span  class="col-6">({{dateToYYYYMMDD(IdDocumentInfo.expiryDate)}}) </span>
                  <input class="col-6"  type="date" v-model="IdDocumentInfo.expiryDate">
                </span>
            </div> 

            <div class="row col-12 text-left">
                <span  class="col-4"> Number </span>
                <input class="col-8" type="input" v-model="IdDocumentInfo.number">
            </div>

            <div class="row col-12 text-left">
                <span  class="col-4"> Issue Date </span>
                <span class=" border border-1 col-8">
                  <span  class="col-6">({{dateToYYYYMMDD(IdDocumentInfo.issueDate)}}) </span>
                  <input class="col-6"  type="date" v-model="IdDocumentInfo.issueDate">
                </span>
            </div>

           <div class="row col-12 text-left">
                <span  class="col-4"> Issued By </span>
                <input class="col-8" list="countriesList"  type="input" v-model="selectedIdDocumentCountryOfIssue">
                <datalist id="countriesList">
                  <option v-for="n in nationalityList" v-bind:key="n.id">{{ n.name }}</option>
                </datalist>
            </div>   

 

  </div>
</div>


      </div>

    </div>
    <div class="modal-footer pt-0 p-4">
      <button type="button" @click="ClearData"> Clear</button>
      <button type="button" @click="AddOfficer"> Add Officer</button>
    </div>

  </div>

</div>
</div>

<div class="flex-container justify-content-ceter m-2">
    <input type="text bg-light text-dark"  v-model="searchString" placeholder="Enter Search String">

    <input list="companiesList" class="bg-light text-dark" v-model="selectedCompanyId" placeholder="Company">
    <datalist id="companiesList">
      <option v-for="company in companies" v-bind:key="company.companyId">
      {{company.companyId +"-"+ company.companyName}}
      </option>
    </datalist>
    <button type="button" class="btn btn-primary btn-sm m-2"  @click="getSecurityOfficers(0)">Search</button>
    <button type="button" id="AddOfficerModalButton" @click="OnAddOfficer" class="btn btn-primary  btn-sm" data-bs-toggle="modal" data-bs-target="#AddOfficerModal" data-bs-whatever="@getbootstrap">Add Officer</button>

</div>

<!-- Modal -->
<div class="modal" id="officerDetailModal-old">
<!-- <div class="modal" v-if="isModalShown"> -->
<div class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title fw-bold" id="officerDetailModalLabel">Detail for Officer with ID = {{modalContent.securityOfficerId}}</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <div class="modal-body text-left">
      <div class="text-warning"><span class="text-primary">ID : </span> {{modalContent.securityOfficerId}}</div>
      <div class="text-warning"><span class="text-primary">NAME : </span> {{modalContent.name}}</div>
      <div class="text-warning"><span class="text-primary">CITY : </span> {{modalContent.cityName}}</div>
      <div class="text-warning"><span class="text-primary">STREET ADDRESS : </span> {{modalContent.streetAddress}}</div>
      <div class="text-warning"><span class="text-primary">POST CODE : </span> {{modalContent.postCode}}</div>
      <div class="text-warning"><span class="text-primary">SIA Badge : </span> {{modalContent.siabadageNumber}}</div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
    </div>
  </div>
</div>
</div>



<div>
<span v-show="!isAuthorized" class="text-danger h2"> Please make sure you are logged in.</span>
</div>

<div class="row m-2">

      <table class="table text-left table-hover bg-white text-dark" v-show="isAuthorized">
          <thead>
              <tr>
                  <!-- <th>Id</th> -->
                  <th>Name</th>
                  <th>Street Address</th>
                  <th>Post Code</th>
                  <th>City</th>
                  <th>Edit</th>
              </tr>
          </thead>
          <tbody>
              <tr v-for="officer in securityOfficers" v-bind:key="officer" @click="ShowOfficerDetail(officer.securityOfficerId)">
              <!-- <tr v-for="officer in securityOfficers" v-bind:key="officer"> -->
                  <!-- <td>{{officer.securityOfficerId}}</td> -->
                  <td>{{officer.name}}</td>
                  <td>{{officer.streetAddress}}</td>
                  <td>{{officer.postCode}}</td>
                  <td>{{officer.cityName}}</td>
                  <td @click="ShowUpdateOfficerModal(officer)">Edit</td>
              </tr>
          </tbody>
      </table>
</div>

<div class="container mt-2">
 <button class="Btn btn-primary btn-sm m-1" v-show="isRangeLess_Than_TotalResultPages"  @click="getSecurityOfficers(startPageNumber - LabelsPerPage )"> &lt;&lt;</button>
 <span v-if="endPageNumber > 1">
    <span v-for="pageNo in (endPageNumber)" v-bind:key="pageNo">
      <button class="btn btn-success btn-sm m-1" type="button" v-if="isShownPageButton(pageNo)" @click="getSecurityOfficers(pageNo -1 )">{{ pageNo }}</button>
    </span>
    <button type="button" class="Btn btn-primary btn-sm m-1" v-show="isRangeGreater_Than_LabelsPerPage" @click="getSecurityOfficers(endPageNumber)"> >> </button>
</span>
</div>

  </div>
</template>

<script>

import { IdDocument } from '../SecurityOfficers/IdDocument';
import {SecurityOfficer} from '../SecurityOfficers/SecurityOfficer';

export default ({
  name:'SecurityOfficers',
  props:{
      securityOfficerId:String,
      smsapi_endpoint_base_url:String,
  },
  data() {
      return{
          securityOfficers:[],
          modalContent:{},
          searchString:'',
          currentPageNo:0,
          startPageNumber : 1,
          endPageNumber:0,
          LabelsPerPage : 10,
          totalResultPages:0,
          totalRecordCount:0,
          recordsPerPage:10,
          isRangeGreater_Than_LabelsPerPage:false,
          isRangeLess_Than_TotalResultPages:false,
          isAuthorized:false,
          securityOfficerToAdd:null,
          securityOfficerObject:SecurityOfficer,
          IdDocumentInfo:IdDocument,
          companies:[],
          selectedCompanyId:"",
          cityList:[],
          tempOfficerObjectForCopying:SecurityOfficer,
          officerFullDetail:SecurityOfficer,
          isUpdateOfficerModalShown:false,
          isEntryMode:false,
          isNewOfficerToAdd:false,
          selectedCityName:'',
          selectedCityId:0,
          // selectedCountryOfBirthId:0,
          // selectedAddressCountryId:0,
          // selectedNationalityId:0,
          // selectedGenderId:0,
          genderList :[],
          documentTypesList :[],
          nationalityList  :[],
          selectedGenderType:'Male',
          selectedNationality:'United Kingdom',
          selectedCountryOfBirth:'',
          selectedAddressCountry:'United Kingdom',
          selectedIdDocumentCountryOfIssueId:0,
          selectedIdDocumentCountryOfIssue:"",
          documentTypeId:0,
          selectedIdDocumentName:"",
          selectedIdDocumentTypeName:"",
      }
  },
      methods:{
        dateToYYYYMMDD (d){
          // alternative implementations in https://stackoverflow.com/q/23593052/1850609
          return (
            new Date(d)
              // .toLocaleString("en-GB", { timeZone: "UTC" })
              .toLocaleString()
              .substring(0, 10)
          );
        },

        GetOfficerFullDetailById(officerId){
          this.getCityList();
          this.getUtilityLists();

          //console.log("GetOfficerFullDetailById called.....");
          //console.log("cityList.....", this.cityList);
          let GetOfficerFullDetailByIdRequestUrl = this.smsapi_endpoint_base_url + "securityofficers/OfficerFullDetailById";
          let GetOfficerFullDetailByIdRequestOptions = {
            method:"Post",
            headers:{
              "Authorization":`Bearer ${localStorage.token}`,
              "Content-Type":"application/json"
            },
            body: JSON.stringify({
              "Id": parseInt(officerId)
            })
          };
          fetch(GetOfficerFullDetailByIdRequestUrl, GetOfficerFullDetailByIdRequestOptions)
          .then(response =>
          {
            var result = response.json();
            //console.log("this.isUpdateOfficerModalShown.....", this.isUpdateOfficerModalShown);
            if(this.isUpdateOfficerModalShown == false)
            {
              document.getElementById("UpdateOfficerModalButton").click();
            }
            return result;
          })
          .then(res => {
            this.IdDocumentInfo = null;
            let companyInfo ="";
            this.companies.filter( function(c)
            {
              if(c.companyId == res.officerFullDetailViewModel.securityCompanyId)
                companyInfo = c.companyId + "-" + c.companyName;
            });

          //console.log("res.officerFullDetailViewModel", res.officerFullDetailViewModel);

          this.securityOfficerObject = new SecurityOfficer(res.officerFullDetailViewModel.securityOfficerId,
              res.officerFullDetailViewModel.firstName,
              res.officerFullDetailViewModel.lastName,
              res.officerFullDetailViewModel.postCode,
              // cityInfo,//res.officerFullDetailViewModel.cityId,//
              res.officerFullDetailViewModel.cityId,//cityInfo,//
              res.officerFullDetailViewModel.county,
              res.officerFullDetailViewModel.streetAdress,
              res.officerFullDetailViewModel.town,
              true,
              companyInfo,//res.officerFullDetailViewModel.securityCompanyId,
              res.officerFullDetailViewModel.siabadageNumber,
              res.officerFullDetailViewModel.mobileNumber,
              res.officerFullDetailViewModel.ninumber,
              res.officerFullDetailViewModel.designationId,
              res.officerFullDetailViewModel.deptId,
              res.officerFullDetailViewModel.passportId,
              res.officerFullDetailViewModel.conveyanceId,
              res.officerFullDetailViewModel.email,//'e@mail.com',
              res.officerFullDetailViewModel.siaExpiryDate,//new Date(),
              res.officerFullDetailViewModel.phone2,//'phone',
              res.officerFullDetailViewModel.nextOfKin,//'kin',
              res.officerFullDetailViewModel.payRate,//'131',
              res.officerFullDetailViewModel.previouslyKnownAs,//'131',
              res.officerFullDetailViewModel.dateOfBirth,//'131',
              res.officerFullDetailViewModel.placeOfBirth,//'131',
              res.officerFullDetailViewModel.houseNameNumber,//'131',
              res.officerFullDetailViewModel.identityDocumentId,//'131',
              res.officerFullDetailViewModel.countryOfBirthId,//'131',
              res.officerFullDetailViewModel.nationalityId,//'131',
              res.officerFullDetailViewModel.genderId,//'131',
              res.officerFullDetailViewModel.addressCountryId,//'131',
              res.officerFullDetailViewModel.identityDocument,//.resolve(),//'131',
              // '','2023-11-11','','',1,2,3,4,5,new IdDocument(1,'2022-10-10','2022-10-10',1,'')
            );

            this.IdDocumentInfo = res.officerFullDetailViewModel.identityDocument;
            this.selectedIdDocumentCountryOfIssue = this.nationalityList.find((n) => n.id == this.IdDocumentInfo?.countryOfIssueId)?.name;
            // console.log("this.selectedIdDocumentCountryOfIssue", this.selectedIdDocumentCountryOfIssue)
            // console.log("res", res)
            // console.log("this.IdDocumentInfo", this.IdDocumentInfo)


            this.selectedCityName = this.cityList.find(c=>c.cityId == res.officerFullDetailViewModel.cityId).name;
            this.selectedGenderType = this.genderList.find((g)=>g.id == res.officerFullDetailViewModel.genderId)?.name;
            
            //this.selectedConveyanceName = this.conveyanceListArray.find(c=> c.id == res.officerFullDetailViewModel.ConveyanceId)?.name;
            this.selectedNationality =    this.nationalityList.find((n) => n.id == res.officerFullDetailViewModel.nationalityId)?.name;
            this.selectedCountryOfBirth = this.nationalityList.find((n) => n.id == res.officerFullDetailViewModel.countryOfBirthId)?.name;
            this.selectedAddressCountry = this.nationalityList.find((n) => n.id == res.officerFullDetailViewModel.addressCountryId)?.name;
            // this.selectedIdDocumentCountryOfIssue = this.nationalityList.find((n) => n.id == res.officerFullDetailViewModel.IdDocumentCountryOfIssueId)?.name;
            //console.log("this.selectedNationality", this.selectedNationality)
            // alert("selectedIdDocumentCountryOfIssue" + selectedIdDocumentCountryOfIssue)
            
            //console.log("this.documentTypesList", this.documentTypesList)

            this.selectedIdDocumentTypeName = this.documentTypesList.find((n) => n.id == res.officerFullDetailViewModel.identityDocument.typeId)?.name;


          })
          .catch(Error => {
            Error = null;//dummy statement should be removed
            console.log("error ", Error);
            this.officerFullDetail = null;
          });
        },
        ShowUpdateOfficerModal(officer){
          //console.log("ShowUpdateOfficerModal......", officer);
          this.isEntryMode = true;
          
          //console.log("this.isUpdateOfficerModalShown......", this.isUpdateOfficerModalShown);
          this.GetOfficerFullDetailById(officer.securityOfficerId);
          //this.isUpdateOfficerModalShown = true;
        },
        OnAddOfficer(){
          this.getCityList();
          //console.log("OnAddOfficer cityList", this.cityList);

          //  this.securityOfficerObject = new SecurityOfficer(0,'shouket','ali','pcode',2,
          //  'county','st. add','town',true,2,'1234567','03332456','345345',1,1,12345,1,'e@mail.com'
          //  ,new Date(),'phone','kin',131)
          this.IdDocumentInfo = new IdDocument(0,new Date(),new Date(),1,'');//new IdDocument();

          this.securityOfficerObject = new SecurityOfficer(0,'','','','',
           '','','',true,'','','','','','','','',''
           ,new Date(),'','','','',new Date(),'','',0,21,21,1,21,this.IdDocumentInfo)

           //console.log("this.securityOfficerObject 1111",this.securityOfficerObject);
        },
        ClearData(){
          this.securityOfficerObject = [];
          //document.getElementById("officerDetailModalButton").click();
        },
        getUtilityLists(){
                  let requestUrl = this.smsapi_endpoint_base_url + "SecurityOfficers/GenderAndDocumentTypesAndNationalityList";
                  let requestoptions = {
                    method:"GET",
                    headers:{
                      "Authorization":"Bearer " + localStorage.token,
                      "Content-Type":"application/json"
                    },
                  }

                  fetch(requestUrl, requestoptions)
                  .then( response => {
                    let res = response.json();
                    return res;
                  })
                  .then(  r => {
                    this.genderList =r.utilityService.gendersList;
                    this.documentTypesList =r.utilityService.documentTypesList;
                    this.nationalityList = r.utilityService.nationalityList;
                    //console.log("this.genderList", this.genderList);
                  });

                },

        UpdateOfficer(){
          //console.log("this.securityOfficerObject",this.securityOfficerObject);
         
          let selectedCity = this.cityList.find(c=>c.name == this.selectedCityName);
          //console.log("selectedCity",selectedCity);

          if(selectedCity == null || selectedCity == undefined)
          {
            alert("Please selecte city from the dropdown list");
            return;
          }
          else
          {
            this.selectedCityId = this.cityList.find(c=>c.name == this.selectedCityName).cityId;
          }


          let selectedGenderId = this.genderList.find((g) => g.name == this.selectedGenderType)?.id;
              if(selectedGenderId <= 0)
              {
                alert("Please select gender form the drop-down list")
                return;
              }

              let selectedNationalityId = this.nationalityList.find( (n) => n.name == this.selectedNationality)?.id;
              if(selectedNationalityId < 0)
              {
                alert("Please select nationality form the drop-down list")
                return;
              }

               
              let selectedCountryOfBirthId = this.nationalityList.find( (n) => n.name == this.selectedCountryOfBirth)?.id;
              if(selectedCountryOfBirthId < 0)
              {
                alert("Please select birth country form the drop-down list")
                return;
              }

              let selectedAddressCountryId = this.nationalityList.find( (n) => n.name == this.selectedAddressCountry)?.id;
              if(selectedAddressCountryId < 0)
              {
                alert("Please select address country form the drop-down list")
                return;
              }

              this.IdDocumentInfo.countryOfIssueId = this.nationalityList.find( (n) => n.name == this.selectedIdDocumentCountryOfIssue)?.id;
              if(this.IdDocumentInfo.countryOfIssueId < 0)
              {
                alert("Please select IdDocument Issue country form the drop-down list")
                return;
              }

              this.IdDocumentInfo.countryOfIssueId = this.nationalityList.find( (n) => n.name == this.selectedIdDocumentCountryOfIssue)?.id;
              if(this.IdDocumentInfo.countryOfIssueId < 0)
              {
                alert("Please select IdDocument Issue country form the drop-down list")
                return;
              }

              this.IdDocumentInfo.typeId = this.documentTypesList.find( (n) => n.name == this.selectedIdDocumentTypeName)?.id;
              if(this.IdDocumentInfo.typeId < 0)
              {
                alert("Please select IdDocument Issue country form the drop-down list")
                return;
              }

              



          let addOfficerRequestUrl = this.smsapi_endpoint_base_url + "SecurityOfficers/UpdateSecurityOfficer";
          let addOfficerrequestOptions = {
            method:"POST",
            headers:{
              "Authorization":`Bearer ${localStorage.token}`,
              "Content-Type":"application/json"
            },
            body:JSON.stringify({
              "SecurityOfficerId":this.securityOfficerObject.SecurityOfficerId,
              "FirstName":this.securityOfficerObject.FirstName,
              "LastName": this.securityOfficerObject.LastName,
              "PostCode": this.securityOfficerObject.PostCode,
              "CityId": parseInt(this.selectedCityId),//(this.securityOfficerObject.CityId),//1,//
              "County": this.securityOfficerObject.County,
              "StreetAdress": this.securityOfficerObject.StreetAdress,
              "Town": this.securityOfficerObject.Town,
              "Active": this.securityOfficerObject.Active,
              "SecurityCompanyId": parseInt( this.securityOfficerObject.SecurityCompanyId),//2,//
              "SiabadageNumber": this.securityOfficerObject.SiabadageNumber,
              "MobileNumber": this.securityOfficerObject.MobileNumber,
              "Ninumber": this.securityOfficerObject.Ninumber,
              "DesignationId": 1,//this.securityOfficerObject.DesignationId,
              "DeptId": 1,//null,//this.securityOfficerObject.DeptId,
              "PassportId": this.securityOfficerObject.PassportId,//null,//
              "ConveyanceId": 1,//this.securityOfficerObject.ConveyanceId,
              "Email":this.securityOfficerObject.Email,
              "SiaExpiryDate":this.securityOfficerObject.SiaExpiryDate,
              "Phone2":this.securityOfficerObject.Phone2,
              "NextOfKin":this.securityOfficerObject.NextOfKin,
              //"PayRate": parseInt( this.securityOfficerObject.PayRate)
              "PayRate": parseFloat( this.securityOfficerObject.PayRate),
              "PreviouslyKnownAS":this.securityOfficerObject.PreviouslyKnownAs,
              "DateOfBirth":this.securityOfficerObject.DateOfBirth,
              "PlaceOfBirth":this.securityOfficerObject.PlaceOfBirth,
              "HouseNameNumber":this.securityOfficerObject.HouseNameNumber,
              "IdentityDocumentId":this.securityOfficerObject.IdentityDocumentId,
              "CountryOfBirthId": selectedCountryOfBirthId,
              "AddressCountryId": selectedAddressCountryId,
              "NationalityId":selectedNationalityId,
              "GenderId": selectedGenderId,
              // "IdentityDocument": { 'Id':this.securityOfficerObject.identityDocumentId, 'IssueDate':this.securityOfficerObject.IdDocumentInfo.issueDate, 'ExpiryDate':this.securityOfficerObject.IdDocumentInfo.expiryDate, 'CountryOfIssueId':this.selectedIdDocumentCountryOfIssueId, 'Number':this.securityOfficerObject.IdDocumentInfo.number, 'TypeId':this.documentTypeId},
              "IdentityDocument": this.IdDocumentInfo,
                  
            })
          };

          fetch(addOfficerRequestUrl, addOfficerrequestOptions)
          .then(response =>{
            if(response.ok == true)
            {
              alert("Update Officer succeeded.")
              //console.log("Update Officer succeeded.")

              // document.getElementById("AddOfficerModalButton").click();
              document.getElementById("UpdateOfficerModalButton").click();
            }
            else
            {
              //console.log("Update Officer Failed. Try again.")
              alert("Update Officer Failed. Try again.")
            }
            //console.log("response",response);
          })
        },
        AddOfficer(){
          //console.log("this.selectedCityName",this.selectedCityName);

          let selectedCity = this.cityList.find(c=>c.name == this.selectedCityName);
          //console.log("selectedCity",selectedCity);

          if(selectedCity == null || selectedCity == undefined)
          {
            alert("Please selecte city from the dropdown list");
            return;
          }
          else
          {
            this.selectedCityId = this.cityList.find(c=>c.name == this.selectedCityName).cityId;
          }

          let selectedGenderId = this.genderList.find((g) => g.name == this.selectedGenderType)?.id;
          if(selectedGenderId <= 0)
          {
            alert("Please select gender form the drop-down list")
            return;
          }

          let selectedNationalityId = this.nationalityList.find( (n) => n.name == this.selectedNationality)?.id;
          if(selectedNationalityId < 0)
          {
            alert("Please select nationality form the drop-down list")
            return;
          }

            
          let selectedCountryOfBirthId = this.nationalityList.find( (n) => n.name == this.selectedCountryOfBirth)?.id;
          if(selectedCountryOfBirthId < 0)
          {
            alert("Please select birth country form the drop-down list")
            return;
          }

          let selectedAddressCountryId = this.nationalityList.find( (n) => n.name == this.selectedAddressCountry)?.id;
          if(selectedAddressCountryId < 0)
          {
            alert("Please select address country form the drop-down list")
            return;
          }

          this.IdDocumentInfo.countryOfIssueId = this.nationalityList.find( (n) => n.name == this.selectedIdDocumentCountryOfIssue)?.id;
          if(this.IdDocumentInfo.countryOfIssueId < 0)
          {
            alert("Please select IdDocument Issue country form the drop-down list")
            return;
          }

          this.IdDocumentInfo.countryOfIssueId = this.nationalityList.find( (n) => n.name == this.selectedIdDocumentCountryOfIssue)?.id;
          if(this.IdDocumentInfo.countryOfIssueId < 0)
          {
            alert("Please select IdDocument Issue country form the drop-down list")
            return;
          }

          this.IdDocumentInfo.typeId = this.documentTypesList.find( (n) => n.name == this.selectedIdDocumentTypeName)?.id;
          if(this.IdDocumentInfo.typeId < 0)
          {
            alert("Please select IdDocument Issue country form the drop-down list")
            return;
          }

          
              
          //this.IdDocumentInfo.id=0;

        let bodyParameters = {
              "SecurityOfficerId":this.securityOfficerObject.SecurityOfficerId,
              "FirstName":this.securityOfficerObject.FirstName,
              "LastName": this.securityOfficerObject.LastName,
              "PostCode": this.securityOfficerObject.PostCode,
              "CityId": parseInt(this.selectedCityId),//(this.securityOfficerObject.CityId),//1,//
              "County": this.securityOfficerObject.County,
              "StreetAdress": this.securityOfficerObject.StreetAdress,
              "Town": this.securityOfficerObject.Town,
              "Active": this.securityOfficerObject.Active,
              "SecurityCompanyId": parseInt( this.securityOfficerObject.SecurityCompanyId),//2,//
              "SiabadageNumber": this.securityOfficerObject.SiabadageNumber,
              "MobileNumber": this.securityOfficerObject.MobileNumber,
              "Ninumber": this.securityOfficerObject.Ninumber,
              "DesignationId": 1,//this.securityOfficerObject.DesignationId,
              "DeptId": null,//this.securityOfficerObject.DeptId,
              "PassportId": null,//this.securityOfficerObject.PassportId,
              "ConveyanceId": 1,//this.securityOfficerObject.ConveyanceId,
              "Email":this.securityOfficerObject.Email,
              "SiaExpiryDate":this.securityOfficerObject.SiaExpiryDate,
              "Phone2":this.securityOfficerObject.Phone2,
              "NextOfKin":this.securityOfficerObject.NextOfKin,
              "PayRate": parseInt( this.securityOfficerObject.PayRate),
              "PreviouslyKnownAS":this.securityOfficerObject.PreviouslyKnownAs,
              "DateOfBirth":this.securityOfficerObject.DateOfBirth,
              "PlaceOfBirth":this.securityOfficerObject.PlaceOfBirth,
              "HouseNameNumber":this.securityOfficerObject.HouseNameNumber,
              "IdentityDocumentId": 0,//this.securityOfficerObject.IdentityDocumentId,
              "CountryOfBirthId": selectedCountryOfBirthId,
              "AddressCountryId": selectedAddressCountryId,
              "NationalityId":selectedNationalityId,
              "GenderId": selectedGenderId,
              // "IdentityDocument": { 'Id':this.securityOfficerObject.identityDocumentId, 'IssueDate':this.securityOfficerObject.IdDocumentInfo.issueDate, 'ExpiryDate':this.securityOfficerObject.IdDocumentInfo.expiryDate, 'CountryOfIssueId':this.selectedIdDocumentCountryOfIssueId, 'Number':this.securityOfficerObject.IdDocumentInfo.number, 'TypeId':this.documentTypeId},
              "IdentityDocument": this.IdDocumentInfo,
            };
          console.log("bodyParameters", bodyParameters);

          let addOfficerRequestUrl = this.smsapi_endpoint_base_url + "SecurityOfficers/AddSecurityOfficer";
          let addOfficerrequestOptions = {
            method:"POST",
            headers:{
              "Authorization":`Bearer ${localStorage.token}`,
              "Content-Type":"application/json"
            },
            body:JSON.stringify(bodyParameters)
          };

          fetch(addOfficerRequestUrl, addOfficerrequestOptions)
          .then(response =>{
            if(response.ok == true)
            {
              alert("Add New Officer succeeded.")
              //console.log("Add New Officer succeeded.")

              document.getElementById("AddOfficerModalButton").click();
            }
            else
            {
              //console.log("Add New Officer Failed. Try again.")
              alert("Add New Officer Failed. Try again.")
            }
            //console.log("response",response);
          })
        },
      getSecurityOfficers(pageNumber){
      this.currentPageNo = pageNumber;
      //console.log("getsites called")

      let companyId = 0; 
      if(this.selectedCompanyId != undefined)
        companyId = this.selectedCompanyId.substring(0, this.selectedCompanyId.indexOf("-"));

      var options = {
        method:'POST',
        headers:{
          'Authorization': `Bearer ${localStorage.token}`,
          'Content-Type':'application/json'
        },
        body:JSON.stringify({
          'PageNumber': this.currentPageNo,
          'RecordsPerPage':this.recordsPerPage,
          'SearchString': this.searchString,
          'CompanyId': parseInt(companyId)
        }),
    }


    //console.log('this.recordsPerPage', this.recordsPerPage)

    let url = this.smsapi_endpoint_base_url + "securityofficers/securityofficers";
    //fetch("https://localhost:44325/securityofficers/securityofficers", options)
    //fetch("http://emergingsols.somee.com/securityofficers/securityofficers", options)
    fetch(url, options)
    .then(response => //response.json()
        {
          //console.log("response", response);
          var res = response.json()
          if(response.status == 401){
            this.isAuthorized = false;
            return null;
          }
          else{
            this.isAuthorized = true;
            return res;
          }
        })
    .then(result => {
            //this.securityOfficers = result
            if(result == null)
            {
              this.schedule=null;
            }
            this.securityOfficers = result.officersListPage;
            this.totalRecordCount = result.totalRecordCount;
            this.recordsPerPage = result.pb.recordsPerPage;
            this.totalResultPages = (this.totalRecordCount % this.recordsPerPage == 0) ? this.totalRecordCount / this.recordsPerPage : this.totalRecordCount / this.recordsPerPage;
            this.totalResultPages = Math.ceil(this.totalResultPages);
            this.diplayResultPageList();
            // console.log('this.securityOfficers', this.securityOfficers)
            // console.log('this.totalRecordCount', this.totalRecordCount)
            // console.log('this.totalResultPages', this.totalResultPages)
            // console.log('this.currentPageNo', this.currentPageNo)
          }).catch(error=>{
            error=null;//dummy statement should be removed
              console.log("error encountered", error);
              return null;
          });

          this.securityOfficers=[]
          return this.securityOfficers;

      },
  isShownPageButton(pageNo){
    return (pageNo > this.startPageNumber) && (pageNo <= (this.startPageNumber + this.LabelsPerPage));
  },

      diplayResultPageList(){
      let sectionNo = Math.floor(this.currentPageNo/this.LabelsPerPage) ;
      this.startPageNumber = sectionNo * this.LabelsPerPage;
          this.endPageNumber = (sectionNo + 1) * this.LabelsPerPage;
          if (this.endPageNumber >= this.totalResultPages)
          {
              //we don't need a previous-result-pages-arrow
              this.isRangeGreater_Than_LabelsPerPage = false;
              this.endPageNumber = this.totalResultPages;
          }
          else
          {
              //we need a previous-result-pages-arrow
              this.isRangeGreater_Than_LabelsPerPage = true;
          }

          if(this.startPageNumber >= this.LabelsPerPage)
          {
            this.isRangeLess_Than_TotalResultPages = true;
          }
          else
          {
            this.isRangeLess_Than_TotalResultPages = false;
          }
  },
  ShowOfficerDetail(officerId){
    //this.isUpdateOfficerModalShown = false;
    //console.log("ShowOfficerDetail......");
    this.GetOfficerFullDetailById(officerId);
  },
getAuthorization(){
      var token = localStorage.getItem("token");
      if(token == "")
        this.isAuthorized = false;
      else
        this.isAuthorized=true;
    },
    getCompaniesList(){
      let clientsRequestUrl = this.smsapi_endpoint_base_url + "Companies/Companies";
      let clientsRequestOptions = {
        method:"GET",
        headers:{
          'Authorization': `Bearer ${localStorage.token}`,
            "Content-Type":"application/json",
        }
      };

      fetch(clientsRequestUrl, clientsRequestOptions)
      .then( response =>response.json())
      .then(reslt =>{
        this.companies = reslt;
      });
},

getCityList(){
        let sitesRequestUrl = this.smsapi_endpoint_base_url + "Cities/Cities";
          let sitesRequestOptions = {
            method:"GET",
            headers:{
              "Authorization":"Bearer " + localStorage.token,
              "Content-Type" : "application/json"
            },
          };
          fetch(sitesRequestUrl, sitesRequestOptions)
          .then(response =>{
            var result = response.json();
            return result;
          })
          .then(res =>{
            this.cityList = res.cities;
            //console.log("this.cityList.cities , result.cities ", this.cityList.cities , res.cities );
            return res;
          });
          // .then(result => {
          //   this.cityList = result.cities ;
          //   console.log("this.cityList.cities , result.cities ", this.cityList.cities , result.cities );
          // });
      },
  },
  mounted(){
    this.getAuthorization();
    const updateOfficerDetailModalEl = document.getElementById('UpdateOfficerModal')
    updateOfficerDetailModalEl.addEventListener('shown.bs.modal', event => {
          this.isUpdateOfficerModalShown = true;
          console.log(event)
          //console.log("UpdateOfficerModal shown.bs.modal ................." ,event);
          // console.log("this.this.isUpdateOfficerModalShown............" ,this.isUpdateOfficerModalShown);
    });
    updateOfficerDetailModalEl.addEventListener('hidden.bs.modal', event => {
        this.isUpdateOfficerModalShown = false;
        this.isEntryMode = false;
        console.log(event)
        // console.log("UpdateOfficerModal hidden.bs.modal............" ,event);
        // console.log("this.this.isUpdateOfficerModalShown............" ,this.isUpdateOfficerModalShown);
    });

    this.securityOfficerObject = new SecurityOfficer(0,'','','','',
            '','','',true,'','','','','','','','',''
            ,new Date(),'','','','',new Date(),'','',0,21,21,1,21,new IdDocument(1,new Date(),new Date(),1,''))

  },
  beforeMount(){
    this.getCompaniesList();
    this.getCityList();
    this.getUtilityLists();
  }
})
</script>
