import 'bootstrap-vue/dist/bootstrap-vue.css'
// import Vue from 'vue'
import { createApp } from 'vue'
import App from './App.vue'

// import Vue from 'vue'

// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// import { BootstrapVue } from 'bootstrap-vue'

// // Import Bootstrap an BootstrapVue CSS files (order is important)
// import 'bootstrap/dist/css/bootstrap.css'


// // Make BootstrapVue available throughout your project
// Vue.use(BootstrapVue)

// // Optionally install the BootstrapVue icon components plugin
// Vue.use(IconsPlugin)

// import bModal from 'bootstrap-vue/es/components/modal/modal'
// Vue.use(bModal)
var app = createApp(App);
app.mount('#app');

localStorage.setItem("isLogginSuccessfull", "false");
localStorage.setItem("isHomePageVisible", "true");
 
