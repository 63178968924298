<template>
    <div class="container">

<!-- Button trigger modal -->
<button type="button" class="btn btn-primary collapse" id="scheduleDetailModalButton" data-bs-toggle="modal" data-bs-target="#scheduleDetailModal">
<!-- <button type="button" class="btn btn-primary" @click="ShowSiteDetail(133333)"> -->
  Launch demo modal
</button>

<!-- Button trigger modal -->
<button type="button" class="btn btn-primary collapse" id="jobUpdateModalButton" data-bs-toggle="modal" data-bs-target="#jobUpdateModal">
<!-- <button type="button" class="btn btn-primary" @click="ShowSiteDetail(133333)"> -->
  Launch demo modal
</button>

<!-- Button trigger modal -->
<button type="button" class="btn btn-primary collapse" id="jobCallsViewButton" data-bs-toggle="modal" data-bs-target="#jobCallsModal">
<!-- <button type="button" class="btn btn-primary" @click="ShowSiteDetail(133333)"> -->
  Launch demo modal
</button>


<div class="justify-content-center">


<!-- <input type="date" :value="startDate && new Date(startDate.getTime()-(startDate.getTimezoneOffset()*60*1000)).toISOString().split('T')[0]"
                   @input="startDate = $event.target.valueAsDate" placeholder="Start Date">
 -->

<!-- 

<input type="date" :value="endDate && new Date(endDate.getTime()-(endDate.getTimezoneOffset()*60*1000)).toISOString().split('T')[0]"
                   @input="endDate = $event.target.valueAsDate" placeholder="Start Date"> -->


<input type="date" :value="dateToYYYYMMDD(startDate)" class="datepicker " data-date-format="dd/mm/yyyy"
                   @input="startDate = $event.target.valueAsDate" placeholder="Start Date">
              
<input type="date" :value="dateToYYYYMMDD(endDate)" 
                   @input="endDate = $event.target.valueAsDate" placeholder="End Date">
                
                   
<!-- <inpput class="form-select col-2"  placeholder="Select Client"></inpput> -->
<input class="col-2" list="companiesList" v-model="selectedCompanyId" placeholder="Clients"> 
<datalist id="companiesList">
  <option v-for="company in this.companies" v-bind:key="company.companyId" >
    {{company.companyId + "-" +company.companyName}}
  </option>
</datalist>

<button type="button" class="btn btn-primary btn-sm m-1"  @click="getScheduleForToday(0)">Search</button>
<button type="button" class="btn btn-primary btn-sm m-1" @click="OnAddJobCliked" id="jobAddModalButton" data-bs-toggle="modal" data-bs-target="#jobUpdateModal">
  Add New Job
</button>

</div>
 
<!-- Modal -->
<div class="modal" id="scheduleDetailModal">
<!-- <div class="modal" v-if="isModalShown"> -->
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title fw-bold" id="scheduleDetailModalLabel">{{modalContent.entity}} Detail for Duty with SNo. = {{duty.id}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body text-left">

        <div class="text-warning"><span class="text-primary">ID : </span> {{modalContent.id}}</div>
        <div v-show="modalContent.entity != 'duty'">
            <div class="text-warning"><span class="text-primary">NAME : </span> {{modalContent.name}}</div>
            <div v-show="modalContent.entity != 'site'" class="text-warning"><span class="text-primary">CITY : </span> {{modalContent.city}}</div>
            <div v-show="modalContent.entity != 'site'" class="text-warning"><span class="text-primary">STREET ADDRESS : </span> {{modalContent.streetAddress}}</div>
            <div v-show="modalContent.entity != 'site'" class="text-warning"><span class="text-primary">POST CODE : </span> {{modalContent.postCode}}</div>
        </div>
         <div v-show="modalContent.entity == 'duty'">
            <!-- <div class="text-warning"><span class="text-primary">Job ID : </span> {{modalContent.SecurityOfficerInfo}}</div> -->
            <div class="text-warning"><span class="text-primary">OFFICER NAME : </span> {{modalContent.SecurityOfficerInfo}}</div>
            <div class="text-warning"><span class="text-primary">SITE NAME : </span> {{modalContent.SiteInfo}}</div>
            <div class="text-warning"><span class="text-primary">DATE : </span> {{modalContent.Date}}</div>
            <div class="text-warning"><span class="text-primary">Starts At : </span> {{modalContent.StartTime}}</div>
            <div class="text-warning"><span class="text-primary">Ends At : </span> {{modalContent.EndTime}}</div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
      </div>
    </div>
  </div>
</div>
        
<div>
  <span v-show="!isAuthorized" class="text-danger h2"> Please make sure you are logged in.</span>
</div>        
<div class="row m-2">
        
        <table class="table text-left table-hover" v-show="isAuthorized" >
            <thead>
                <tr>
                    <!-- <th>SN</th> -->
                    <th>Site</th>
                    <th>Officer</th>
                    <th>Date</th>
                    <!-- <th>Start</th>
                    <th>End</th> -->
                    <th>Client</th>
                    <th>Edit</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(duty) in schedule" v-bind:key="duty" :class="setStatusColor(duty.scheduleViewModel.statusId)">
                    <!-- <td>{{index+1}}</td> -->
                    <!-- <td>{{duty.scheduleViewModel.jobId}}</td> -->
                    <td @click="ShowSiteDetail(duty, 'site')">{{duty.scheduleViewModel.siteInfo}}</td>
                    <td  @click="ShowSiteDetail(duty, 'officer')">{{duty.scheduleViewModel.securityOfficerInfo}}</td>
                    <!-- <td>{{duty.officer}}</td> -->
                    <td @click="ShowSiteDetail(duty, 'duty')" class="col-4">
                        {{getJobTimings(duty)}}
                    </td>

                    <!-- <td @click="ShowSiteDetail(duty, 'duty')">{{duty.scheduleViewModel.jobDate.substring(0, duty.scheduleViewModel.jobDate.indexOf("T")) }}</td>
                    <td @click="ShowSiteDetail(duty, 'duty')">{{duty.scheduleViewModel.startTime.value.hours + ":" + duty.scheduleViewModel.startTime.value.minutes}}</td>
                    <td @click="ShowSiteDetail(duty, 'duty')">{{duty.scheduleViewModel.endTime.value.hours + ":" + duty.scheduleViewModel.endTime.value.minutes}}</td> -->
                    <td @click="ShowSiteDetail(duty, 'duty')">{{duty.scheduleViewModel.clientInfo}}</td>                
                    <td @click="toggleJobDetailModal(duty.scheduleViewModel.jobId)" class="col-1">Edit</td>                
                    <td @click="ShowJobCallsModal(duty.scheduleViewModel.jobId)" class="col-1">Status</td>                
                </tr>
            </tbody>
        </table>
</div>
        
<div class="container mt-2">
   <button class="Btn btn-primary btn-sm m-1" v-show="isRangeLess_Than_TotalResultPages"  @click="getScheduleForToday(startPageNumber - LabelsPerPage )"> &lt;&lt;</button>
   <span v-if="endPageNumber > 1">
      <span v-for="pageNo in (endPageNumber)" v-bind:key="pageNo">
        <button class="btn btn-success btn-sm m-1" type="button" v-if="isShownPageButton(pageNo)" @click="getScheduleForToday(pageNo -1 )">{{ pageNo }}</button>
      </span>
      <button type="button" class="Btn btn-primary btn-sm m-1" v-show="isRangeGreater_Than_LabelsPerPage" @click="getScheduleForToday(endPageNumber)"> >> </button>
  </span>
</div>

<div class="modal" tabindex="-1" role="dialog" id="jobCallsModal">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <span  class="modal-title text-danger h2"  v-if="!isNewJobToAdd"> JobCalls for job Id : {{job.JobId}}</span>
        <span  class="modal-title text-danger h2" v-if="isNewJobToAdd"> Enter New Job Details</span>
         <!-- <button type="button"  v-if="!isNewJobToAdd" class="Btn btn-primary btn-sm m-1" @click="deleteJob(this.job.JobId)"> Delete Job </button> -->
      </div>

      <div class="modal-body">
        <div class="row  text-primary" >
          <div class="col-12">
          <table class="table text-left table-hover">
            <thead>
              <tr>
                <th>SNo</th>
                <!-- <th>Id</th> -->
                <th>Time</th>
                <!-- <th>Location</th> -->
                <th>Address</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(jobcall,index) in JobCalls" v-bind:key="jobcall.id">
                <!-- <td>ALLAH IS ONE</td> -->
                <td>{{index + 1}} </td>
                <!-- <td><span> {{jobcall.id}} </span></td> -->
                <td>{{jobcall.callTime}} </td>
                <!-- <td>{{jobcall.location}}</td> -->
                <td>{{jobcall.address}}</td>
              </tr>
              </tbody>
            </table>


          </div>
        </div>
      </div>
      <div class="modal-footer">
         <button type="button" class="Btn btn-primary btn-sm m-1" @click="ShowJobCallsModal(-1)"> Hide Modal </button>         
      </div>
    </div>
  </div>
</div>

<div class="modal" tabindex="-1" role="dialog" id="jobUpdateModal">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <span  class="modal-title text-danger h2"  v-if="!isNewJobToAdd"> Detail for job Id : {{job.JobId}}</span>
        <span  class="modal-title text-danger h2" v-if="isNewJobToAdd"> Enter New Job Details</span>
         <button type="button"  v-if="!isNewJobToAdd" class="Btn btn-primary btn-sm m-1" @click="deleteJob(this.job.JobId)"> Delete Job </button>

      </div>

      <div class="modal-body">
                <!-- <div class="row"> -->
                  <div class="row  text-primary" >
                    <div class="col-2 pt-3">
                      <span> Offier</span>
                    </div>
                    <div class="col-8">
                      <input class="h5 col-12" :value="selectedSecurityOfficerInfo_ForInputBox"  list="officersList" @change="OnOfficerChange($event)" placeholder="Select New Officer...">
                      <datalist id="officersList">
                        <option v-for="officer in officers" v-bind:key="officer.securityOfficerId">
                        {{officer.securityOfficerId + "-" + officer.name + "," + officer.postCode + "," + officer.cityName}}
                        </option>
                      </datalist>
                    </div>
                  </div>
                <!-- </div> -->

                <!-- <div class="row border"> -->
                  <div class="row  text-primary" >
                    <div class="col-2 pt-3">
                        <span> Site</span>
                    </div>
                    <div class="col-8">
                      <input class="col-12 h5"  :value="selectedSiteInfo_ForInputBox" list="sitesList" @change="onSiteChange($event)" placeholder="Select New Site...">
                      <datalist id="sitesList">
                          <option v-for="site in sites" v-bind:key="site.siteId">{{site.siteId + "-"  + site.siteName + ","+ site.cityName + ","+ site.postCode}}</option>
                      </datalist>
                    </div>
                  </div>
                <!-- </div> -->


                <!-- <div class="row border" > -->
                  <div class="row text-primary" >
                    <div class="col-2 pt-3">
                        <span> Client</span>
                    </div>
                    <div class="col-8">
                      <input class="col-12 h5" :value="selectedSiteClientsInfo_ForInputBox" @change="OnClientChange($event)" list="clientListOptions" id="exampleDataList" placeholder="Selecte New Client...">
                      <datalist id="clientListOptions">
                        <option v-for="company in selectedSiteClients" v-bind:key="company.companyId" :selected="company.companyId == selectedJobClientId">
                                {{company.companyId + "-" +company.companyName}}
                        </option>
                      </datalist>
                    </div>
                  </div>
                <!-- </div> -->

                <!-- <div class="row border"> -->
                  <div class="row text-primary"  >
                    <div class="col-2 pt-3">
                      <span> Status</span>
                    </div>
                    <div class="col-8">
                      <input class="col-12 h5" :value="selectedJobStatusInfo_ForInputBox" @change="OnStatusChange($event)" list="statusesListOptions" id="exampleDataList2" placeholder="Selecte New Status...">
                      <datalist id="statusesListOptions">
                        <option v-for="status in jobStatuses" v-bind:key="status.statusId" >
                                {{status.statusId + "-" +status.name}}
                        </option>
                      </datalist>
                    </div>
                  </div>
                <!-- </div> -->

                <div class="row" >
                  <div class="col-3">
                      <span > JobDate </span>
                  </div>
                  <div class="col-6">
                    <input type = "date" v-model="selectedJobDate" class = "col-12 datepicker">  
                  </div>
                </div>

                <div class="row">
                  <div class="col-3">
                      <span class="col-3">StartTime</span>
                  </div>
                  <div class="col-6">
                    <input type = "text" class="col-12" v-model="selectedJobStartTime" >  
                  </div>
                </div>

                <div class="row">
                  <div class="col-3">
                      <span class="col-2">EndTime</span>
                  </div>
                  <div class="col-6">
                    <input type = "text" class="col-12" v-model="selectedJobEndTime" >  
                  </div>
                </div>

                <div class="row">
                  <div class="col-3">
                      <span >Payrate</span>
                  </div>
                  <div class="col-6">
                    <input type = "text" class="col-12" v-model="selectedJobPayRate" >  
                  </div>
                </div>

                <div class="row">
                  <div class="col-3">
                      <span >Adjustment</span>
                  </div>
                  <div class="col-6">
                    <input type = "text" class="col-12" v-model="selectedJobAdjustment" >  
                  </div>
                </div>


                <div class="row">
                  <div class="col-3">
                      <span >T. Exp.</span>
                  </div>
                  <div class="col-6">
                    <input type = "text" class="col-12" v-model="selectedJobTravelExpense" >  
                  </div>
                </div>

                <div class="row">
                  <div class="col-3">
                    <span >B. Hrs.</span>
                  </div>
                  <div class="col-6">
                    <input type = "text" class="col-12" v-model="selectedJobBonusHours" >  
                  </div>
                </div>

                <div class="row">
                  <div class="col-3">
                    <span >Remarks</span>
                  </div>
                  <div class="col-6">
                    <input class="text col-12" v-model="selectedJobRemarks" >
                  </div>
                </div>

                
              <div>
            </div>
      </div>

      <div class="modal-footer">
         <button type="button" class="Btn btn-primary btn-sm m-1" @click="toggleJobDetailModal(-1)"> Hide Modal </button>
         <button type="button"  v-if="!isNewJobToAdd" class="Btn btn-primary btn-sm m-1" @click="UpdateJob()"> Update Job </button>
         
         <button type="button"  v-if="isNewJobToAdd" class="Btn btn-primary btn-sm m-1" @click="UpdateJob()"> Add Job </button>


      </div>
    </div>
  </div>
</div>

</div>
</template>

<script language="ts">

import Site from "../Sites/Site"
import Job from "../Sites/Job"
// import JobStatuses from "../Companies/JobStatuses"
//import { numberLiteralTypeAnnotation } from "@babel/types";

//import { Job } from "../Sites/job";
//import { Site } from "../Sites/Site";
//import { ISite } from "../Sites/ISite";
export default ({

   name:'Schedule',
   props:{
    smsapi_endpoint_base_url:String,
   },
   data(){
       return{
           schedule:this.getScheduleForToday(),
           isModalShown:Boolean,
           modalContent:{},
           duty:{},
           balanceAdjustmentValue:40,
            searchString:'',
            //startDate:Date,
            currentPageNo:0,
            startPageNumber : 1,
            endPageNumber:0,
            LabelsPerPage : 10,
            totalResultPages:0,
            totalRecordCount:0,
            recordsPerPage:10,
            isRangeGreater_Than_LabelsPerPage:false,
            isRangeLess_Than_TotalResultPages:false,
            message: 'Hello Vue.js!',
            startDate: new Date(),// Date('2011-04-15T00:01:01Z'),
            endDate: new Date(),//('2011-04-17T00:01:01Z'),
            isAuthorized:false,
            isVisible:false,
            job:Job,
            site:Site,
            officers:[],
            companies:[],
            selectedOfficerId:0,
            selectedSiteId:0,
            selectedSiteInfo:"",
            selectedJobDate: this.dateToYYYYMMDD(new Date()),// "2022-02-02",
            //myJobDate: "02-02-2022"//.reverse()
            selectedJobStartTime:"00:00",
            selectedJobEndTime:"00:00",
            selectedJobRemarks:"",
            selectedJobAdjustment:1,
            selectedJobTravelExpense:10,
            selectedJobClientId:2,
            selectedJobClientInfo:2,
            selectedJobBonusHours:2,
            selectedSecurityOfficerInfo:"",
            selectedSecurityOfficerInfo_ForInputBox:"",
            selectedSiteInfo_ForInputBox:"",
            selectedSiteClientsInfo_ForInputBox:"",
            selectedJobStatusInfo_ForInputBox:"",
            jobStatuses:[],
            selectedJobStatusId:"",
            selectedJobStatusInfo:"",
            selectedJobPayRate:0,
            selectedSiteClients:[],
            isNewJobToAdd:false,
            selectedCompanyId:"",
            JobCalls:[],
            // jobStatuses:JobStatuses=
       }
   },
   computed:{
    getIsModalShownValue(){
           return this.isModalShown;
       },
       GetSelectedOfficerInfo(selectedOfficerId, selectedSecurityOfficerInfo){
      return selectedOfficerId + "-"+ selectedSecurityOfficerInfo;
    },
   },
   methods:{
    setStatusColor(statusId){
      var classObject = {};
      switch (statusId) {
         case '2':
         case 2:
            classObject['bg-success'] = true;
            classObject['text-white'] = true;
            break;
         case '4':
         case 4:
            classObject['bg-warning'] = true;
            classObject['text-dark'] = true;
            break;
         case '3':
         case 3:
         case '8':
         case 8:
            classObject['bg-danger'] = true;
            classObject['text-white'] = true;
            break;
         default:
            classObject['bg-white'] = true;
            classObject['text-dark'] = true;
            break;
      }
      return classObject;
    },
   OnAddJobCliked(){
     //console.log("job.JobId",this.job.JobId);
         this.isNewJobToAdd = true;

     this.job.JobId=0;
    //  this.selectedOfficerId=0;
    // this.selectedSiteId=0;
    //this.selectedSiteInfo="";
    this.selectedJobDate= this.dateToYYYYMMDD(new Date()),// "2022-02-02",
    this.selectedJobStartTime = "00:00";
    this.selectedJobEndTime = "00:00";
    this.selectedJobRemarks = "";
    this.selectedJobAdjustment=1;
    this.selectedJobTravelExpense=10;
    // this.selectedJobClientId=2;
    // this.selectedJobClientInfo=2;
    this.selectedJobBonusHours=2;
    // this.selectedSecurityOfficerInfo="";
    // this.selectedJobStatusId="";
    this.selectedJobStatusInfo="";
    this.selectedJobPayRate=0;
    //console.log("job.JobId",this.job.JobId);
    this.selectedSecurityOfficerInfo_ForInputBox="";
    this.selectedSiteInfo_ForInputBox="";
    this.selectedSiteClientsInfo_ForInputBox="";
    this.selectedJobStatusInfo_ForInputBox="";

    this.selectedSecurityOfficerInfo="";
    this.selectedSiteInfo="";
    this.selectedSiteClientsInfo="";
    this.selectedJobStatusInfo="";

          this.getOfficersList();
    this.getSitesList();
    this.getCompaniesList();
    this.getStatusesList();
   },
    getTime4Digits(time){
        let timehours = time.value.hours;

        let timeMins = time.value.minutes;

        let time4Digits = timehours;

        if(timehours >= 0 && timehours < 10)
          time4Digits = "0" + timehours;
        

        if(timeMins >= 0 && timeMins < 10)
        {
          time4Digits += ":0" + timeMins;
        }
        else
          time4Digits += ":" + timeMins;

          return time4Digits;
    },
    getJobTimings(duty){
        let dutyDate = duty.scheduleViewModel.jobDate
                        .substring(0, 10);
                        //.substring(0, duty.scheduleViewModel.jobDate.indexOf("T"));
       
        /*old code but commented now*/
        // let dutyStartTime = this.getTime4Digits(duty.scheduleViewModel.startTime);
        // let dutyEndTime = this.getTime4Digits(duty.scheduleViewModel.endTime);
        // return dutyDate + "("+ dutyStartTime + "-" + dutyEndTime + ")";
        
        /*new code which did not work on server so commented now*/
        // try
        // {
        //   let dutyDateTime = new Date(dutyDate);
        //   dutyDateTime.setHours(duty.scheduleViewModel.startTime.value.hours);
        //   dutyDateTime.setMinutes(duty.scheduleViewModel.startTime.value.minutes);
        //   duty.scheduleViewModel.jobDate += duty.scheduleViewModel.startTime.value.hours;
        //   duty.scheduleViewModel.jobDate += duty.scheduleViewModel.startTime.value.minutes;
        //   let dutyTimings = dutyDateTime.toLocaleString("en-GB").substring(0,dutyDateTime.toLocaleString("en-GB").length-3)  ;
        //   dutyTimings += "-"+ this.getTime4Digits(duty.scheduleViewModel.endTime);
        //   return dutyTimings;
        // }
        // catch(error)
        // {
        //   console.log("error occurred : " , error)
        // }

        
        /*this is the final code which worked both locally and on server as well*/
        let dutyDateTime = new Date(dutyDate);
        let dutyTimings = dutyDateTime.toLocaleString("en-GB").substring(0,dutyDateTime.toLocaleString("en-GB").length-10)  ;
        let dutyStartTime = this.getTime4Digits(duty.scheduleViewModel.startTime);
        let dutyEndTime = this.getTime4Digits(duty.scheduleViewModel.endTime);
        return dutyTimings + " ("+ dutyStartTime + "-" + dutyEndTime + ")";
    },
    OnStatusChange(e){
      //console.log("job status ", e.target.value);
      let status = e.target.value;
      this.selectedJobStatusId = status.substring(0, status.indexOf("-"));
      this.selectedJobStatusInfo_ForInputBox = status;
      //console.log("selectedJobStatusId ", this.selectedJobStatusId);
    },
    onSiteChange(e) {
        //console.log("Site : ",e.target.value);
        let site = e.target.value;
        let endIndex = site.indexOf("-")
        this.selectedSiteId = site.substring(0,endIndex);
        this.selectedSiteInfo_ForInputBox = site;
        //console.log("selectedSiteId", this.selectedSiteId);
        this.getSiteClients(this.selectedSiteId);
    },
    OnClientChange(e){
        //"Client : ",e.target.value);
        let site = e.target.value;
        let endIndex = site.indexOf("-")
        this.selectedJobClientId = site.substring(0,endIndex);
        this.selectedSiteClientsInfo_ForInputBox = site;
        //console.log("selectedJobClientId", this.selectedJobClientId);
    },
    OnOfficerChange(e){
        // const selectedIndex = e.target.options.selectedIndex;
        // console.log("key value : ", e.target.options[selectedIndex]);

        //console.log("Officer : ",e.target.value);
        let officer = e.target.value;
        let endIndex = officer.indexOf("-");
        this.selectedOfficerId = officer.substring(0, endIndex);
        this.selectedSecurityOfficerInfo_ForInputBox = e.target.value;

        // this.selectedJobPayRate = 1.22;

        var requestUrl = this.smsapi_endpoint_base_url + "securityofficers/SecurityOfficerDetail"
        var requestOptions = {
          method:"POST",
          headers:
          {
            'Authorization':`Bearer ${localStorage.token}`,
            "Content-Type":"application/json"
          },
          body:JSON.stringify({
            'Id':parseInt(this.selectedOfficerId)
            //'Id':1044
          })
        }

        fetch(requestUrl, requestOptions)
        .then(response =>  response.json())
          .then(result =>{
            this.selectedJobPayRate = result.payRate
        });

    },
    getSiteClients(selectedSiteId){
        let siteClientsRequestUrl = this.smsapi_endpoint_base_url + "Companies/SiteClients";
        let siteClientsRequestOptions={
          method:"POST",
          headers:{
            'Authorization':`Bearer ${localStorage.token}`,
            "Content-Type":"application/json",
          },
          body:JSON.stringify( 
            {
              //Id: parseInt( this.selectedSiteId)
              Id: parseInt(selectedSiteId)
          })
        }

        fetch(siteClientsRequestUrl, siteClientsRequestOptions)
        .then(response => response.json())
        .then(result=>{
              //console.log("result = ", result);
              this.selectedSiteClients=result;
              //console.log("this.selectedSiteClients = ", this.selectedSiteClients)
          })
    },
    deleteJob(jobId){
      //console.log("jobId", jobId);

          let updateJobRequestUrl = this.smsapi_endpoint_base_url + "schedule/DeleteJob";
          //console.log("updateJobRequestUrl : ", updateJobRequestUrl);
          let udpateJobRequestOptions = {
            method:"POST",
            headers:{
              'Authorization': `Bearer ${localStorage.token}`,
              'Content-Type':'application/json'
            },
            body:JSON.stringify( {Id:jobId})
          };

          fetch(updateJobRequestUrl, udpateJobRequestOptions)
          .then(response =>  response.json())
          .then(result =>{
            result = null;//dummy statement should be removed
            console.log("result : ", result);
            document.getElementById("jobUpdateModal").click();
            this.getScheduleForToday(0);
          });
    },
    UpdateJob(){

        //console.log("selectedOfficerId in UpdateJob method ", this.selectedOfficerId);

        let jobDetailToUpdate = {
              'jobId': this.job.JobId,// 29765,
              'siteId': parseInt(this.selectedSiteId),// 2940,
              'jobDate': this.selectedJobDate,//"2022-08-03T11:00:00",
              'securityOfficerId': parseInt(this.selectedOfficerId),// 1470,
              'operator': parseInt(localStorage.getItem('userId')),//4
              'bonusHours': parseInt(this.selectedJobBonusHours),// 3.5,
              'travelExpense': parseInt(this.selectedJobTravelExpense),// 4.5,
              'Remarks': this.selectedJobRemarks,// "test update",
              'Adjustment': parseFloat(this.selectedJobAdjustment),// 2.5,
              'statusId': parseInt(this.selectedJobStatusId),// 1,
              'clientId': parseInt(this.selectedJobClientId),// 24,
              'OperatorId':parseInt(localStorage.getItem('userId')),//4,
              'PayRate':parseFloat( this.selectedJobPayRate),// 7.5,
              'Rate':parseFloat(this.Rate),// 14.5,
              'startTime': this.selectedJobStartTime,//'6:00:00',//
              'endTime':this.selectedJobEndTime,//'12:00:00', //
             
          };

    console.log("jobDetailToUpdate : ", jobDetailToUpdate);
    if(this.job.JobId == 0)//add new job and addjob endpoint
    {
          //console.log("updateJobRequestUrl : ", updateJobRequestUrl);
          //console.log("jobDetailToAdd.... : ", jobDetailToUpdate);
          
          let updateJobRequestUrl = this.smsapi_endpoint_base_url + "schedule/AddJob";
          let udpateJobRequestOptions = {
            method:"POST",
            headers:{
              'Authorization': `Bearer ${localStorage.token}`,
              'Content-Type':'application/json'
            },
            body:JSON.stringify( jobDetailToUpdate)
          };


          fetch(updateJobRequestUrl, udpateJobRequestOptions)
          .then(response =>  response.json())
          .then(result =>{
            result = null;//dummy statement should be removed
            console.log("result : ", result);
            document.getElementById("jobUpdateModal").click();
            this.getScheduleForToday(0);
          });
    }
    else//if jobId is greater than zero then it is an updateJon case and hence call UpdateJob endpoint
    {
          let updateJobRequestUrl = this.smsapi_endpoint_base_url + "schedule/UpdateJob";
          //console.log("updateJobRequestUrl : ", updateJobRequestUrl);
          let udpateJobRequestOptions = {
            method:"POST",
            headers:{
              'Authorization': `Bearer ${localStorage.token}`,
              'Content-Type':'application/json'
            },
            body:JSON.stringify( jobDetailToUpdate)
          };

          fetch(updateJobRequestUrl, udpateJobRequestOptions)
          .then(response =>  response.json())
          .then(result =>{
            result = null;//dummy statement should be removed
            console.log("result : ", result);
            document.getElementById("jobUpdateModal").click();
            this.getScheduleForToday(0);
          });
    }
    //console.log("selectedJobDate", this.selectedJobDate);           
    },

    getOfficersList(){
      let officersRequestUrl =  this.smsapi_endpoint_base_url + "securityofficers/securityofficers";
              let officersRequestOptions = {
                method:'POST',
                headers:{
                  'Authorization': `Bearer ${localStorage.token}`,
                  'Content-Type':'application/json'
                },
                body:JSON.stringify({
                  'PageNumber': 0,
                  'RecordsPerPage':1000,
                  'SearchString': ''
                })
              };

              fetch(officersRequestUrl, officersRequestOptions)
              .then(response => {
                  var result = response.json();
                  if(response.status == false)
                  {
                    //console.log("result false",  result);
                  }
                  else
                  {
                    //console.log("result true",  result);
                    return result;
                  }
              })
              .then(res=>{
                //console.log("res", res);
                this.officers=res.officersListPage;

              });
    },
    getSitesList(){
              let sitesRequestUrl = this.smsapi_endpoint_base_url + "Sites/SitesPageWise";
              let sitesRequestOptions = {
                method:"POST",
                headers:{
                  "Authorization":"Bearer " + localStorage.token,
                  "Content-Type" : "application/json"
                },
                body:JSON.stringify({
                  'PageNumber': 0,
                  'RecordsPerPage':10000,
                  'SearchString': ''
                })
              };
              
              fetch(sitesRequestUrl, sitesRequestOptions)
              .then(response =>{
                var result = response.json();
                if(response.status == false)
                  {
                    //console.log("result false",  result);
                  }
                  else
                  {
                    //console.log("result true",  result);
                    return result;
                  }
              })
              .then(res=>{
                //console.log("res", res);
                //console.log("ressitesListPage",  res.sitesListPage);
                this.sites = res.sitesListPage;
              });
              
    },
    getCompaniesList(){
          let clientsRequestUrl = this.smsapi_endpoint_base_url + "Companies/Companies";
          let clientsRequestOptions = {
            method:"GET",
            headers:{
              'Authorization': `Bearer ${localStorage.token}`,
                "Content-Type":"application/json",
            }
            // body:JSON.stringify({})
          };

          fetch(clientsRequestUrl, clientsRequestOptions)
          .then( response =>response.json())
          .then(reslt =>{
            //console.log("reslt....2 : ", reslt);
            this.companies = reslt;
          })
          .then( r => 
            this.companies = r
          );
    },
    getStatusesList(){
      let jobStatusesRequestUrl = this.smsapi_endpoint_base_url + "JobStatus/JobStatusesObject";
            let jobStatusesRequestOptions = {
              method:"GET",
              headers:{
                'Authorization':`Bearer ${localStorage.token}`,
                'Content-Type':'application/json'
              },
              //body:JSON.stringify({}),
            }

            fetch(jobStatusesRequestUrl, jobStatusesRequestOptions)
            .then(response =>{
              var res =  response.json();
              if(res.status == false)
              {
                //console.log("result false",  result);
              }
              else
              {
                //console.log("result true",  result);
                return res;
              }

              // console.log("res ", res);
              // console.log("response ", response);
              // return res;
            })
              .then(result =>{
              //console.log("result", result);
              //console.log("result.statuses", result.statuses);
              this.jobStatuses = result.statuses;
              //console.log("this.jobStatuses", this.jobStatuses);

              result.statuses.forEach(s=> {
                //console.log("status : " , s);
                  if(s.statusId == this.selectedJobStatusId)
                  this.selectedJobStatusInfo = s.name;
              });


              var statuses = result.statuses.find(j=>j.statusId == 1)//this.selectedJobStatusId);
              //var statuses = this.jobStatuses.find(j=>j.statusId >= 2)//this.selectedJobStatusId);

              if(statuses.length > 0)
              {
                //console.log("status : ", statuses[0])
              }
            })
            .then(r =>{
                r = null;
                console.log("r : ", r)
            });
    },
    
    ShowJobCallsModal(selectedJobId){
      //console.log("selectedJobId : ", selectedJobId);
      this.JobCalls = this.JobCalls.slice(0,0);
      if(selectedJobId >= 0)
      {
        let requestUrl = this.smsapi_endpoint_base_url + "JobCalls/CallsForJob";
        let requestOptions = {
          headers:{
          'Authorization': `Bearer ${localStorage.token}`,
          "Content-Type":"application/json",
          },
          method : "POST",
          body:JSON.stringify({
            Id : selectedJobId//30695
          })
        }

        fetch(requestUrl, requestOptions)
             .then(response => response.json())
             .then(result => {
              //console.log("result = ", result);
              //console.log("result.jobCalls = ", result.jobCalls);

              result.jobCalls.forEach(jc=>{
                let jobcall = {
                    id:jc.id,
                    JobId : jc.jobId,
                    callTime  :  jc.callTime,
                    location :  jc.location,
                    address  :  jc.remarks,
                    callTypeId  :  jc.callTypeId,
                  }
                  //console.log("jobcall = ", jobcall);
                  this.JobCalls.push(jobcall)

              });

              //console.log("this.JobCalls = ", this.JobCalls);

              
                

          });
        document.getElementById("jobCallsViewButton").click();
      }
      else if(selectedJobId ==-1)
      {
        document.getElementById("jobCallsViewButton").click();
      }
    },
    toggleJobDetailModal(selectedJobId){
      //console.log("selectedJobId : ", selectedJobId);
      if(selectedJobId == 0)
        this.isNewJobToAdd=true;
      else if(selectedJobId == -1)
      {
           this.isNewJobToAdd=false;
           document.getElementById("jobUpdateModalButton").click();
      }
      else
        this.isNewJobToAdd=false;


              this.getOfficersList();
              this.getSitesList();
              this.getCompaniesList();
              this.getStatusesList();

             let requestUrl = this.smsapi_endpoint_base_url + "schedule/JobDetail";
             let requestOptions = {
               headers:{
                'Authorization': `Bearer ${localStorage.token}`,
                "Content-Type":"application/json",
               },
                method : "POST",
                body:JSON.stringify({
                  Id : selectedJobId//30695
                })
             }
             fetch(requestUrl, requestOptions)
             .then(response => response.json())
             .then(result => {
              //console.log("result = ", result);
              this.job = {
                JobId : result.jobId,
                securityOfficerId  :  result.securityOfficerId,
                securityOfficerInfo :  result.securityOfficerInfo,
                siteId  :  result.siteId,
                SiteInfo  :  result.siteInfo,
                jobDate  :  result.jobDate.toLocaleString().substring(0,10).split("-").reverse().join("-"),
                startTime  :  result.startTime.value.hours +":" + result.startTime.value.minutes,
                endTime  :  result.endTime.value.hours +":"+ result.endTime.value.minutes,
            }


         
          var date = result.jobDate.substring(0,result.jobDate.indexOf('T'));
          //console.log("date : " , new Date(date));
          this.selectedJobDate = this.dateToYYYYMMDD(new Date(date)),

          

          //this.selectedJobStartTime = result.startTime.value.hours +":" + result.startTime.value.minutes,
          this.selectedJobStartTime = this.getTime4Digits(result.startTime),
          //this.selectedJobEndTime = result.endTime.value.hours +":"+ result.endTime.value.minutes,
          this.selectedJobEndTime = this.getTime4Digits(result.endTime),

          this.selectedJobRemarks =result.remarks,
          this.selectedJobAdjustment = result.adjustment,
          this.selectedJobTravelExpense = result.travelExpense,
          //this.selectedJobClientId = result.clientId,
          this.selectedJobClientId = result.clientId,//undefined,
          this.selectedJobClientInfo = result.clientInfo,
          this.selectedSiteClientsInfo_ForInputBox = result.clientInfo,
          this.selectedSecurityOfficerInfo = result.securityOfficerInfo,
          this.selectedSecurityOfficerInfo_ForInputBox = result.securityOfficerInfo,
          // console.log("result....3 : ", result);
          // console.log("result.clientId....4 : ", result.clientId);
          // console.log("result.clientId....4 : ", result.clientId);

          this.selectedJobBonusHours = result.bonusHours,

          this.selectedOfficerId = this.job.securityOfficerId;
          //this.selectedOfficerId = undefined;
          this.selectedSiteId = this.job.siteId;
          //this.selectedSiteId = undefined;
          this.selectedSiteInfo = result.siteInfo;
          this.selectedSiteInfo_ForInputBox = result.siteInfo;
          this.getSiteClients(this.selectedSiteId);

            
            this.selectedJobStatusId = result.statusId;
            this.selectedJobPayRate = result.payRate;//11;//
            this.selectedJobStatusInfo_ForInputBox = result.statusInfo;


          //this.isVisible=!this.isVisible;
           document.getElementById("jobUpdateModalButton").click();
      });
    },
    addADayToMyDate() {
      // use the if because myDate can be null
      if (this.myDate) {
        // notice we don't just call .setDate(...) in myDate, instead
        // we create a new Date object and set it to this.myDate, so vue can detect it changed
        // this is not a caveat of this specific solution, but of any binding of dates
        this.myDate = new Date(this.myDate.setDate(this.myDate.getDate() + 1));
      }
    },
    dateToYYYYMMDD(d) {
      // alternative implementations in https://stackoverflow.com/q/23593052/1850609
      return d && new Date(d.getTime()-(d.getTimezoneOffset()*60*1000)).toISOString().split('T')[0]
    },
       getScheduleForToday(pageNo){
          //  var StartDate = new Date(2021, 10, 2);
          //  var EndDate = new Date(2021, 11, 26);
          this.currentPageNo = pageNo
           var StartDate = this.startDate;//new Date();

          //console.log(StartDate); // May 01 2000    
          // console.log("hskjfhskjfhk"); // May 01 2000    
 
           var EndDate = this.endDate//new Date();
          //var EndDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);

           //console.log(EndDate); // May 01 2000

           let companyId = 0; 
           if(this.selectedCompanyId != undefined)
             companyId = this.selectedCompanyId.substring(0, this.selectedCompanyId.indexOf("-"));
            
           var options = {
              method:'POST',
              headers:{
                'Authorization': `Bearer ${localStorage.token}`,
                'Content-Type':'application/json'
              },
              body:JSON.stringify({
                'StartDate':StartDate,
                'EndDate':EndDate,
                'PageNumber': this.currentPageNo,
                'RecordsPerPage':this.recordsPerPage,
                'SearchString': this.searchString,
                'CompanyId': parseInt( companyId)
              }),
            }

            let requestUrl = this.smsapi_endpoint_base_url + "schedule/ScheduleForDateRangePageWise";

            //fetch("https://localhost:44325/schedule/ScheduleForDateRangePageWise", options)
            //fetch("http://emergingsols.somee.com/schedule/ScheduleForDateRangePageWise", options)
            fetch(requestUrl, options)
            .then(response => //response.json()
            {
              //console.log("response", response);
              var res = response.json()
              if(response.status == 401){
                this.isAuthorized = false;
                return null;
              }
              else{
                this.isAuthorized = true;
                return res;
              }
            })
            .then(result => {
              //console.log('result', result);
              if(result == null)
              {
                this.schedule=null;
              }
              //this.schedule = result
              this.schedule = result.scheduleSinglePageResult;
              this.totalRecordCount = result.totalRecordCount;
              //this.recordsPerPage = result.pb.recordsPerPage;
              this.totalResultPages = (this.totalRecordCount % this.recordsPerPage == 0) ? this.totalRecordCount / this.recordsPerPage : this.totalRecordCount / this.recordsPerPage;
              this.totalResultPages = Math.ceil(this.totalResultPages);
              //console.log('this.recordsPerPage', this.recordsPerPage)
              //console.log('this.totalResultPages', this.totalResultPages)
              this.diplayResultPageList();

               //console.log('this.schedule', this.schedule)
            }).catch(error => {
              error = null;
              console.log("error", error);
              this.schedule = null;
            })
            //console.log("this.schedule", this.schedule);

            return this.schedule;
       },
       isShownPageButton(pageNo){
      return (pageNo > this.startPageNumber) && (pageNo <= (this.startPageNumber + this.LabelsPerPage));
    },
  diplayResultPageList(){
        let sectionNo = Math.floor(this.currentPageNo/this.LabelsPerPage) ;
        this.startPageNumber = sectionNo * this.LabelsPerPage;
            this.endPageNumber = (sectionNo + 1) * this.LabelsPerPage;
            if (this.endPageNumber >= this.totalResultPages)
            {
                //we don't need a previous-result-pages-arrow
                this.isRangeGreater_Than_LabelsPerPage = false;
                this.endPageNumber = this.totalResultPages;
            }
            else
            {
                //we need a previous-result-pages-arrow
                this.isRangeGreater_Than_LabelsPerPage = true;
            }

            if(this.startPageNumber >= this.LabelsPerPage)
            {
              this.isRangeLess_Than_TotalResultPages = true;
            }
            else
            {
              this.isRangeLess_Than_TotalResultPages = false;
            }
    },
    ShowOfficerDetailPromise(securityOfficerId){
      let requestUrl = this.smsapi_endpoint_base_url + "securityOfficers/SecurityOfficerDetail";
      let requestOptions = {
          method:'POST',
          headers:{
            'Authorization': `Bearer ${localStorage.token}`,
            'Content-Type':'application/json'
          },
          body:JSON.stringify({
                //'Id':duty.scheduleViewModel.securityOfficerId
                'Id':securityOfficerId
          })
      };
      var fetchResponse = fetch(requestUrl, requestOptions)
      .then(response => response.json())
      .then(res => {
        // console.log("ShowOfficerDetailPromise method called and now we are in then with res = ", res);
        // this.modalContent = { entity:'officer', id:res.securityOfficerId, name:res.name, city:res.cityName, streetAddress:res.streetAddress, postCode:res.postCode};
        return res;
      });
      return fetchResponse;
    },
  ShowSiteDetail(duty, DetailType){
    this.duty = duty;
    if(DetailType == "site")
    {
      //this.modalContent = { entity:'site', id:duty.scheduleViewModel.siteId, name:duty.scheduleViewModel.siteInfo, streetAddress:'15 parkside road', postCode:'M14 7JG', city:'Manchester'}
      this.modalContent = { entity:'site', id:duty.scheduleViewModel.siteId, name:duty.scheduleViewModel.siteInfo}
    }
    else if(DetailType == "officer")
    {
        this.ShowOfficerDetailPromise(duty.scheduleViewModel.securityOfficerId)
        .then(res => {
              res = null;
              console.log("res after promise called: ", res);
              this.modalContent = { entity:'officer', id:res.securityOfficerId, name:res.name, city:res.cityName, streetAddress:res.streetAddress, postCode:res.postCode}
        })
        .catch(error =>{
          error=null;
          console.log("error : ", error);
        });
    }
    else if(DetailType == "duty")
    {
      let requestUrl = this.smsapi_endpoint_base_url + "schedule/JobDetail";
      let requestOptions = {
        headers:{
        'Authorization': `Bearer ${localStorage.token}`,
        "Content-Type":"application/json",
        },
        method : "POST",
        body:JSON.stringify({
          Id : duty.scheduleViewModel.jobId
        })
      }

      fetch(requestUrl, requestOptions)
      .then(response => response.json())
      .then(result => {
      this.modalContent = { 
        entity:'duty', 
        id:result.jobId, 
        JobId:result.jobId, 
        SecurityOfficerInfo:result.securityOfficerInfo, 
        SiteInfo:result.siteInfo,
        Date:result.jobDate.toLocaleString().substring(0,10).split("-").reverse().join("-"), 
        StartTime:result.startTime.value.hours +":" + result.startTime.value.minutes, 
        EndTime:result.endTime.value.hours +":"+ result.endTime.value.minutes,
      }
      });
    }
    //this.isModalShown = !this.isModalShown;
    document.getElementById("scheduleDetailModalButton").click();
  },
  getAuthorization(){
  var token = localStorage.getItem("token");
  if(token == "")
    this.isAuthorized = false;
  else
    this.isAuthorized=true;
  },

},
   mounted(){
    //  console.log("getScheduleForToday : ");
    // this.getScheduleForToday();
        this.getAuthorization();
        this.getSitesList();
        this.getCompaniesList();

        setInterval(() => {
          this.getScheduleForToday(this.currentPageNo)
         }, 60 * 1000);

      // You can clear a periodic function by uncommenting:
      //clearInterval(intervalId);
  },
  created(){
    //his.getAuthorization();
    this.getCompaniesList();

  }
})
</script>
