import {ISecurityOfficer} from "../SecurityOfficers/ISecurityOfficer";
import { IdDocument } from "./IdDocument";
class SecurityOfficer implements ISecurityOfficer
{
    SecurityOfficerId: number;
    FirstName: string;
    LastName: string;
    PostCode: string;
    CityId: number;
    County: string;
    StreetAdress: string;
    Town: string;
    Active: boolean;
    SecurityCompanyId: number;
    SiabadageNumber: string;
    MobileNumber: string;
    Ninumber: string;
    DesignationId: number;
    DeptId: number;
    PassportId: number;
    ConveyanceId: number;
    Email: string;
    SiaExpiryDate: Date;
    Phone2: string;
    NextOfKin: string;
    PayRate: number;
    PreviouslyKnownAs:string;
    DateOfBirth:Date;
    PlaceOfBirth:string;
    HouseNameNumber:string;
    IdentityDocumentId:number;
    CountryOfBirthId:number;
    NationalityId:number;
    GenderId:number;
    AddressCountryId:number;
    IdDocumentInfo: IdDocument;//= new IdDocument();

    constructor(
        SecurityOfficerId: number,
        FirstName: string,
        LastName: string,
        PostCode: string,
        CityId: number,
        County: string,
        StreetAdress: string,
        Town: string,
        Active: boolean,
        SecurityCompanyId: number,
        SiabadageNumber: string,
        MobileNumber: string,
        Ninumber: string,
        DesignationId: number,
        DeptId: number,
        PassportId: number,
        ConveyanceId: number,
        Email: string,
        SiaExpiryDate: Date,
        Phone2: string,
        NextOfKin: string,
        PayRate: number,
        PreviouslyKnownAs:string,
        DateOfBirth:Date,
        PlaceOfBirth:string,
        HouseNameNumber:string,
        IdentityDocumentId:number,
        CountryOfBirthId:number,
        NationalityId:number,
        GenderId:number,
        AddressCountryId:number,
        idDocument:IdDocument,
    
    )
    {
        this.SecurityOfficerId = SecurityOfficerId;
        this.FirstName = FirstName;
        this.LastName=LastName;
        this.PostCode=PostCode;
        this.CityId=CityId;
        this.County=County;
        this.StreetAdress=StreetAdress;
        this.Town=Town;
        this.Active=Active;
        this.SecurityCompanyId=SecurityCompanyId;
        this.SiabadageNumber=SiabadageNumber;
        this.MobileNumber=MobileNumber;
        this.Ninumber=Ninumber;
        this.DesignationId=DesignationId;
        this.DeptId=DeptId;
        this.PassportId=PassportId;
        this.ConveyanceId=ConveyanceId;
        this.Email=Email;
        this.SiaExpiryDate=SiaExpiryDate;
        this.Phone2=Phone2;
        this.NextOfKin=NextOfKin;
        this.PayRate=PayRate;
        
        this.PreviouslyKnownAs=PreviouslyKnownAs;
        this.DateOfBirth=DateOfBirth;
        this.PlaceOfBirth=PlaceOfBirth;
        this.HouseNameNumber=HouseNameNumber;
        this.IdentityDocumentId=IdentityDocumentId;
        this.CountryOfBirthId=CountryOfBirthId;
        this.NationalityId=NationalityId;
        this.GenderId=GenderId;
        this.AddressCountryId=AddressCountryId;

        this.IdDocumentInfo = new IdDocument(idDocument.id, idDocument.issueDate, idDocument.expiryDate, idDocument.number, idDocument.countryOfIssueId, idDocument.typeId);
    
    }
}
export { SecurityOfficer }
