
<template>
    <div class="container">

        <div>
            <input type="bg-light text-dark" class="m-2" v-model="searchString" id="searchString1" placeholder="Enter Search String">
            <!-- <button type="button" class="btn btn-primary btn-sm m-1"  @click="getCompaniesList()">Search</button> -->
            <button type="button" class="btn btn-primary btn-sm m-1"  @click="SearchCompany()">Search</button>
            <button type="button" class="btn btn-primary btn-sm m-1" @click="ShowAddCompanyModal()" >
            Add Company 
            </button>
        </div>

        <span v-if="isDataFetched" class="d-flex justify-content-center">
            <div class="spinner-border text-primary">
                <span class="visually-hidden"></span>
            </div> 
             Loading...           
        </span>
    
<div class="row m-2">

        <!-- <div class="text-primary col-12 h2">List of Companies and Sites</div> -->
        <table class="table text-left bg-light text-dark table-hover col-12">
            <thead>
                <tr class="h4 col-12">
                    <!-- <td>Id</td> -->
                    <td>Name</td>
                    <td>Post Code</td>
                    <td>Street Address</td>
                    <!-- <td>City</td> -->
                    <td>City</td>
                    <!-- <td>Client</td> -->
                    <!-- <td>Town</td> -->
                    <!-- <td>County</td> -->
                    <!-- <td>Active</td> -->
                    <td>Edit</td>
                </tr>
            </thead>
            <tbody>
                
                <!-- , VatNo,  TownName,   PhoneNo, CompanyNo, ConcernedPerson, 
                MobileNo, ,  CurrentInvoiceNo, SoftwareOwner, Email, ControlPhone, PayeeNumber -->

                <tr v-for="company in companies" v-bind:key="company.companyId" @click="showCompanyModal(company)" clas="col-12">
                    <!-- <td @click="ShowCompanyDetailModal(company)">{{company.companyId}}</td> -->
                    <td @click="ShowCompanyDetailModal(company)">{{company.companyName}}</td>
                    <td @click="ShowCompanyDetailModal(company)">{{company.postCode}}</td>
                    <td @click="ShowCompanyDetailModal(company)">{{company.streetAdress}}</td>
                    <!-- <td>{{company.cityId }}</td> -->
                    <td @click="ShowCompanyDetailModal(company)">{{company.cityName }}</td>
                    <!-- <td>{{company.county}}</td> -->
                    <!-- <td>{{company.country}}</td> -->
                    <!-- <td>{{company.active}}</td> -->
                    <td @click="ShowUpdateModal(company)">Edit</td>
                </tr>
            </tbody>
        </table>
</div>
        <button type="button" class="btn btn-primary collapse" id="companyDetailModalButton" data-bs-toggle="modal" data-bs-target="#companyDetailModal">
        Launch companyDetailModal
        </button>

        

        <div class="modal" id="companyDetailModal" tabindex="999999" >
            <!-- <div class="modal-dialog modal-dialog-scrollable" role="document"> -->
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <!-- <div class="modal-title h1 text-underline">Company Detail for Id = {{selectedCompany.companyId}}</div> -->
                         <h4 class="modal-title text-align-center text-primary fw-bold" id="siteDetailModalLabel" v-if="!isNewSiteToAdd">Detail for Company with Company-Id = {{selectedCompany.companyId}}</h4>
                         <h4 class="modal-title text-align-center text-primary fw-bold" id="siteDetailModalLabel" v-if="isNewSiteToAdd">Enter New Company Information</h4>
        
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        
                        <div class="row p-2 m-2 modal-body text-left" v-if="isModalDataValid">
                        <!-- <div class="row p-2 m-2 modal-body text-left" v-if="!isModalDataValid"> -->

                            
                            <div class="row">
                                <span class="text-primary col-3">NAME </span> 
                                <span class="text-primary col-9" v-if="!isEntryMode">{{selectedCompany.companyName}}</span>
                                <input type="text" v-if="isEntryMode" class="text-danger col-9" v-model="selectedCompany.companyName" placeholder="Enter New Name ">
                            </div>

                            
                            <div class="row">
                                <span class="text-primary col-3">PostCode </span>
                                <span class="text-primary col-9" v-if="!isEntryMode">{{selectedCompany.postCode}}</span>
                                <input type="text" v-if="isEntryMode" class="text-danger col-9" v-model="selectedCompany.postCode" placeholder="Enter Postcode ">
                            </div>


                            <div class="row">
                                <span class="text-primary col-3">StreetAdress </span>
                                <span class="text-primary col-9" v-if="!isEntryMode">{{selectedCompany.streetAdress}}</span>
                                <input type="text" v-if="isEntryMode" class="text-danger col-9" v-model="selectedCompany.streetAdress" placeholder="Enter StreetAdress ">
                            </div>

                            <div class="row">
                                <span class="text-primary col-3">City </span>
                                <span class="text-primary col-9" v-if="!isEntryMode">{{selectedCompany.cityName}}</span>
                                <!-- <input type="text" v-if="isEntryMode" list="citiesList" @change="onCityChanged($event)" class="text-danger col-9" v-model="selectedCompany.cityName" placeholder="Select City ">
                                <datalist id="citiesList">
                                    <option v-for="city in cityList" :value="city.cityId" v-bind:key="city.cityID">{{city.name}}</option>
                                </datalist> -->
                                <!-- <select id="selectedCityOption"  v-if="isEntryMode" class="text-danger col-9" @change="onCityChanged($event)" v-model="selectedCompany.cityName" placeholder="selectedCompany.cityName"> -->
                                <select id="selectedCityOption"  v-if="isEntryMode" class="text-danger col-9" @change="onCityChanged($event)" >
                                    <option v-for="city in cityList"   :value="city.cityId" v-bind:key="city.cityID">{{city.name}}</option>
                                </select>
                            </div>

                            <div class="row">
                                <span class="text-primary col-3">County </span>
                                <span class="text-primary col-9" v-if="!isEntryMode">{{selectedCompany.county}}</span>
                                <input type="text" v-if="isEntryMode" class="text-danger col-9" v-model="selectedCompany.county" placeholder="Enter County ">
                            </div>

                            <div class="row">
                                <span class="text-primary col-3">Country </span>
                                <span class="text-primary col-9" v-if="!isEntryMode">{{selectedCompany.country}}</span>
                                <input type="text" v-if="isEntryMode" class="text-danger col-9" v-model="selectedCompany.country" placeholder="Enter Country ">
                            </div>

                            <div class="row">
                                <span class="text-primary col-3">IsActive </span>
                                <span class="text-primary col-9" v-if="!isEntryMode">{{selectedCompany.active}}</span>
                                <select v-if="isEntryMode"  @change="onStatusChanged($event)" class="text-danger col-9" v-model="selectedCompany.active"  placeholder="Enter Status ">
                                    <option value="true">true</option>
                                    <option value="false">false</option>
                                </select>
                            </div>

                            <div class="row">
                                <span class="text-primary col-3">PhoneNo </span>
                                <span class="text-primary col-9" v-if="!isEntryMode">{{selectedCompany.phoneNo}}</span>
                                <input type="text" v-if="isEntryMode" class="text-danger col-9" v-model="selectedCompany.phoneNo" placeholder="Enter PhoneNo ">
                            </div>

                            <div class="row">
                                <span class="text-primary col-3">Company# </span>
                                <span class="text-primary col-9" v-if="!isEntryMode">{{selectedCompany.companyNo}}</span>
                                <input type="number" v-if="isEntryMode" class="text-danger col-9" v-model="selectedCompany.companyNo" placeholder="Enter CompanyNo ">
                            </div>

                            <div class="row">
                                <span class="text-primary col-3">VatNo </span>
                                <span class="text-primary col-9" v-if="!isEntryMode">{{selectedCompany.vatNo}}</span>
                                <input type="number" v-if="isEntryMode" class="text-danger col-9" v-model="selectedCompany.vatNo" placeholder="Enter VatNo ">
                            </div>

                            <div class="row">
                                <span class="text-primary col-3">Personnel </span>
                                <span class="text-primary col-9" v-if="!isEntryMode">{{selectedCompany.concernedPerson}}</span>
                                <input type="text" v-if="isEntryMode" class="text-danger col-9" v-model="selectedCompany.concernedPerson" placeholder="Enter Concerned Person ">
                            </div>

                            <div class="row">
                                <span class="text-primary col-3">MobileNo </span>
                                <span class="text-primary col-9" v-if="!isEntryMode">{{selectedCompany.mobileNo}}</span>
                                <input type="text" v-if="isEntryMode" class="text-danger col-9" v-model="selectedCompany.mobileNo" placeholder="Enter MobileNo ">
                            </div>

                            <div class="row">
                                <span class="text-primary col-3">Ctrl-Ph </span>
                                <span class="text-primary col-9" v-if="!isEntryMode">{{selectedCompany.controlPhone}}</span>
                                <input type="text" v-if="isEntryMode" class="text-danger col-9" v-model="selectedCompany.controlPhone" placeholder="Enter ControlPhone ">
                            </div>

                            <div class="row">
                                <span class="text-primary col-3">Paye No</span>
                                <span class="text-primary col-9" v-if="!isEntryMode">{{selectedCompany.payeeNumber}}</span>
                                <input type="text" v-if="isEntryMode" class="text-danger col-9" v-model="selectedCompany.payeeNumber" placeholder="Enter PayeNumber ">
                            </div>

                            <div class="row">
                                <span class="text-primary col-3">Email </span>
                                <span class="text-primary col-9" v-if="!isEntryMode">{{selectedCompany.email}}</span>
                                <input type="email" v-if="isEntryMode" class="text-danger col-9" v-model="selectedCompany.email" placeholder="Enter Email ">
                            </div>
                        
                            <div class="row">
                                <!-- <span class="text-primary col-9" v-if="!isEntryMode">{{selectedCompany.softwareOwner}}</span> -->
                                <!-- <input list="optionsList"  @change="onOwenerStatusChanged($event)" v-if="isEntryMode" class="text-danger col-9" v-model="selectedCompany.softwareOwner" placeholder="Enter SoftwareOwner ">
                                    <datalist id="optionsList">
                                        <option value="true" >False</option>
                                        <option value="false" >True</option>
                                    </datalist> -->
                                <span class="text-primary col-3">IsOwner?</span>
                                <select class="text-danger col-9" @change="onOwenerStatusChanged($event)" v-if="isEntryMode" v-model="selectedCompany.softwareOwner" placeholder="Enter SoftwareOwner ">
                                    <option value="true" >true</option>
                                    <option value="false" >false</option>
                                </select>                                
                            </div>
                            
                            <div class="row">
                                <span class="text-primary col-12" v-if="!isNewSiteToAdd">CURRENT CurrentInvoiceNo : <span class="text-primary">{{selectedCompany.currentInvoiceNo}}</span></span>
                            </div>

                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" v-if="!isNewSiteToAdd" @click="UpdateCompany()" class="btn btn-secondary" data-bs-dismiss="modal">Update Company</button>
                        <button type="button" v-if="isNewSiteToAdd" @click="AddCompany()" class="btn btn-secondary" data-bs-dismiss="modal">Add Company</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- <b-button @click="TogglePopup">Toggel Popup</b-button>
        <b-modal header-class="bg-blue" content-class="shape-m" >
            <template v-slot:modal-header>
                <div class="container-fluid">
                    <div class="modal-title subtitle text-white">test</div>
                </div>
            </template>
            <span v-if="true" class="d-flex justify-content-center">
                <i class="fa fa-spinner fa-spin fa-3x fa-fw d-flex"></i>
                In Progress
            </span>
            <span class="float-left pt-3 pb-3" v-show="true"> ALLAH </span>
            <template v-slot:modal-footer>
                <span type="button" @click="true" class="btn bg-blue-3d shape-m font-weight-bold text-white">Close</span>
            </template>
        </b-modal> -->
    </div>
</template>
<script>
//import {Company} from "../Companies/Company";
//import ICompany from "../Companies/ICompany";
//import ISite from '../Sites/ISite'

import { toRaw } from 'vue';


export default({
name:'Companies',
props:{
    smsapi_endpoint_base_url:String
},
data() {
    return {
        companies:[],
        isDataFetched:false,
        //selectedCompany:{},
        selectedCompany:{},
        isNewSiteToAdd:false,
        isModalDataValid:true,
        isEntryMode:true,
        isModalAlreadyOpened:false,
        cityList:[],
        searchString:"",
        selectedOption:true,
        selected:true
    }
},
methods:{
    onOwenerStatusChanged(){
        var isSoftwareOwnerValueBoolean=false;
      if( event.target.value == "true" || event.target.value == "True")
      {
        isSoftwareOwnerValueBoolean=true;
      }
      else if( event.target.value == "false" || event.target.value == "False")
      {
        isSoftwareOwnerValueBoolean=false;
      }
      else
      {
        isSoftwareOwnerValueBoolean = event.target.value;
      }
      this.selectedCompany.softwareOwner=isSoftwareOwnerValueBoolean;
    },
    onStatusChanged(event){
        var activeValueBoolean=false;
      if( event.target.value == "true" || event.target.value == "True")
      {
        activeValueBoolean=true;
      }
      else if( event.target.value == "false" || event.target.value == "False")
      {
        activeValueBoolean=false;
      }
      else
      {
        activeValueBoolean = event.target.value;
      }
      this.selectedCompany.active=activeValueBoolean;
    },
    onCityChanged(event){
        this.selectedCompany.cityId = event.target.value;
    },
     getCityList(){
    //return [{Id:1, cityName:'city1'}, {Id:2, cityName:'city2'}];
        //console.log("getCityList is called.");
        let sitesRequestUrl = this.smsapi_endpoint_base_url + "Cities/Cities";
          let sitesRequestOptions = {
            method:"GET",
            headers:{
              "Authorization":"Bearer " + localStorage.token,
              "Content-Type" : "application/json"
            },
          };
          
          fetch(sitesRequestUrl, sitesRequestOptions)
          .then(response =>{
            var result = response.json();
            //console.log("result000",  result);
            return result;
          })
          .then(res =>{
            this.cityList = res.cities;
            //console.log("res.cities",  this.cityList);
            //console.log("this.cityList.....",  this.cityList);
            //return res;
          });
    },
    AddCompany(){
        if(this.selectedCompany.cityId == null)
        {
            this.cityList.filter(function(x){
                x=null;//dummy statement should be removed
                console.log("cityId is null : ", x);
            })
        }
        else
        {
            // console.log("cityId is :",this.selectedCompany.cityId);
            // console.log("cityName is :",this.selectedCompany.cityName);
        }
        let AddCompanyRequestUrl = this.smsapi_endpoint_base_url + "Companies/AddCompnay";
        let AddComapnyRequestOptions = {
            method:"POST",
            headers:{
                "Authorization":`Bearer ${localStorage.token}`,
                "Content-Type":"application/json"
            },
            body:JSON.stringify(   //this.selectedCompany)
                {
                    "companyID" : 0,
                    "vatNo": parseInt( this.selectedCompany.vatNo),
                    "streetAdress": this.selectedCompany.streetAdress,
                    "townName":  this.selectedCompany.townName,
                    "cityID": parseInt(this.selectedCompany.cityId),
                    "postCode":  this.selectedCompany.postCode,
                    "phoneNo":  this.selectedCompany.phoneNo,
                    "companyNo": parseInt(this.selectedCompany.companyNo),
                    "concernedPerson":  this.selectedCompany.concernedPerson,
                    "mobileNo":  this.selectedCompany.mobileNo,
                    "companyName":  this.selectedCompany.companyName,
                    "active": this.selectedCompany.active,
                    "softwareOwner": this.selectedCompany.softwareOwner,
                    "email":  this.selectedCompany.email,
                    "controlPhone":  this.selectedCompany.controlPhone,
                    "payeeNumber":  this.selectedCompany.payeeNumber,
                    "county":  this.selectedCompany.county,
                    "country":  this.selectedCompany.country,
                    "currentInvoiceNo": parseInt(this.selectedCompany.currentInvoiceNo)
                }
            )
        };

        fetch(AddCompanyRequestUrl, AddComapnyRequestOptions)
        .then(response=>{
            //console.log("response", response);

            return response.json();
        })
        .then(result=>{
            result = null;//dummy statement should be removed
            console.log("result", result);
        })

    },
    UpdateCompany(){
                    
        // console.log("UpdateCompany called........................");
        // console.log("UpdateCompany called........................");
        if(this.selectedCompany.cityId == null || this.selectedCompany.cityId == "")
        {
            // console.log("cityId is null");
            // console.log("cityName is : ", this.selectedCompany.cityName);

            var selectedCityIdemp = this.cityList.find((x)=>{
                // console.log("cityId is null : ", x);
                if( x.name == this.selectedCompany.cityName)
                return x.cityId;
            })

            //console.log("selectedCityIdemp : ", selectedCityIdemp.cityId);
            this.selectedCompany.cityId = selectedCityIdemp.cityId;
        }

        let AddCompanyRequestUrl = this.smsapi_endpoint_base_url + "Companies/UpdateCompany";
        let AddComapnyRequestOptions = {
            method:"POST",
            headers:{
                "Authorization":`Bearer ${localStorage.token}`,
                "Content-Type":"application/json"
            },
            body:JSON.stringify(   //this.selectedCompany)
                {
                    "companyID" : this.selectedCompany.companyId,
                    "vatNo": parseInt( this.selectedCompany.vatNo),
                    "streetAdress": this.selectedCompany.streetAdress,
                    "townName":  this.selectedCompany.townName,
                    "cityID": parseInt(this.selectedCompany.cityId),
                    "postCode":  this.selectedCompany.postCode,
                    "phoneNo":  this.selectedCompany.phoneNo,
                    "companyNo": parseInt(this.selectedCompany.companyNo),
                    "concernedPerson":  this.selectedCompany.concernedPerson,
                    "mobileNo":  this.selectedCompany.mobileNo,
                    "companyName":  this.selectedCompany.companyName,
                    "active": this.selectedCompany.active,
                    "softwareOwner": this.selectedCompany.softwareOwner,
                    "email":  this.selectedCompany.email,
                    "controlPhone":  this.selectedCompany.controlPhone,
                    "payeeNumber":  this.selectedCompany.payeeNumber,
                    "county":  this.selectedCompany.county,
                    "country":  this.selectedCompany.country,
                    "currentInvoiceNo": parseInt(this.selectedCompany.currentInvoiceNo)
                }
            )
        };

        fetch(AddCompanyRequestUrl, AddComapnyRequestOptions)
        .then(response=>{
            //console.log("response", response);

            return response.json();
        })
        .then(result=>{
            result =null;//dummy statement should be removed
            console.log("result", result);
            this.SearchCompany();
        });
    },
    ShowAddCompanyModal(){
        this.isEntryMode=true;
        this.isNewSiteToAdd=true;
        this.selectedCompany = {
            companyID : 0,
            vatNo: "",
            streetAdress: "",
            townName:  "",
            cityID: '',
            postCode:  "",
            phoneNo:  "",
            companyNo: '',
            concernedPerson:  "",
            mobileNo:  "",
            companyName:  "",
            active: true,
            currentInvoiceNo:  "",
            softwareOwner: false,
            email:  "",
            controlPhone:  "",
            payeeNumber:  "",
            county:  "",
            country:  ""

        };//new Company(0,"","","",1,"","",0,"","","",false,"",false,"","","","","");
        document.getElementById("companyDetailModalButton").click();
    },
    ShowCompanyDetailModal(company){
        this.isNewSiteToAdd = false;
        this.isModalDataValid = true;
        this.isEntryMode =false;
        
        //console.log("company", company)

        this.selectedCompany = toRaw(company);
        //console.log("this.selectedCompany", this.selectedCompany)

        document.getElementById("companyDetailModalButton").click();
    },
    SearchCompany(){
          this.isDataFetched = true;
          let clientsRequestUrl = this.smsapi_endpoint_base_url + "Companies/SearchCompany";
          let clientsRequestOptions = {
            method:"POST",
            headers:{
              'Authorization': `Bearer ${localStorage.token}`,
                "Content-Type":"application/json",
            },
            body:JSON.stringify({

                "PageNumber" :0,
                "RecordsPerPage" :0,
                "SearchString":this.searchString
            })
          };

          fetch(clientsRequestUrl, clientsRequestOptions)
          .then( response =>response.json())
          .then(reslt =>{
            //console.log("reslt....2 : ", reslt);
            this.companies = reslt;
            this.isDataFetched = false;
          })
          .catch(error=>{
            error=null;//dummy statement should be removed
            console.log("error encountered", error);
            this.isDataFetched = false;
          });
    },
    // TogglePopup(){
    //     this.isPaymentMessageSentStatusShown=!this.isPaymentMessageSentStatusShown;
    //     //this.isPaymentMessageSentStatusShown=false;
    //     console.log("this.isPaymentMessageSentStatusShown", this.isPaymentMessageSentStatusShown)
    // },
    getCompaniesList(){
        this.isDataFetched = true;
          let clientsRequestUrl = this.smsapi_endpoint_base_url + "Companies/Companies";
          let clientsRequestOptions = {
            method:"GET",
            headers:{
              'Authorization': `Bearer ${localStorage.token}`,
                "Content-Type":"application/json",
            }
            // body:JSON.stringify({})
          };

          fetch(clientsRequestUrl, clientsRequestOptions)
          .then( response =>response.json())
          .then(reslt =>{
            //console.log("reslt....2 : ", reslt);
            this.companies = reslt;
            this.isDataFetched = false;
          })
          .catch(error=>{
            error = null;//dummy statement should be removed
            console.log("error encountered", error);
            this.isDataFetched = false;
          });
    },
    getClientSites(){

        let clientSitesRquestUrl = this.smsapi_endpoint_base_url+ "Companies/Companies";
        let clientSitesRequestOptions={
            method:"GET",
            headers:{
                "Authorize":`Bearer ${localStorage.token}`,
                "Content-Type":"application/json"
            }
            // body:JSON.stringify({
            //     "Id":2
            // })
        }

        fetch(clientSitesRquestUrl, clientSitesRequestOptions)
        .then(response=>{
            return response.josn();
        })
        .then(result =>{
            result = null;//dummy statement should be removed
            console.log("result", result);
        })
    },
    showCompanyModal(company){
        if(this.isModalAlreadyOpened == false)
        {
            //console.log("showCompanyModal called")

            this.selectedCompany = toRaw(company);
            
            // console.log("this.selectedCompany", this.selectedCompany)
            // console.log("company", company)

           


            document.getElementById("companyDetailModalButton").click();
            /*
                console.log("showCompanyModal company " , toRaw(company));
                let company1 = toRaw(company);
                this.selectedCompany = company1;
                document.getElementById("companyDetailModalButton").click();
                console.log("selectedCompany " , company1);
            */
        }
    },
    ShowUpdateModal(company){

        this.isNewSiteToAdd = false;
        this.isModalDataValid = true;
        this.isEntryMode =true;
        //console.log("ShowUpdateModal called")
        
        this.selectedCompany = toRaw(company);
        
        /*this code sets the index of the selected-city for the clicked company*/
        document.getElementById("companyDetailModalButton").click();
        let selectedCityOptionElement = document.getElementById("selectedCityOption");
        let optionsArray = Array.from(selectedCityOptionElement.options);
        let selectedIndex = optionsArray.findIndex(o => {
            return o.value == this.selectedCompany.cityId;
        });
        selectedCityOptionElement.selectedIndex = selectedIndex;

        /*
        console.log("ShowUpdateModal company" , toRaw(company));
        let company1 = toRaw(company);
        this.selectedCompany = company1;
        document.getElementById("companyDetailModalButton").click();
        console.log("selectedCompany " , company1);
        */
    }

},
mounted(){
    this.getCompaniesList();
    this.getCityList();

    const companyDetailModalEl = document.getElementById('companyDetailModal')
    companyDetailModalEl.addEventListener('shown.bs.modal', event => {
        this.isModalAlreadyOpened = true;
        console.log("selectedCompany shown.bs.modal ................." ,event);
    });
    companyDetailModalEl.addEventListener('hidden.bs.modal', event => {
        this.isModalAlreadyOpened = false;
        console.log("selectedCompany hidden.bs.modal............" ,event);
    });
},

})
</script>
<style>
</style>