<template language="ts">


<div id="array-with-index" class="container" v-show="true">
  
  <!-- Button trigger modal -->
<button type="button" class="btn btn-primary collapse" id="siteDetailModalButton" data-bs-toggle="modal" data-bs-target="#siteDetailModal">
  Launch demo modal
</button>

<!-- <div> -->
<div class="flex-container justify-content-ceter m-2">

<input type="text align-left bg-light text-dark" v-model="searchString" placeholder="Enter Search String">

<input list="companiesList" class="bg-light text-dark col-2" v-model="selectedCompanyId" placeholder="Clients">
<datalist id="companiesList">
  <option v-for="company in companies" v-bind:key="company.companyId">
  {{company.companyId +"-"+ company.companyName}}
  </option>
</datalist>

<button type="button" class="btn btn-primary btn-sm m-1"  @click="getsites(0)">Search</button>
<button type="button" class="btn btn-primary btn-sm m-1" @click="OnAddSiteCliked(2)" >
  Add Site
</button>


</div>
 
<div class="modal" id="siteDetailModal" tabindex="999999">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title fw-bold" id="siteDetailModalLabel" v-if="!isNewSiteToAdd">Detail for Site with Site-Id = {{modalContent.siteId}}</h4>
        <h4 class="modal-title fw-bold" id="siteDetailModalLabel" v-if="isNewSiteToAdd">Enter New Site Information</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body row p-2 m-2 justify-content-center" v-if="isModalDataValid">

        <div class="row text-left">
          <!-- <span class="text-primary text-left col-3" v-if="!isNewSiteToAdd"> NAME : </span> -->
          <span class="text-primary text-left col-3">Name</span>
          <span v-if="!isNewSiteToAdd && !isEntryMode" class="text-warning col-9">{{modalContent.siteName}}</span>
          <input type="text" v-if="isEntryMode" class="text-danger col-9" v-model="selectedSite.siteName" placeholder="Enter New Name for Site">
        </div>

        <div class="row">
          <!-- <span class="text-primary text-left col-3" v-if="!isNewSiteToAdd"> CITY : </span> -->
          <span class="text-primary text-left col-3">City</span>
          <span v-if="!isNewSiteToAdd && !isEntryMode" class="text-warning text-left col-9">{{modalContent.cityName}}</span>

          <input type="form-select" v-if="isEntryMode" class="col-9" list="cityDataList" @change="OnCityChanged($event)" v-model="selectedSite.cityName" placeholder="Select New City for Site">
          <datalist id="cityDataList">
            <option class="text-danger" v-for="city in cityList" v-bind:key="city.cityId" >{{city.cityId + "-" + city.name}}</option>
          </datalist>
        </div>

        <div class="row text-left">
          <!-- <span class="text-primary text-left col-3" v-if="!isNewSiteToAdd"> St. ADDRESS : </span> -->
          <span class="text-primary text-left col-3">St. Adress</span>
          <span v-if="!isNewSiteToAdd && !isEntryMode" class="text-warning text-left col-9">{{modalContent.streetAdress}}</span>
          <input type="text" v-if="isEntryMode" class="text-danger col-9" v-model="selectedSite.streetAdress" placeholder="Enter New Street Addresss for Site">
        </div>

        <div class="row text-left">
          <!-- <span class="text-primary text-left col-3" v-if="!isNewSiteToAdd"> POST CODE : </span> -->
          <span class="text-primary text-left col-3">PostCode</span>
          <span v-if="!isNewSiteToAdd && !isEntryMode" class="text-warning text-left col-9">{{modalContent.postCode}}</span>
          <input type="text" v-if="isEntryMode" class="text-danger col-9" maxlength="8" v-model="selectedSite.postCode" placeholder="Enter New postCode for Site">
        </div>

        <div class="row text-left">
          <!-- <span class="text-primary text-left col-3" v-if="!isNewSiteToAdd"> SITE CODE : </span> -->
          <span class="text-primary text-left col-3">SiteCode</span>
          <span v-if="!isNewSiteToAdd && !isEntryMode" class="text-warning text-left col-9">{{modalContent.siteCode}}</span>

          <input type="text" v-if="isEntryMode" class="text-danger col-9" maxlength="6" v-model="selectedSite.siteCode" placeholder="Enter New Sitecode for Site">
        </div>

        <div class="row text-left">
          <!-- <span class="text-primary text-left col-3" v-if="!isNewSiteToAdd"> COUNTY : </span> -->
          <span class="text-primary text-left col-3"> County</span>
          <span v-if="!isNewSiteToAdd && !isEntryMode" class="text-warning text-left col-9">{{modalContent.county}}</span>

          <input type="text" v-if="isEntryMode" class="text-danger col-9" v-model="selectedSite.County" placeholder="Enter New County for Site">
        </div>

        <div class="row text-left">
          <!-- <span class="text-primary text-left col-3" v-if="!isNewSiteToAdd"> TOWN : </span> -->
          <span class="text-primary text-left col-3">Town</span>
          <span v-if="!isNewSiteToAdd && !isEntryMode" class="text-warning text-left col-9">{{modalContent.town}}</span>

          <input type="text" v-if="isEntryMode" class="text-danger col-9" v-model="selectedSite.Town" placeholder="Enter New Town for Site">
        </div>

        <div class="row text-left" v-if="isEntryMode">
        <!-- <span class="text-primary  col-3" v-if="!isNewSiteToAdd"> ACTIVE : </span> -->
        <span class="text-primary  col-3">Active</span>
          <span v-if="!isNewSiteToAdd && !isEntryMode" class="text-warning text-left col-9">{{modalContent.active}}</span>

          <input type="text" list="booleanList" class="text-danger col-9" v-model="selectedSite.Active" placeholder="Enter New Active for Site">
          <datalist id="booleanList">
            <option value="true">True</option>
            <option value="false">False</option>
          </datalist>
        </div>

        <div class="row text-left" v-if="!isNewSiteToAdd">
          <span class="text-primary col-12"> CLIENTS : {{modalContent.client}}</span>
          <span v-if="!isNewSiteToAdd && !isEntryMode" class="text-warning text-left col-9">{{modalContent.Active}}</span>

          <!-- <div v-for="(client,index) in modalContent.clientsInfo" v-bind:key="client">
            <span class="text-warning"><span> {{index + 1}} : </span>
            <span> {{client}} </span></span>
          </div> -->

        <div v-if="isEntryMode">
          <div v-for="(siteClient, index) in modalContent.siteClients" v-bind:key="siteClient.clientSiteId">
            <input type="form-select" class="col-6" @change="OnUpdateClientChange($event, siteClient.clientSiteId)"  list="clientListOptions" v-model="siteClient.companyInfo">
            <datalist id="clientListOptions">
                <option v-for="company in companies" v-bind:key="company.companyId">
                        {{company.companyId + "-" +company.companyName}}
                </option>
            </datalist>
            <input type="number" class="col-1" v-model="siteClient.rate">
            <input type="text" class="col-3" v-model="siteClient.remarks">
            <button type="button" @click="OnDeleteExistingSiteClients(siteClient.clientSiteId, index)">Del</button>
          </div>
        </div>
          
        </div>
      </div>

<div class=" row justify-content-center p-2 m-2">

  <div class="border border-1 border-dark col-11 conner-round p-2" v-if="isNewSiteToAdd || isEntryMode">
    
 

      <div class="row d-flex flex-row justify-content-center">
          <button type="button" class="col-4 text-dark" @click="AddClientRow">Add Clients</button>
      </div>



      <div class="row col-12 h4 " v-show="addedClientsObjec.length > 0">
        <span class=" margin-left-2 col-6">Selecte Client</span>
        <span class="col-2">Rate</span>
        <span class="col-4">Remarks</span>
      </div>
      <div v-for="(client) in addedClientsObjec" v-bind:key="client">

              <input type="text" @change="OnClientChange($event)" class="text-danger col-6" v-model="client.CompanyId" list="clientListOptions" id="exampleDataList" placeholder="Selecte New Client...">
              <datalist id="clientListOptions">
                <option v-for="company in companies" v-bind:key="company.companyId">
                        {{company.companyId + "-" +company.companyName}}
                </option>
            </datalist>
            <span >
                <input type="number" class="col-1" v-model="client.Rate" placeholder="Enter Rate for client" />
            </span>
            <span >
              <input class="col-3" v-model="client.Remarks" placeholder="Enter Remarks for client" />
            </span>
            <button type="button" class="col-2" @click="DeleteSiteClient( client)">Del</button>
      </div>

 </div>
</div>
      
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" @click="ClearSiteClientsArray" data-bs-dismiss="modal">Close</button>
        <button type="button" v-if="!isNewSiteToAdd" @click="UpdateSite()" class="btn btn-secondary" data-bs-dismiss="modal">Update Site</button>
        <button type="button" v-if="isNewSiteToAdd" @click="AddSite()" class="btn btn-secondary" data-bs-dismiss="modal">Add Site</button>
      </div>
      
    </div>
  </div>
</div>

        
        
<div>
  <span v-show="!isAuthorized" class="text-danger h2"> Please make sure you are logged in.</span>
</div>        
      
<div class="row m-2">
  <table class="table text-left table-hover" v-show="isAuthorized">
  <thead>
    <tr>
      <!-- <th>ID</th> -->
      <th>NAME</th>
      <th>STREET ADDRESS</th>
      <th>POST CODE</th>
      <th>CITY</th>
      <th>Edit</th>
    </tr>
  </thead>
  <tbody>
    <tr  v-for="site in sites" v-bind:key="site" class="bg-white text-dark" @click="ShowSiteDetail(site.siteId)">
      <!-- <td>{{site.siteId}}</td> -->
      <td @click="updateSite(0)">{{ site.siteName }}</td>
      <td @click="updateSite(0)">{{ site.streetAdress }}</td>
      <td @click="updateSite(0)">{{site.postCode}}</td>
      <td @click="updateSite(0)">{{site.cityName}}</td>
      <td @click="updateSite(site)">Edit</td>
    </tr>
  </tbody>
</table>
</div>


<div class="container mt-2">
   <button class="Btn btn-primary btn-sm m-1" v-show="isRangeLess_Than_TotalResultPages"  @click="getsites(startPageNumber - LabelsPerPage )"> &lt;&lt;</button>
   <span v-if="endPageNumber > 1">   
      <span v-for="pageNo in (endPageNumber)" v-bind:key="pageNo">
        <button class="btn btn-success btn-sm m-1" type="button" v-if="isShownPageButton(pageNo)" @click="getsites(pageNo -1 )">{{ pageNo }}</button>
      </span>
      <button type="button" class="Btn btn-primary btn-sm m-1" v-show="isRangeGreater_Than_LabelsPerPage" @click="getsites(endPageNumber)"> >> </button>
  </span>
  </div>


</div>

</template>

<script>
//import { ISite } from './Sites/ISite';
//import { Site } from '../Sites/Site'; 
//import { Page } from './Paging'
import ISite from '../Sites/ISite'
import { Site } from '../Sites/Site';
import IClientSite from '../Companies/IClientSite';
import {ClientSite} from '../Companies/ClientSite';
import {RecordOperations} from '../Companies/RecordOperations';

// import Site from "../Sites/Site"

export default {
  name: 'Sites',
  components:{
    
  },
  props: {
    msg: String,
    isSitesVisible:Boolean, 
    smsapi_endpoint_base_url:String,
    },

    data(){
        return {
            sites : [],//ISite[]: [],
            allSites : [],//ISite[]: [],
            count: 4 ,
            modalContent:{},
            isModalShown:false,
            //siteDetail:ISite,
            balanceAdjustmentValue:40,
            searchString:'',
            currentPageNo:0,
            startPageNumber : 1,
            endPageNumber:0,
            LabelsPerPage : 10,
            totalResultPages:0,
            totalRecordCount:0,
            recordsPerPage:10,
            isRangeGreater_Than_LabelsPerPage:false,
            isRangeLess_Than_TotalResultPages:false,
            isAuthorized:false,
            selectedSiteId:0,
            selectedSite:ISite,
            //cityList:[{Id:1, cityName:'city1'}, {Id:2, cityName:'city2'}]
            cityList:[],
            isModalDataValid:false,
            isEntryMode:false,
            isNewSiteToAdd:false,     
            companies:[],
            selectedCompanyId:"",
            //companies:this.getCompaniesList(),
            // addedClients:[],
            clientSiteTestObject:IClientSite,
            addedClientsObjec:[],
            clientIndex:0,
            ClientIdToAdd:0,
            clientRateToAdd:0,
            clientSiteRemarsToAdd:"",
            siteIdToAdd:0,
            nullClientSite:null,
        }
      },
methods: {
  OnDeleteExistingSiteClients(clientSiteId, index){

    // console.log("clientSiteId", clientSiteId);
    // console.log("inside 1 OnDeleteExistingSiteClients method");

    let DeleteExistingSiteClientsRequestUrl = this.smsapi_endpoint_base_url + "ClientSite/DeleteClientSite";
    let DeleteExistingSiteClientsRequestOptions =
    {
      method:"POST",
      headers:{
        'Authorization': `Bearer ${localStorage.token}`,
        "Content-Type":"application/json"
      },
      body:JSON.stringify({"Id":clientSiteId})
    };

    // console.log("inside 2 OnDeleteExistingSiteClients method");


    fetch(DeleteExistingSiteClientsRequestUrl, DeleteExistingSiteClientsRequestOptions)
    .then( response => {
      var res = response.json();
      res = null;//dummy statement should be removed
      console.log("res", res);
      this.modalContent.siteClients.splice(index,1);
    })
    .catch( error =>
      {
        error=null;//dummy statement should be removed
        alert("Error occurred during Deleting SiteClient from database\n\r" + "Error detail : " + error.message );
    });

  },
  DeleteSiteClient(client){
    //console.log("client ", client);
    //console.log("this.addedClientsObjec 1 ", this.addedClientsObjec);
    let indexOfClientToDelete = this.addedClientsObjec.indexOf(client);
    this.addedClientsObjec.splice(indexOfClientToDelete,1);
    //console.log("this.addedClientsObjec 2", this.addedClientsObjec);

  },
   OnUpdateClientChange(event, clientSiteId){
      // console.log("Client Id.....", event.target.value);
      // console.log("siteClient.clientSiteId.....", clientSiteId);

      let clientInfo = event.target.value;
      this.ClientIdToAdd = clientInfo.substring(0, clientInfo.indexOf("-"))
      // let updatedClient = this.modalContent.siteClients.filter(sc=>sc.clientSiteId == clientSiteId);

      this.modalContent.siteClients.forEach(sc=>{
        if(sc.clientSiteId == clientSiteId)
        {
          sc.companyId = parseInt(this.ClientIdToAdd);
        }
      });

      // updatedClient.companyId = parseInt(this.ClientIdToAdd);
      // console.log("this.ClientIdToAdd.....", this.ClientIdToAdd);
      // console.log("this.modalContent.siteClients.....", this.modalContent.siteClients);

  },
  OnClientChange(event){
      //console.log("Client Id.....", event.target.value);
      let clientInfo = event.target.value;
      this.ClientIdToAdd = clientInfo.substring(0, clientInfo.indexOf("-"))
      //console.log("this.ClientIdToAdd.....", this.ClientIdToAdd);
  },
  AddClientRow(){
    let firstCompany = this.companies[0];
    let firstCompanyString = firstCompany.companyId + "-" + firstCompany.companyName;
    let clientSite = new ClientSite(firstCompanyString,this.modalContent.siteId,0,0,"", RecordOperations.Add);
    this.addedClientsObjec.push( clientSite);
    //console.log("this.addedClientsObjec : ", this.addedClientsObjec);

    this.clientIndex++;
    this.message = '<input type="text" v-modal="" placeholder="Enter Rate">';
    // console.log("addedClients : ", this.addedClients);
  },
  ClearSiteClientsArray(){
      this.addedClientsObjec.splice(0, this.addedClientsObjec.length);
  },
  getCompaniesList(){
          let clientsRequestUrl = this.smsapi_endpoint_base_url + "Companies/Companies";
          let clientsRequestOptions = {
            method:"GET",
            headers:{
              'Authorization': `Bearer ${localStorage.token}`,
                "Content-Type":"application/json",
            }
            // body:JSON.stringify({})
          };

          fetch(clientsRequestUrl, clientsRequestOptions)
          .then( response =>response.json())
          .then(reslt =>{
            //leave it as without it sometimes we don't get list of clients on all pages
            //console.log("reslt....2222222 : ", reslt);
            this.companies = reslt;
          });
    },
  AddSite(){

      let index = 0;
      //console.log("this.addedClientsObjec : ", this.addedClientsObjec);

      this.addedClientsObjec.forEach(x=>{
          if(x.companyId == 0)
          this.addedClientsObjec.splice(index,1);
          x.CompanyId = parseInt( x.CompanyId.substring(0, x.CompanyId.indexOf("-")));
          x.Rate = parseFloat( x.Rate);
          index++;
      })

      var activeValueBoolean=false;
      if( this.selectedSite.Active == "true" || this.selectedSite.Active == "True")
      {
        activeValueBoolean=true;
        //console.log("In if1 this.selectedSite.Active", this.selectedSite.Active);
      }
      else if( this.selectedSite.Active == "false" || this.selectedSite.Active == "False")
      {
        activeValueBoolean=false;
        //console.log("In if2 this.selectedSite.Active", this.selectedSite.Active);
      }
      else
      {
        activeValueBoolean = this.selectedSite.Active;
        // console.log("In else this.selectedSite.Active", this.selectedSite.Active);
        // console.log("In else activeValueBoolean", activeValueBoolean);
      }

      let sitesRequestUrl = this.smsapi_endpoint_base_url + "Sites/AddSiteWithClients";
      let sitesRequestOptions = {
          method:"POST",
          headers:{
            "Authorization":"Bearer " + localStorage.token,
            "Content-Type" : "application/json"
          },
          body:JSON.stringify({
            "SiteViewModel" : {
            "SiteId": 0,
            "CityId": parseInt( this.selectedSite.cityId),//2,//this.selectedSite.cityId,
            "SiteName": this.selectedSite.siteName,
            //"siteId": this.selectedSite.siteCode,
            "PostCode": this.selectedSite.postCode,
            "County": this.selectedSite.County,
            "Town": this.selectedSite.Town,
            "Active": activeValueBoolean,// true,//this.selectedSite.Active,
            "Client": this.selectedSite.Client,
            "SiteCode": this.selectedSite.siteCode,
            "StreetAdress": this.selectedSite.streetAdress
          },
            "ClientSiteViewModels" :  this.addedClientsObjec//this.addedClients
          }
          )
        };
        
        fetch(sitesRequestUrl, sitesRequestOptions)
        .then(response =>{
          var result = response.json();
          //console.log("result000",  result);
          return result;
        })
        .then(res =>{
          this.cityList = res.cities;
          //console.log("res.cities",  res.cities);
          //console.log("this.cityList",  this.cityList);
          //return res;
          this.addedClientsObjec.splice(0, this.addedClientsObjec.length);
        });
  },
  OnAddSiteCliked(siteId){
      this.isNewSiteToAdd = true;
      this.isEntryMode = true;
      this.ShowSiteDetail(siteId)
  },
  OnCityChanged(e){
    let city = e.target.value;
    let endIndex = city.indexOf("-")
    this.selectedSite.cityId = city.substring(0,endIndex);
  },
  UpdateSite(){
      if(this.selectedSite.cityId == undefined)
      {
        this.selectedSite.cityId  = this.modalContent.cityId;
      }

      var activeValueBoolean=false;
      if( this.selectedSite.Active == "true" || this.selectedSite.Active == "True")
      {
        activeValueBoolean=true;
      }
      else if( this.selectedSite.Active == "false" || this.selectedSite.Active == "False")
      {
        activeValueBoolean=false;
      }
      else
      {
        activeValueBoolean = this.selectedSite.Active;
      }

      // "SiteClientsToUpdate":this.modalContent.siteClients,
      // "SiteClientsToAdd":this.addedClientsObjec
      // console.log("this.modalContent.siteClients", this.modalContent.siteClients);
      // console.log("tthis.addedClientsObjec", this.addedClientsObjec);


      let siteClientsToSend = [];
      this.addedClientsObjec.forEach( sc =>
      {
        if(sc.CompanyId != null)
         { 
          //console.log("sc.CompanyId ", sc.CompanyId);
          let CompanyId = sc.CompanyId.substring(0, sc.CompanyId.indexOf("-"));
          let CompanyIdIntValue = parseInt(CompanyId);
          let RateFloatValue = parseFloat(sc.Rate);
          //(companyId, siteId,  rate, clientSiteId, remarks, recordOperation, clientInfo ) 
          let clientSite = new ClientSite(CompanyIdIntValue, sc.SiteId, RateFloatValue, sc.ClientSiteId, sc.Remarks, sc.RecordOperation,"");
          //this.modalContent.siteClients.push(clientSite);
          siteClientsToSend.push(clientSite);}
      });

      this.modalContent.siteClients.forEach( sc =>
      {
          //console.log("sc.CompanyId ", sc.companyId);
          let RateFloatValue = parseFloat(sc.rate);
          let clientSite = new ClientSite(sc.companyId, sc.siteId, RateFloatValue, sc.clientSiteId, sc.remarks, sc.recordOperation,"");
          siteClientsToSend.push(clientSite);
      });
     
      //console.log("siteClientsToSend after concatinating...", siteClientsToSend);
      let sitesRequestUrl = this.smsapi_endpoint_base_url + "Sites/UpdateSiteWithClients";//UpdateSite";
      let sitesRequestOptions = {
          method:"POST",
          headers:{
            "Authorization":"Bearer " + localStorage.token,
            "Content-Type" : "application/json"
          },
          body:JSON.stringify({
            "SiteViewModel":
            {
              "SiteId": this.selectedSite.siteId,
              "CityId": parseInt( this.selectedSite.cityId),//2,//this.selectedSite.cityId,
              "SiteName": this.selectedSite.siteName,
              //"siteId": this.selectedSite.siteCode,
              "PostCode": this.selectedSite.postCode,
              "County": this.selectedSite.County,
              "Town": this.selectedSite.Town,
              "Active": activeValueBoolean,// true,//this.selectedSite.Active,
              "Client": this.selectedSite.Client,
              "SiteCode": this.selectedSite.siteCode,
              "StreetAdress": this.selectedSite.streetAdress,
            },
            "ClientSiteViewModels":siteClientsToSend//this.addedClientsObjec//this.modalContent.siteClients
            // "SiteClientsToUpdate":this.modalContent.siteClients,
            // "SiteClientsToAdd":this.addedClientsObjec

          })
        };
        
        fetch(sitesRequestUrl, sitesRequestOptions)
        .then(response =>{
          var result = response.json();
          // console.log("result000",  result);
          return result;
        })
        .then(res =>{
          this.cityList = res.cities;
          //this.addedClientsObjec=[];
          //this.modalContent.siteClients=[];
          //console.log("res.cities",  res.cities);
          // console.log("this.cityList",  this.cityList);
          //return res;
        });
  },
  getCityList(){
    //return [{Id:1, cityName:'city1'}, {Id:2, cityName:'city2'}];
        //console.log("getCityList is called.");
        let sitesRequestUrl = this.smsapi_endpoint_base_url + "Cities/Cities";
          let sitesRequestOptions = {
            method:"GET",
            headers:{
              "Authorization":"Bearer " + localStorage.token,
              "Content-Type" : "application/json"
            },
          };
          
          fetch(sitesRequestUrl, sitesRequestOptions)
          .then(response =>{
            var result = response.json();
            //console.log("result000",  result);
            return result;
          })
          .then(res =>{
            this.cityList = res.cities;
            //console.log("res.cities",  res.cities);
            //console.log("this.cityList",  this.cityList);
            //return res;
          });
  },
  getSitesList(){
            let sitesRequestUrl = this.smsapi_endpoint_base_url + "Sites/SitesPageWise";
            let sitesRequestOptions = {
              method:"POST",
              headers:{
                "Authorization":"Bearer " + localStorage.token,
                "Content-Type" : "application/json"
              },
              body:JSON.stringify({
                'PageNumber': 0,
                'RecordsPerPage':1111,
                'SearchString': ''
              })
            };
            
            fetch(sitesRequestUrl, sitesRequestOptions)
            .then(response =>{
              var result = response.json();
              if(response.status == false)
                {
                  //console.log("result false",  result);
                }
                else
                {
                  //console.log("result true",  result);
                  return result;
                }
            })
            .then(res=>{
              //console.log("res", res);
              //console.log("ressitesListPage",  res.sitesListPage);
              this.allSites = res.sitesListPage;
            });
            
  },
  updateSite(site){
    if(site == 0)
      this.isEntryMode = false;
    else
      this.isEntryMode = true;

    this.isNewSiteToAdd = false;


    if(this.clientIndex > 0)
      this.DeleteSiteClient(this.nullClientSite);

   //"this.isEntryMode 0000", this.isEntryMode);
    //console.log("updateSite(site) called for site : ", site);
  
  },
  getsites(pageNumber) {

    /*START of tempcode: temporary code to solve an unresolved issue*/
    //the code below at the start of this method shows the add a new client interface on the update-site modal it is not required here
    //but we have problem that the list of cities on update-site interface is not displayed as expected with their ids and names
    //so we tried hard to figure out why it is happening but could not find the reason and hence could not correct this issue. However, we noted that 
    //if we display 'add a new client interface' for the site on site-update-modal then the cities list properly displays and works. So, when the real reason and 
    //hence the real solution for the above mentioned problem is found then we will remove this code.
    let firstCompanyString = null;
    this.nullClientSite = new ClientSite(firstCompanyString,this.modalContent.siteId,0,0,"", RecordOperations.Add);
    this.addedClientsObjec.push( this.nullClientSite);
    //console.log("this.addedClientsObjec : ", this.addedClientsObjec);
    this.clientIndex++;
    this.message = '<input type="text" v-modal="" placeholder="Enter Rate">';
    /*END of tempcode: temporary code to solve an unresolved issue*/


    this.currentPageNo = pageNumber;
    // console.log("getsites called")

    let companyId = 0; 
    if(this.selectedCompanyId != undefined)
      companyId = this.selectedCompanyId.substring(0, this.selectedCompanyId.indexOf("-"));

    let options = {
      method:'POST',
      headers:{
        'Authorization': `Bearer ${localStorage.token}`,
        'Content-Type':'application/json'
      },
      body:JSON.stringify({
        'PageNumber': this.currentPageNo,
        'RecordsPerPage':this.recordsPerPage,
        'SearchString': this.searchString,
        'CompanyId': parseInt(companyId)
      }),
    }
      
    let url = this.smsapi_endpoint_base_url + "Sites/SitesPageWise";

    fetch(url, options)
    .then(response => 
        {
          var res = response.json()
          if(response.status == 401){
            this.isAuthorized = false;
            return null;
          }
          else{
            this.isAuthorized = true;
            return res;
          }
        })
    .then(result => {
      if(result == null)
      {
        this.schedule=null;
      }
      this.sites = result.sitesListPage;
      this.totalRecordCount = result.totalRecordCount;
      this.recordsPerPage = result.pb.recordsPerPage;
      this.totalResultPages = (this.totalRecordCount % this.recordsPerPage == 0) ? this.totalRecordCount / this.recordsPerPage : this.totalRecordCount / this.recordsPerPage;
      this.totalResultPages = Math.ceil(this.totalResultPages);
      this.diplayResultPageList();
      //var page = new Page( 2, true);
      //this.Pages[0] = page;
    }).catch(error => {
      error = null;//dummy statement should be removed
      console.log("error",error);
      this.sites = null;
    });
    //console.log("this.sites : ", this.sites);
    return this.sites;
  },
  isShownPageButton(pageNo){
    return (pageNo > this.startPageNumber) && (pageNo <= (this.startPageNumber + this.LabelsPerPage));
  },
  diplayResultPageList(){
      let sectionNo = Math.floor(this.currentPageNo/this.LabelsPerPage) ;
      this.startPageNumber = sectionNo * this.LabelsPerPage;
          this.endPageNumber = (sectionNo + 1) * this.LabelsPerPage;
          if (this.endPageNumber >= this.totalResultPages)
          {
              //we don't need a previous-result-pages-arrow
              this.isRangeGreater_Than_LabelsPerPage = false;
              this.endPageNumber = this.totalResultPages;
          }
          else
          {
              //we need a previous-result-pages-arrow
              this.isRangeGreater_Than_LabelsPerPage = true;
          }

          if(this.startPageNumber >= this.LabelsPerPage)
          {
            this.isRangeLess_Than_TotalResultPages = true;
          }
          else
          {
            this.isRangeLess_Than_TotalResultPages = false;
          }
  },
    increment() {
    return this.count++
  },
  ShowSiteDetail(siteId){
    //console.log("inside the showDetail method.");
            var options = {
            method: "POST",
            headers:{
              'Authorization': `Bearer ${localStorage.token}`,
              'Content-Type':"application/json",
            },
            body: JSON.stringify( {"Id": siteId}),
          }

          let url = this.smsapi_endpoint_base_url + "Sites/SiteByIdWithClients";
          fetch(url, options)
          .then(response => response.json())
          .then(result => {
                this.getCityList();
                if(result.success)
                {
                  this.modalContent = result.result;
                    this.selectedSite = new Site(
                    this.modalContent.siteId,
                    this.modalContent.siteName,
                    this.modalContent.streetAdress,
                    this.modalContent.postCode,
                    this.modalContent.siteCode ,
                    this.modalContent.cityName,
                    this.modalContent.county,
                    this.modalContent.town, 
                    this.modalContent.active,
                    this.modalContent.cityId,
                    this.modalContent.clientsInfo,
                    this.modalContent.siteClients,
                  )

                  //set the record operation for each of the site-clients to be 'Update' as 
                  //these are already exisiting clients and would be able to be updated(or deleted)
                  //the other type of recordOperation would be 'Add' for new site-clients records
                  this.modalContent.siteClients.forEach( sc=>sc.recordOperation = RecordOperations.Update);

                    //console.log("this.modalContent ",this.modalContent);

                    //Note: these assignments have been used because the above Site constructor was
                    //not correctly assigning all the properties correctly. So, we have to use these statements
                    if(!this.isNewSiteToAdd)
                    {
                      this.selectedSite.siteId = this.modalContent.siteId;
                      this.selectedSite.siteName = this.modalContent.siteName;
                      this.selectedSite.streetAdress = this.modalContent.streetAdress;
                      this.selectedSite.postCode = this.modalContent.postCode;
                      this.selectedSite.siteCode = this.modalContent.siteCode ;
                      this.selectedSite.cityName = this.modalContent.cityName;
                      this.selectedSite.County = this.modalContent.county;
                      this.selectedSite.Town = this.modalContent.town;
                      this.selectedSite.Active = this.modalContent.active;
                      this.selectedSite.cityId = this.modalContent.cityId;
                      this.selectedSite.Client = this.modalContent.clientsInfo;
                      this.selectedSite.SiteClients = this.modalContent.siteClients;
                    }
                    else
                    {
                      this.selectedSite.siteId = 0;
                      this.selectedSite.siteName = "";
                      this.selectedSite.streetAdress = "";
                      this.selectedSite.postCode = "";
                      this.selectedSite.siteCode = "" ;
                      this.selectedSite.cityName = "";
                      this.selectedSite.County = "";
                      this.selectedSite.Town = "";
                      this.selectedSite.Active = true;
                      this.selectedSite.cityId = 1;
                      this.selectedSite.Client = [];
                      this.selectedSite.SiteClients = [];
                    }

                  //console.log("this.selectedSite......showSiteDetail ",this.selectedSite);
                  this.isModalDataValid = true;
                }
                else
                {
                  this.modalContent = {}
                  this.isModalDataValid=false;
                }

                //console.log("result : ",result);
                return this.modalContent;
            });
            //console.log("this.modalContent.cityId ...", this.modalContent.cityId);
            document.getElementById("siteDetailModalButton").click();
            this.isModalShown= !this.isModalShown;
  },
  getAuthorization(){
      var token = localStorage.getItem("token");
      if(token == "")
        this.isAuthorized = false;
      else
        this.isAuthorized=true;
  },
},
  
  compute:{
    showModal(){
      //console.log("this.isModalShown", this.isModalShown);
      return this.isModalShown;
    }

  },
  mounted(){
    this.getAuthorization();
    this.getsites(0);
    this.getCityList();
    this.getSitesList();
    this.getCompaniesList();
    this.isModalDataValid=false;
    this.selectedSite= new Site(1,"1","1","2","2");
  },
  beforeMount() {
    //this.getCompaniesList();
  }
}
</script>

<style>
</style>
