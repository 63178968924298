import { IJob } from "./IJob";

export default class Job2 implements IJob{
    jobId: number;
    siteId: number;
    jobDate: Date;
    securityOfficerId: number;
    operator: number;
    bonusHours: number;
    travelExpense: number;
    Remarks: string;
    Adjustment: number;
    statusId: number;
    clientId: number;
    OperatorId: number;
    PayRate: number;
    Rate: number;
    startTime: string;
    endTime: string;
    recordDateTime: string;
    recordStatusId: number;
    recordStatus: number;
    oldJobId: number;
    oldJob: number;

    /**
     * 
     * @param jobId 
     * @param siteId 
     * @param jobDate 
     * @param securityOfficerId 
     * @param operator 
     * @param bonusHours 
     * @param travelExpense 
     * @param Remarks 
     * @param Adjustment 
     * @param statusId 
     * @param clientId 
     * @param OperatorId 
     * @param PayRate 
     * @param Rate 
     * @param startTime 
     * @param endTime 
     * @param recordDateTime 
     * @param recordStatusId 
     * @param recordStatus 
     * @param oldJobId 
     * @param oldJob 
     */
    constructor(jobId: number, siteId: number, jobDate: Date, securityOfficerId: number, operator: number, 
        bonusHours: number, travelExpense: number, Remarks: string, Adjustment: number, statusId: number, 
        clientId: number, OperatorId: number, PayRate: number, Rate: number, startTime: string, endTime: string, 
        recordDateTime: string, recordStatusId: number, recordStatus: number, oldJobId: number, oldJob: number)
    {
        this.jobId= jobId;
        this.siteId = siteId;
        this.jobDate = jobDate;
        this.securityOfficerId =securityOfficerId;
        this.operator =operator;
        this.bonusHours =bonusHours;
        this.travelExpense =travelExpense;
        this.Remarks =Remarks;
        this.Adjustment =Adjustment;
        this.statusId =statusId;
        this.clientId =clientId;
        this.OperatorId =OperatorId;
        this.PayRate =PayRate;
        this.Rate =Rate;
        this.startTime =startTime;
        this.endTime =endTime;
        this.recordDateTime =recordDateTime;
        this.recordStatusId =recordStatusId;
        this.recordStatus =recordStatus;
        this.oldJobId =oldJobId;
        this.oldJob =oldJob;
    }

}